import styled from 'styled-components';
import { colors } from '../../constant';

export const Logo = styled.img`
  margin-bottom: 20px;
  max-width: 240px;
  max-height: 150px;
`;
export const ResponsiveWrapper = styled.div`
  width: 100%;
  box-sizing: initial;
  background-color: ${colors.white};
  border-radius: 4px;
  max-width: 380px;
  width: 380px;
  margin: 0 auto;
  padding: 30px;
  border-radius: 4px;
  overflow-y: auto;
  @media only screen and (max-width: 768px) {
    width: 80%;
  }
`;
export const Divider = styled.div`
  padding-top: 35px;
  margin-bottom: 35px;
  border-bottom: 1px solid ${colors.gray10};
  position: relative;
`;

export const OrTextContainer = styled.span`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 24px;
  left: 0;
  font-size: 15px;
  color: ${colors.gray10};
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const OrText = styled.span`
  background-color: ${colors.white};
  padding: 0 8px;
`;
