/* eslint-disable no-restricted-globals */
import { action } from 'easy-peasy';

const DEFAULT_STATE = {
  confirmed: false
};

const attributes = {
  userData: DEFAULT_STATE
};

const actions = {
  setUserData: action((state, userData) => {
    state.userData = userData;
    state.firstAndLastName = `${userData.FirstName} ${userData.LastName}`;
  }),

  setFirstLastName: action((state, { firstName, lastName }) => {
    state.firstAndLastName = `${firstName} ${lastName}`;
    localStorage.setItem('firstAndLastName', `${firstName} ${lastName}`);
  })
};

const user = {
  ...attributes,
  ...actions
};

export default user;
