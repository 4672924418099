import { fontWeights } from './constant';

export const textalign = ({ textalign }) =>
  textalign && `text-align: ${textalign};`;

export const color = ({ color }) => color && `color: ${color} !important;`;

export const bgcolor = ({ bgcolor }) =>
  bgcolor && ` background-color: ${bgcolor};`;

export const pointer = ({ pointer }) => pointer && 'cursor: pointer;';

export const display = ({ display }) => display && `display: ${display};`;

export const flex = ({ flex }) => flex && `flex: ${flex} !important;`;

export const boxsizing = ({ boxsizing }) =>
  boxsizing && `box-sizing: ${boxsizing}`;

export const visibility = ({ visibility }) =>
  visibility && `visibility: ${visibility};`;

export const justifycontent = ({ justifycontent }) =>
  justifycontent && `justify-content: ${justifycontent};`;

export const justifyItems = ({ justifyItems }) =>
  justifyItems && `justify-items: ${justifyItems};`;

export const justifySelf = ({ justifySelf }) =>
  justifySelf && `justify-self: ${justifySelf};`;

export const alignContent = ({ alignContent }) =>
  alignContent && `align-content: ${alignContent};`;

export const alignitems = ({ alignitems }) =>
  alignitems && `align-items: ${alignitems};`;

export const wrap = ({ wrap }) => wrap && 'flex-wrap: wrap;';

export const shrink = ({ shrink }) => shrink && `flex-shrink: ${shrink};`;

export const direction = ({ direction }) =>
  direction && `flex-direction: ${direction};`;

export const relative = ({ relative }) => relative && 'position: relative;';

export const absolute = ({ absolute }) => absolute && 'position: absolute;';

export const borderradius = ({ borderradius }) =>
  borderradius && `border-radius: ${borderradius};`;

export const boxshadow = ({ boxshadow }) =>
  boxshadow && `box-shadow: ${boxshadow};`;

export const bordercolor = ({ bordercolor }) =>
  bordercolor && `border-color: ${bordercolor};`;

export const grow = ({ grow }) => grow && `flex-grow: ${grow};`;

export const fontSize = ({ fontSize }) => fontSize && `font-size: ${fontSize};`;

export const fontWeight = ({ fontWeight }) =>
  fontWeight && `font-weight: ${fontWeight};`;

export const columns = ({ columns }) =>
  columns && `grid-template-columns: ${columns};`;

export const rows = ({ rows }) => rows && `grid-template-rows: ${rows};`;

export const template = ({ template }) =>
  template && `grid-template: ${template};`;

export const gap = ({ gap }) => gap && `gap: ${gap};`;

export const rowGrap = ({ rowGrap }) => rowGrap && `row-grap: ${rowGrap};`;

export const columngap = ({ columngap }) =>
  columngap && `column-gap: ${columngap};`;

export const gridcolumn = ({ gridcolumn }) =>
  gridcolumn && `grid-template-columns: ${gridcolumn};`;

export const disablePointerEvents = ({ disablePointerEvents }) =>
  disablePointerEvents && 'pointer-events: none;';

export const underline = ({ underline }) =>
  underline && 'text-decoration: underline;';

export const hoverUnderline = ({ hoverUnderline }) =>
  hoverUnderline && '&:hover{ text-decoration: underline; }';

export const lineheight = ({ lineheight }) =>
  lineheight && `line-height: ${lineheight};`;

export const bold = ({ bold }) => bold && `font-weight: ${fontWeights.bold};`;

export const float = ({ float }) => float && `float: ${float};`;

export const transform = ({ transform }) =>
  transform && `transform: ${transform};`;

export const texttransform = ({ texttransform }) =>
  texttransform && `text-transform: ${texttransform};`;

export const minwidth = ({ minwidth }) => minwidth && `min-width: ${minwidth};`;

export const maxwidth = ({ maxwidth }) => maxwidth && `max-width: ${maxwidth};`;

export const minheightsize = ({ minheightsize }) =>
  minheightsize && `min-height: ${minheightsize};`;

export const maxheightsize = ({ maxheightsize }) =>
  maxheightsize && `max-height: ${maxheightsize};`;

export const heightsize = ({ heightsize }) =>
  heightsize && `height: ${heightsize};`;

export const overflowy = ({ overflowy }) =>
  overflowy && `overflow-y: ${overflowy};`;
export const overflow = ({ overflow }) => overflow && `overflow: ${overflow};`;
export const textoverflow = ({ textoverflow }) =>
  textoverflow && `text-overflow: ${textoverflow};`;
export const cursor = ({ cursor }) => cursor && `cursor: ${cursor};`;

export const w = ({ w }) => w && 'width: 100%;';

export const fontFamily = ({ fontFamily }) =>
  fontFamily && `font-family: ${fontFamily};`;

export const wordbreak = ({ wordbreak }) =>
  wordbreak && `word-break : ${wordbreak};`;

export const p = ({ p }) => p && `padding : ${p};`;

export const m = ({ m }) => m && `margin: ${m};`;

export const mt = ({ mt }) => mt && `margin-top: ${mt};`;

export const mb = ({ mb }) => mb && `margin-bottom: ${mb};`;

export const mr = ({ mr }) => mr && `margin-right: ${mr};`;

export const ml = ({ ml }) => ml && `margin-left: ${ml};`;

export const t = ({ t }) => t && `top: ${t};`;

export const r = ({ r }) => r && `right: ${r};`;

export const b = ({ b }) => b && `bottom: ${b};`;

export const l = ({ l }) => l && `left: ${l};`;
