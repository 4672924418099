import React from 'react';

import i18n from '../../../i18n';
import { colors } from '../../constant';
import Icon from '../icon/Icon';

export const types = [
  {
    key: 'success',
    color: colors.green,
    icon: 'success',
    title: i18n.t('success')
  },
  { key: 'error', color: colors.red, icon: 'error', title: i18n.t('error') },
  { key: 'info', color: colors.blue, icon: 'info', title: i18n.t('info') },
  {
    key: 'warning',
    color: colors.yellow,
    icon: 'warning',
    title: i18n.t('warningTitleCase')
  }
];

export const notificationConfig = (icon = 'success') => {
  return {
    icon: <Icon width="19" height="19" icon={icon}></Icon>,
    className: 'custom_notification',
    closeButton: <></>
  };
};
