import React, { useMemo } from 'react';
import { Navigate, Routes } from 'react-router';
import { Route } from 'react-router-dom';

import Layout from './Layout';
import Buckets from './buckets';
import { Login } from './auth/login/Login';
import { ResetPassword } from './auth/resetPassword/ResetPassword';
import Settings from './settings';
import { ProtectedRoute } from '../security/ProtectedRoute';
import { VerificationCode } from './auth/VerificationCode/VerificationCode';
import AccessKeysLogin from './auth/access-keys';
import NewRootAccessKeys from './auth/access-keys/components/NewRootAccessKeys';
import AccessKeyRequired from './auth/access-keys/components/AccessKeyRequired';
import Usage from './usage';
import PageNotFound from './page-not-found';

const ROUTES = [
  { Component: ProtectedRoute, index: true },
  { Component: Login, path: '/login' },
  { Component: ResetPassword, path: '/reset-password' },
  { Component: VerificationCode, path: '/verify-code' },
  { Component: AccessKeysLogin, path: '/access-keys' },
  { Component: NewRootAccessKeys, path: '/new-access-keys' },
  { Component: AccessKeyRequired, path: '/no-access-keys' },
  {
    Component: Buckets,
    path: '/buckets',
    childrenRoutes: Buckets.childrenRoutes
  },
  {
    Component: Settings,
    path: '/settings',
    childrenRoutes: Settings.childrenRoutes
  },
  { Component: Usage, path: '/usage', childrenRoutes: Usage.childrenRoutes }
];

const mapRoutes = ({ Component, path, childrenRoutes, index, redirectTo }) => (
  <Route
    key={path || index}
    index={index}
    path={path}
    element={Component ? <Component /> : <Navigate to={redirectTo} />}
  >
    {childrenRoutes && childrenRoutes.map(mapRoutes)}
  </Route>
);

export default function Router() {
  const routes = useMemo(() => {
    return ROUTES.map(mapRoutes);
  }, []);

  return (
    <>
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route
          path="/"
          element={<Layout publicRoutes={['/login', '/verify-code']} />}
        >
          {routes}
        </Route>
      </Routes>
    </>
  );
}
