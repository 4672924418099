import { action } from 'easy-peasy';

const attributes = {
  notification: {
    color: '',
    icon: 'info',
    title: ''
  },
  allNotifications: []
};

const actions = {
  setNotification: action((state, notification) => {
    state.notification = notification;
  }),
  setAllNotifications: action((state, notifications) => {
    state.allNotifications = notifications;
  })
};

const notifications = {
  ...attributes,
  ...actions
};

export default notifications;
