import React from 'react';

import { colors } from '../../../constant';
import { Logo } from '../styles';
import Box from '../../../common/box/Box';
import Text from '../../../common/text/Text';

const LoginHeader = ({ theme }) => {
  return (
    <Box
      display="flex"
      direction="column"
      alignitems="center"
      justifycontent="center"
    >
      <Logo src={theme.logo} alt="" />
      <Text
        fontSize="18px"
        fontWeight="500"
        mb="20px"
        wordbreak="break-word"
        textalign="center"
        color={colors.black}
      >
        {theme?.loginTitle}
      </Text>
    </Box>
  );
};

export default LoginHeader;
