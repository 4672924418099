import React from 'react';

export const Label = ({ filled, label, htmlFor, icon, ...props }) => {
  return (
    <label {...props} htmlFor={htmlFor} className={filled ? 'filledField' : ''}>
      {label}
      {icon && icon}
    </label>
  );
};
