import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from '../../../common/text/Text';
import CloseModalIcon from '../../../../assets/icons/x.svg';
import {
  CloseModalImg,
  ModalBasic,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../../../common/modal/Modal';
import Title from '../../../common/title/Title';
import Button from '../../../common/button/Button';
import hasPermission from '../../../../security/Permission';
import { disableUserMfa } from '../../endpoints';

const MfaModalDisabled = ({ open, close, id }) => {
  const { t } = useTranslation();
  const canUpdateMfaPerm = hasPermission('oem_profile', 'update_mfa');
  const [isLoading, setIsLoading] = useState(false);

  const handleDisableMfa = () => {
    setIsLoading(true);
    disableUserMfa(id, {
      enabled: false
    }).then(([res]) => {
      close({ success: res.success, message: res?.code || res?.Code });
      setIsLoading(false);
    });
  };

  return (
    <>
      {canUpdateMfaPerm && (
        <ModalBasic
          style={{
            width: '500px',
            overflowy: 'auto',
            maxHeight: '-webkit-fill-available'
          }}
          open={open}
          closeModal={close}
        >
          <div>
            <ModalHeader>
              <Title fontSize="16px" fontWeight="500">
                {t('disableMfa')}
              </Title>
              <CloseModalImg src={CloseModalIcon} onClick={close} />
            </ModalHeader>

            <ModalBody>
              <Text
                fontSize="14px"
                mb="15px"
                maxwidth="490px"
                textalign="center"
              >
                {t('disableMfaDescription')}
              </Text>
            </ModalBody>

            <ModalFooter display="flex" justifycontent="center" mt="30px">
              <Button className="cancel" onClick={close} mr="20px">
                {t('cancel')}
              </Button>
              <Button
                w="142px"
                isLoading={isLoading}
                onClick={handleDisableMfa}
                primary
              >
                {t('confirm')}
              </Button>
            </ModalFooter>
          </div>
        </ModalBasic>
      )}
    </>
  );
};

export default MfaModalDisabled;
