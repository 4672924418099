import styled from 'styled-components';

export const ResetKeysAndDescriptionContainer = styled.div``;

export const KeysTitle = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #0b293a;
  margin: 17px 0px;
`;

export const KeysDescription = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #6c757d;
  line-height: 16px;
  padding-top: 20px;
  margin-bottom: 10px;
`;

export const BoldDescription = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #6c757d;
`;

export const UnderlineDescription = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #6c757d;
  text-decoration: underline;
`;
