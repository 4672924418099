/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-access-key */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CreateAccessKey from '../../CreateAccessKey';
import DeleteModal from '../../../../common/modal/DeleteModal';
import { deleteAccessKey } from '../../../endpoints';
import { showToast } from '../../../../../hooks/show-toast';
import ResetAccessKeysModal from '../ResetAccessKeysModal';
import KeysModal from '../KeysModal';

export default function ActionModals({ state, dispatch, fetchAccessKeys }) {
  const { t } = useTranslation();
  const handleDeleteAccessKey = () => {
    dispatch({ type: 'SET_BUTTON_LOADING', payload: true });
    deleteAccessKey(state.accessKeyToDelete).then(([res]) => {
      if (res.success) {
        dispatch({ type: 'TOGGLE_DELETE_ACCESS_KEY', payload: false });
        fetchAccessKeys();
      }
      showToast(
        {
          message: res?.code || res?.Code,
          params: res?.params || null,
          success: res?.success
        },
        res?.success ? 'success' : 'error'
      );
      dispatch({ type: 'SET_BUTTON_LOADING', payload: false });
    });
  };
  return (
    <>
      <ResetAccessKeysModal
        open={state.resetKeysModal}
        close={() => dispatch({ type: 'SET_RESET_KEYS_MODAL', payload: false })}
        showSuccessModal={() =>
          dispatch({ type: 'SET_KEYS_ACTIONS_MODAL', payload: true })
        }
        setNewKeys={e => dispatch({ type: 'SET_NEW_KEYS', payload: e })}
      />
      <KeysModal
        accessKey={state.newKeys.accessKey}
        secretKey={state.newKeys.secretKey}
        open={state.keysActionsModal}
        close={() =>
          dispatch({ type: 'SET_KEYS_ACTIONS_MODAL', payload: false })
        }
      />
      <CreateAccessKey
        open={state.showCreateAccessKey}
        close={() =>
          dispatch({ type: 'SET_SHOW_CREATE_ACCESS_KEY', payload: false })
        }
        onSubmit={() => fetchAccessKeys(false)}
      />
      <DeleteModal
        isLoading={state.buttonLoading}
        open={state.showDeleteAccessKey}
        close={() =>
          dispatch({ type: 'TOGGLE_DELETE_ACCESS_KEY', payload: false })
        }
        onSubmit={e => handleDeleteAccessKey(e)}
        deleteText={t('deleteConfirmationKey')}
        text={t('deleteUpperCase')}
        name={t('rootAccessKey')}
      />
    </>
  );
}

ActionModals.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  fetchAccessKeys: PropTypes.func.isRequired
};
