import React from 'react';
import PropTypes from 'prop-types';
import 'rsuite/dist/rsuite.css';
import { useTranslation } from 'react-i18next';
import { DateRangePicker, Stack } from 'rsuite';

export function subtractDaysFromDate(date, days = 29) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() - days);
  const timezoneOffset = newDate.getTimezoneOffset();
  newDate.setMinutes(newDate.getMinutes() - timezoneOffset);
  return newDate;
}

export default function RangePickerComp({
  onChange,
  value,
  disabledDate,
  cleanable = true,
  display = true,
  lastSync
}) {
  const { t } = useTranslation();
  return (
    <Stack direction="column" spacing={8} alignItems="flex-start">
      {display && (
        <DateRangePicker
          value={value}
          ranges={[
            {
              label: t('lastThirtyDays'),
              value: [
                subtractDaysFromDate(lastSync || new Date(), 29),
                subtractDaysFromDate(lastSync || new Date(), 0)
              ],
              placement: 'left'
            }
          ]}
          disabledDate={disabledDate}
          style={{ width: 235 }}
          placeholder={t('selectDate')}
          placement="bottomEnd"
          format="dd-LLL-yyyy"
          onChange={onChange}
          cleanable={cleanable}
          character=" - "
        />
      )}
    </Stack>
  );
}

RangePickerComp.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  disabledDate: PropTypes.string.isRequired,
  cleanable: PropTypes.bool.isRequired,
  display: PropTypes.bool.isRequired,
  lastSync: PropTypes.string.isRequired
};
