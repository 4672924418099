/* eslint-disable array-callback-return */
import { action } from 'easy-peasy';

const attributes = {
  filesUploadingGlobal: [],
  selectedFilesGlobal: [],
  customToastId: 'custom-id'
};

const actions = {
  addFileToUploadingFiles: action((state, payload) => {
    state.filesUploadingGlobal = state.filesUploadingGlobal.concat(payload);
  }),
  removeFileByUploadingModal: action((state, payload) => {
    const files = state.filesUploadingGlobal.filter(item => item !== payload);
    state.filesUploadingGlobal = files;
  }),
  addFileToSelectedFiles: action((state, payload) => {
    state.selectedFilesGlobal = state.selectedFilesGlobal.concat(payload);
  }),
  removeFileFromSelectedFiles: action((state, payload) => {
    const files = state.selectedFilesGlobal.filter(
      item => item.file.path !== payload
    );
    state.selectedFilesGlobal = files;
  }),
  setFileSuccessful: action((state, { file, status }) => {
    state.selectedFilesGlobal.map((item, _index) => {
      if (file.file.path === item.file.path) {
        item.displaySuccessIcon = status;
      }
    });
  }),
  setFileError: action((state, { file, status }) => {
    state.selectedFilesGlobal.map((item, _index) => {
      if (file.file.path === item.file.path) {
        item.displayErrorIcon = status;
      }
    });
  }),
  setSelectedFilesToArray: action((state, payload) => {
    const files = payload?.length > 0 ? [...payload] : [];
    state.selectedFilesGlobal = files;
  }),
  setCustomToastId: action((state, payload) => {
    state.customToastId = payload;
  }),
  calculatePercentage: action((state, { fileName, number, updateAnyway }) => {
    const fileSelected = state.selectedFilesGlobal
      ?.filter(obj => obj.file.path === fileName)
      .pop();
    if (fileSelected) {
      fileSelected.percentage =
        number > fileSelected.percentage || updateAnyway
          ? number
          : fileSelected.percentage;
      const arr = state.selectedFilesGlobal.map(item =>
        item.file.path === fileSelected.file.path ? fileSelected : item
      );
      state.selectedFilesGlobal = arr;
    }
  })
};

const fileUpload = {
  ...attributes,
  ...actions
};

export default fileUpload;
