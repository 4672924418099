/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import { useStoreActions } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import DeleteModal from '../../../../../common/modal/DeleteModal';
import { deleteSsoConfiguration } from '../../../../endpoints';
import { showToast } from '../../../../../../hooks/show-toast';
import { logOut } from '../../../../../header/endpoints';

export default function DeleteOrganizationModal({ open, close, onSuccess }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const isSsoUser = JSON.parse(localStorage.getItem('userIsSso'));

  const { logout, setAuthLoader } = useStoreActions(
    actions => actions.authentication
  );

  const onLogout = e => {
    setAuthLoader(true);
    localStorage.setItem('ssoDeletedOrg', 'true');
    logOut()
      .then(([res]) => {
        if (res?.success) logout();
      })
      .catch(() => {
        setAuthLoader(false);
      });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    deleteSsoConfiguration()
      .then(([res]) => {
        if (res.success) {
          if (isSsoUser) onLogout();
          else {
            showToast(
              {
                message: res?.code || res?.Code,
                params: res?.params || null,
                success: true
              },
              'success'
            );
          }
          onSuccess();
          close();
        } else {
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: false
            },
            'error'
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <DeleteModal
      open={open}
      close={close}
      isLoading={isLoading}
      onSubmit={() => handleSubmit()}
      deleteText={t('deleteSSOConfigText')}
      text={t('deleteUpperCase')}
      name={t('ssoConfigName')}
    />
  );
}

DeleteOrganizationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};
