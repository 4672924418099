import React from 'react';
import { StyledBadge, TimeContainer } from './styles';
import Icon from '../icon/Icon';

const Badge = props => {
  const {
    backgroundColor = '#CEFFDD',
    color = '#073A16',
    text = 'No Status',
    remainingTime
  } = props;
  return (
    <StyledBadge backgroundColor={backgroundColor} color={color}>
      {text}
      {remainingTime && (
        <TimeContainer>
          <Icon height="11px" width="12px" icon="locked_clock" />
          {remainingTime}
        </TimeContainer>
      )}
    </StyledBadge>
  );
};
export default Badge;
