import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccountInformationHeaderContainer,
  AccountInformationTitle
} from './styles';

const AccountInformationHeader = () => {
  const { t } = useTranslation();

  return (
    <AccountInformationHeaderContainer>
      <AccountInformationTitle>
        {t('accountInformation')}
      </AccountInformationTitle>
    </AccountInformationHeaderContainer>
  );
};

export default AccountInformationHeader;
