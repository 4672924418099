import styled from 'styled-components';

export const AccessKeyRequiredContainer = styled.div`
  width: 100%;
  height: 100vh;
  margin-top: -60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const AccessKeyRequiredModal = styled.div`
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.101617);
  border-radius: 4px;
  width: 452px;
  height: 150px;
  padding: 15px 30px;
  box-sizing: initial;
`;

export const KeysTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #0b293a;
  margin: 17px 0px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 20px;
`;

export const KeysDescription = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #6c757d;
  line-height: 16px;
  padding-top: 10px;
`;
