import React, { useRef } from 'react';
import jsPDF from 'jspdf';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import Text from '../../../common/text/Text';
import CloseModalIcon from '../../../../assets/icons/x.svg';
import {
  CloseModalImg,
  ModalBasic,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../../../common/modal/Modal';
import Title from '../../../common/title/Title';
import Button from '../../../common/button/Button';
import hasPermission from '../../../../security/Permission';
import Icon from '../../../common/icon/Icon';
import { colors } from '../../../constant';

import { ComponentToPrint } from './ComponentToPrint';

export default function MfaModalRecoveryKey({
  open,
  close,
  secretKey,
  qrCode
}) {
  const { t } = useTranslation();
  const componentRef = useRef();
  const showComponentToPrint = false;
  const canUpdateMfaPerm = hasPermission('oem_profile', 'update_mfa');

  const print = () => {
    const doc = new jsPDF('p', 'mm', 'a4');
    doc.setFont('Roboto');
    doc.html(componentRef.current, {
      callback: function() {
        doc.save('MfaRecoveryKey.pdf');
      },
      width: 264,
      windowWidth: 1000,
      y: 16
    });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <>
      {canUpdateMfaPerm && (
        <ModalBasic
          style={{
            width: '500px',
            overflowy: 'auto',
            maxHeight: '-webkit-fill-available'
          }}
          open={open}
          closeModal={close}
        >
          <div>
            <ModalHeader>
              <Title fontSize="16px" fontWeight="500">
                {t('downloadPrintRecoveryKey')}
              </Title>
              <CloseModalImg src={CloseModalIcon} onClick={close} />
            </ModalHeader>

            <ModalBody display="flex" direction="column" alignitems="center">
              <Icon
                icon="verify"
                width="36px"
                height="36px"
                strokeWidth="0.5px"
                fill="none"
              ></Icon>
              <Text
                fontSize="14px"
                mb="15px"
                maxwidth="490px"
                textalign="center"
                mt="20px"
              >
                {t('recoveryKeyDescription')}
              </Text>
            </ModalBody>

            <ModalFooter display="flex" justifycontent="flex-end" mt="30px">
              <Button
                bgcolor={colors.lightGray}
                w="40px"
                minheight="40px"
                borderRadius="2px"
                padding="0px"
                center
                onClick={print}
                primary
                mr="10px"
              >
                <Icon icon="download" width="40px" height="16px"></Icon>
              </Button>

              <Button
                bgcolor={colors.lightGray}
                w="40px"
                minheight="40px"
                borderRadius="2px"
                padding="0px"
                center
                onClick={handlePrint}
                primary
              >
                <Icon icon="print" width="40px" height="16px"></Icon>
              </Button>
            </ModalFooter>
          </div>

          <div style={{ display: 'none' }}>
            <ComponentToPrint
              ref={componentRef}
              open={showComponentToPrint}
              secretKey={secretKey}
              qrCode={qrCode}
            />
          </div>
        </ModalBasic>
      )}
    </>
  );
}

MfaModalRecoveryKey.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  secretKey: PropTypes.string.isRequired,
  qrCode: PropTypes.string.isRequired
};
