import { identifyUser, initializeErrorReporting } from '@bluearchive/ui-common';

/**
 * This function is the default export for this module. It initializes error reporting and identifies the user if available.
 *
 * @param {Object} user - An object containing information about the user, if available.
 * @param {string} user.name - The name of the user.
 * @param {string} user.fullName - The full name of the user.
 */
export default function config(user) {
  const dsn = process.env.REACT_APP_DSN;
  const appID = process.env.REACT_APP_APP_ID;
  const appPrefix = process.env.REACT_APP_APP_PREFIX;
  const sampleRate = 1.0;
  const debug = process.env.REACT_APP_REPORT_ERRORS_DEBUG;
  const env = process.env.REACT_APP_BUILD_VERSION;

  if (env === 'qa' || env === 'qa2' || env === 'stage' || env === 'prod') {
    // Initialize error reporting with the retrieved values.
    initializeErrorReporting(
      appID,
      dsn,
      appPrefix,
      undefined,
      undefined,
      sampleRate,
      debug,
      env
    );

    // If a user is available, identify them using the "identifyUser" function.
    if (user) {
      identifyUser({ Name: user?.name, FullName: user?.fullName });
    }
  }
}
