import React from 'react';
import {
  ConfigContainerStyle,
  SSOConfigDescriptions,
  SSOConfigHeaderContainer,
  SSOConfigTitle
} from '../styles';
import { colors } from '../../../../../constant';
import i18n from '../../../../../../i18n';

const ConfigContainer = ({
  title = i18n.t('samlConnection'),
  children,
  description = i18n.t('general')
}) => {
  return (
    <ConfigContainerStyle>
      <SSOConfigHeaderContainer isToggled borderColor={colors.gray}>
        <SSOConfigTitle>{title}</SSOConfigTitle>
      </SSOConfigHeaderContainer>
      <SSOConfigDescriptions fontSize="14px" fontWeight="500">
        {description}
      </SSOConfigDescriptions>
      {children}
    </ConfigContainerStyle>
  );
};

export default ConfigContainer;
