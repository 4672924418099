import React from 'react';
import { AccountInformationContainer } from './styles';
import AccountInformationHeader from './AccountInformationHeader';
import AccountInformationData from './AccountInformationData';

const AccountInfomration = () => {
  return (
    <AccountInformationContainer>
      <AccountInformationHeader />
      <AccountInformationData />
    </AccountInformationContainer>
  );
};

export default AccountInfomration;
