import React from 'react';
import { Outlet } from 'react-router';

import { BucketsList } from './BucketsList';
import BucketDetails from './details/BucketDetails';

const Buckets = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};

Buckets.childrenRoutes = [
  { Component: BucketsList, exact: true, index: true },
  {
    path: ':name',
    exact: true,
    Component: BucketDetails,
    childrenRoutes: BucketDetails.childrenRoutes
  }
];

export default Buckets;
