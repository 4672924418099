import styled from 'styled-components';

export const Title = styled.label`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #0b293a;
  cursor: pointer;
`;
export const Description = styled.h1`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top: 7px;
  color: #6c757d;
`;
