import { DatePicker as Dpicker } from 'rsuite';
import styled from 'styled-components';
import 'rsuite/dist/rsuite.min.css';

export const DatePickerStyled = styled(Dpicker)`
  height: 40px;
  width: 200px;
`;

export const Label = styled.label`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #6c757d;
`;
