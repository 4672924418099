import styled from 'styled-components';
import { colors } from '../../constant';
import { absolute, display, b, l, m, r, t } from '../../properties';

const Line = styled.div`
  width: ${props => (props.width ? props.width : '100%')};
  border-bottom: ${props => (props.border ? props.border : '1px solid')};
  border-width: ${props => (props.borderWidth ? props.borderWidth : '1px')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '0')};
  border-color: ${props => (props.color ? props.color : colors.lightGray)};

  ${m}
  ${absolute}
  ${t}
  ${r}
  ${b}
  ${l}
  ${display}
`;

export default Line;
