/* eslint-disable no-useless-escape */
import * as Yup from 'yup';
import i18n from '../../../i18n';

export const initialValues = {
  password: '',
  confirmPassword: ''
};

export const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required(i18n.t('passwordRequired'))
    .min(6, i18n.t('passwordReqOnlyLength'))
    .matches(
      /(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[\" \!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\=\>\?\@\[\\\]\^\_\`\{\|\}\~\"])(?=.*[a-zA-Z]).{6,}/,
      i18n.t('passwordPattern')
    ),
  confirmPassword: Yup.string()
    .required(i18n.t('confirmPasswordRequired'))
    .oneOf([Yup.ref('password'), null], i18n.t('passwordsMustMatch'))
});
