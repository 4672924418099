/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import jwt from 'jwt-decode';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
  getSubAccount,
  resetRootAccessKeys,
  updateRootAccessKeys
} from '../endpoints';
import Box from '../../common/box/Box';
import Icon from '../../common/icon/Icon';
import { showToast } from '../../../hooks/show-toast';
import { LoaderWrap } from '../../common/loader/Loader';

import KeysAndDescription from './components/KeysAndDescription';
import {
  AccessKeysContainer,
  AccessKeysModal,
  ConfirmResetTitle,
  initialValues,
  ModalTitle,
  resetInitialValues,
  resetValidationSchema,
  validationSchema
} from './helpers';
import Footer from './components/Footer';
import ConfirmResetKeys from './components/ConfirmReset';
import ResetAccessKeys from './components/reset-access-keys';

const AccessKeysLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isResetForm, setIsResetForm] = useState(false);
  const [showConfirmPage, setShowConfirmPage] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(true);
  const [subAccount, setSubAccount] = useState({ loading: false, email: '' });

  const userData = jwt(localStorage.getItem('Authorization'));

  const onSubmit = (values, actions) => {
    if (isResetForm) {
      resetRootAccessKeys()
        .then(([res]) => {
          if (res?.success) {
            localStorage.setItem('Authorization', res.data.Auth.AccessToken);
            localStorage.setItem('RefreshToken', res.data.Auth.RefreshToken);
            //Here I set notification and render it in Router.js
            showToast(
              {
                message: res?.code || res?.Code,
                params: res?.params || null,
                success: true
              },
              'success'
            );
            setResetSuccess(true);
            setTimeout(() => {
              navigate('/new-access-keys', {
                state: {
                  accessKey: res.data.AccessKeyId,
                  secretKey: res.data.SecretAccessKey
                }
              });
              actions.setSubmitting(false);
            }, 200);
          } else {
            showToast(
              {
                message: res?.code || res?.Code,
                params: res?.params || null,
                success: false
              },
              'error'
            );
            actions.setSubmitting(false);
          }
        })
        .catch(err => {
          showToast(
            {
              message: err?.code || err?.Code,
              params: err?.params || null,
              success: false
            },
            'error'
          );
          actions.setSubmitting(false);
        });
    } else {
      updateRootAccessKeys(values)
        .then(([res]) => {
          if (res?.success) {
            localStorage.setItem('Authorization', res.data.Auth.AccessToken);
            localStorage.setItem('RefreshToken', res.data.Auth.RefreshToken);
            showToast(
              {
                message: res?.code || res?.Code,
                params: res?.params || null,
                success: true
              },
              'success'
            );
            setTimeout(() => {
              navigate('/buckets');
              actions.setSubmitting(false);
            }, 600);
          } else {
            showToast(
              {
                message: res?.code || res?.Code,
                params: res?.params || null,
                success: false
              },
              'error'
            );
            actions.setSubmitting(false);
          }
        })
        .catch(err => {
          showToast(
            {
              message: err?.code || err?.Code,
              params: err?.params || null,
              success: false
            },
            'error'
          );
          actions.setSubmitting(false);
        });
    }
  };
  const formik = {
    ...useFormik({
      validationSchema: isResetForm ? resetValidationSchema : validationSchema,
      initialValues: isResetForm ? resetInitialValues : initialValues,
      enableReinitialize: true,
      onSubmit: (fieldValues, actions) => onSubmit(fieldValues, actions)
    })
  };

  useEffect(() => {
    setSubAccount({ ...subAccount, loading: true });
    getSubAccount().then(([res]) => {
      setSubAccount({ email: res?.data?.Name, loading: false });
    });
  }, []);

  useEffect(() => {
    if (
      !userData?.roles.includes('ROOT') &&
      (!userData?.user?.AccessKey?.length > 0 ||
        !userData?.user?.SecretKey?.length > 0)
    ) {
      navigate('/no-access-keys');
    }
    if (
      !resetSuccess &&
      userData?.user?.AccessKey?.length > 0 &&
      userData?.user?.SecretKey?.length > 0
    ) {
      navigate('/buckets');
    }
    setDisplayLoader(false);
  }, [userData]);

  return (
    <AccessKeysContainer>
      <AccessKeysModal>
        {showConfirmPage ? (
          <>
            <Box>
              <ConfirmResetTitle>
                <Icon
                  onClick={() => setShowConfirmPage(false)}
                  icon="arrow_left"
                  height="15px"
                />
                <span>{t('confirmRootAccessKeyReset')}</span>
              </ConfirmResetTitle>
            </Box>
            <ConfirmResetKeys formik={formik} />
          </>
        ) : (
          <>
            <ModalTitle>
              {isResetForm ? t('resetRootAccessKeys') : t('accessKeysRequired')}{' '}
            </ModalTitle>
            {isResetForm ? (
              <ResetAccessKeys />
            ) : (
              <KeysAndDescription formik={formik} email={subAccount.email} />
            )}
            <Footer
              toggleView={() => setIsResetForm(!isResetForm)}
              formik={formik}
              isResetForm={isResetForm}
              onClick={
                isResetForm
                  ? () => setShowConfirmPage(true)
                  : formik.handleSubmit
              }
            />
          </>
        )}
      </AccessKeysModal>
      <LoaderWrap display={subAccount.loading || displayLoader} />
    </AccessKeysContainer>
  );
};

export default AccessKeysLogin;
