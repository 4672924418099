import styled from 'styled-components';

export const ConfirmAndDescriptionContainer = styled.div`
  position: relative;
`;

export const ConfirmDescription = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #6c757d;
  line-height: 16px;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const BoldDescription = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #6c757d;
`;

export const UnderlineDescription = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #6c757d;
  text-decoration: underline;
`;
