import React from 'react';
import { Outlet } from 'react-router';
import SettingsTabs from './SettingsTabs';
import Box from '../common/box/Box';
import Profile from './profile';
import { AccessKeys } from './accessKeys/AccessKeys';
import { Members } from './members/Members';
import MemberDetails from './members/MemberDetails';
import Account from './account';

const Settings = () => {
  return (
    <Box
      display="flex"
      justifycontent="center"
      direction="column"
      alignitems="center"
    >
      <Box
        w="800px"
        display="flex"
        direction="column"
        maxwidth="800px"
        p="0 0 30px"
        alignitems="center"
      >
        <SettingsTabs />
        <Outlet />
      </Box>
    </Box>
  );
};

Settings.childrenRoutes = [
  { redirectTo: '/settings/profile', index: true },
  { path: '/settings/profile', Component: Profile },
  { path: '/settings/members/:id', Component: MemberDetails },
  { path: '/settings/members', Component: Members },
  { path: '/settings/access-keys', Component: AccessKeys },
  { path: '/settings/account', Component: Account }
];

export default Settings;
