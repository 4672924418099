import React from 'react';
import { useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';

import { ChangePasswordNotAvailableContainer } from './styles';

import Title from '../title/Title';
import SSOIcon from '../../../assets/images/SSOIcon';

const ChangePasswordNotAvailable = () => {
  const { t } = useTranslation();
  const { theme } = useStoreState(state => state.theme);

  return (
    <ChangePasswordNotAvailableContainer>
      <SSOIcon width="90px" fill={theme.themes?.colorPrimary} />
      <Title fontSize="14px">{t('passwordSecuritySettingsManaged')}</Title>
    </ChangePasswordNotAvailableContainer>
  );
};

export default ChangePasswordNotAvailable;
