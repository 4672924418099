import { actionOn } from 'easy-peasy';

const SUFFIX = 'Request';

function createLoadingThunks(store) {
  const reducerNames = Object.keys(store);

  const newStore = {};
  reducerNames.map(
    reducerName => (newStore[reducerName] = enhance(store[reducerName]))
  );

  return newStore;
}

const enhance = reducer => {
  const thunkNames = Object.keys(reducer).filter(key => key.endsWith(SUFFIX));

  return {
    ...reducer,
    loading: false,
    ...makeListener(thunkNames)
  };
};

function makeListener(thunkNames) {
  return {
    loadingStart: actionOn(getTypes(thunkNames, 'startType'), state => {
      state.loading = true;
    }),
    loadingEnd: actionOn(getTypes(thunkNames, 'successType'), state => {
      state.loading = false;
    }),
    loadingFail: actionOn(getTypes(thunkNames, 'failType'), state => {
      state.loading = false;
    })
  };
}

function getTypes(thunkNames, actionType) {
  return actions => thunkNames.map(name => actions[name][actionType]);
}

export default createLoadingThunks;
