import React from 'react';
import PropTypes from 'prop-types';

export const OrganizationIcon = ({ fill = '#01CD3E' }) => {
  return (
    <svg
      width="101"
      height="106"
      viewBox="0 0 101 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_831_9679)">
        <path d="M21.7394 71.586H16.3057V64.8545H21.7394V71.586Z" fill={fill} />
        <path
          d="M21.7394 59.4698H16.3057V52.7383H21.7394V59.4698Z"
          fill={fill}
        />
        <path
          d="M21.7394 83.7018H16.3057V76.9717H21.7394V83.7018Z"
          fill={fill}
        />
        <path
          d="M21.7394 96.7159H16.3057V90.4326H21.7394V96.7159Z"
          fill={fill}
        />
        <path d="M83.7897 71.586H78.3545V64.8545H83.7897V71.586Z" fill={fill} />
        <path
          d="M83.7897 59.4698H78.3545V52.7383H83.7897V59.4698Z"
          fill={fill}
        />
        <path
          d="M83.7897 83.7018H78.3545V76.9717H83.7897V83.7018Z"
          fill={fill}
        />
        <path
          d="M83.7897 96.7159H78.3545V90.4326H83.7897V96.7159Z"
          fill={fill}
        />
        <path
          d="M52.5379 47.3531H47.5566V41.0713H52.5379V47.3531Z"
          fill={fill}
        />
        <path
          d="M64.7662 47.3531H59.3311V41.0713H64.7662V47.3531Z"
          fill={fill}
        />
        <path
          d="M40.7623 47.3531H35.3271V41.0713H40.7623V47.3531Z"
          fill={fill}
        />
        <path
          d="M52.5379 32.9932H47.5566V26.2617H52.5379V32.9932Z"
          fill={fill}
        />
        <path
          d="M64.7662 32.9932H59.3311V26.2617H64.7662V32.9932Z"
          fill={fill}
        />
        <path
          d="M40.7623 32.9932H35.3271V26.2617H40.7623V32.9932Z"
          fill={fill}
        />
        <path
          d="M52.5379 62.1622H47.5566V55.4307H52.5379V62.1622Z"
          fill={fill}
        />
        <path
          d="M64.7662 62.1622H59.3311V55.4307H64.7662V62.1622Z"
          fill={fill}
        />
        <path
          d="M40.7623 62.1622H35.3271V55.4307H40.7623V62.1622Z"
          fill={fill}
        />
        <path
          d="M52.5379 76.9718H47.5566V70.2402H52.5379V76.9718Z"
          fill={fill}
        />
        <path
          d="M64.7662 76.9718H59.3311V70.2402H64.7662V76.9718Z"
          fill={fill}
        />
        <path
          d="M40.7623 76.9718H35.3271V70.2402H40.7623V76.9718Z"
          fill={fill}
        />
        <path
          d="M90.9002 101.999H72.0462V47.2917H90.9002V101.997V101.999ZM68.006 101.999H58.5797V83.9856C58.5797 82.8785 57.6777 81.9848 56.5603 81.9848H41.7466C40.6293 81.9848 39.7273 82.8785 39.7273 83.9856V101.999H31.6482V21.941H68.0074V101.999H68.006ZM54.5395 101.999H43.766V85.9879H54.5395V101.999ZM27.6066 101.999H8.75408V47.2917H27.6066V101.997V101.999ZM50.4993 4.99479L57.8122 7.97099L50.4993 10.5991V4.99479ZM98.9792 43.2886H72.0462V21.941H76.0864C77.2038 21.941 78.1058 21.0473 78.1058 19.9402C78.1058 18.8331 77.2038 17.9394 76.0864 17.9394H50.4993V14.8572L64.1002 9.96037C65.8319 9.35023 65.8883 6.93691 64.1811 6.22509L49.6782 0.325696C48.0939 -0.474925 46.459 0.259813 46.459 1.92694V17.9379H22.2206C21.1032 17.9379 20.2012 18.8317 20.2012 19.9388C20.2012 21.0459 21.1032 21.9396 22.2206 21.9396H27.6066V43.2886H2.01939C0.902005 43.2886 0 44.1823 0 45.2894C0 46.3965 0.902005 47.2902 2.01939 47.2902H4.7124V101.996H0.673613V105.999H98.9792V101.996H94.9389V47.2917H98.9792C100.097 47.2917 100.999 46.398 100.999 45.2908C100.999 44.1837 100.097 43.29 98.9792 43.29V43.2886Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_831_9679">
          <rect width="101" height="106" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

OrganizationIcon.propTypes = {
  fill: PropTypes.string.isRequired
};
