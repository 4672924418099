import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '../box/Box';
import Text from '../text/Text';
import Icon from '../icon/Icon';
import { colors } from '../../constant';

export default function DropdownButton() {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      alignitems="center"
      justifycontent="center"
      bgcolor={colors.softGray}
      minheightsize="40px"
      borderradius="4px"
    >
      <Text>
        {t('filterBy')}
        <Icon icon="arrow_down" width="8px" height="8px" ml="20px"></Icon>
      </Text>
    </Box>
  );
}

DropdownButton.propTypes = {};
