import React from 'react';

import hasPermission from '../../../security/Permission';

import ProfileDetails from './ProfileDetails';

const Profile = () => {
  const canReadProfilePerm = hasPermission('oem_profile', 'read');

  return (
    <>
      {canReadProfilePerm && (
        <div style={{ width: '100%' }}>
          <ProfileDetails />
        </div>
      )}
    </>
  );
};

export default Profile;
