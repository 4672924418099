/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import VerificationInput from 'react-verification-input';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Text from '../../../common/text/Text';
import CloseModalIcon from '../../../../assets/icons/x.svg';
import {
  CloseModalImg,
  ModalBasic,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../../../common/modal/Modal';
import Title from '../../../common/title/Title';
import Button from '../../../common/button/Button';
import { updateUserMfa } from '../../endpoints';
import Box from '../../../common/box/Box';
import { LoaderWrap } from '../../../common/loader/Loader';
import hasPermission from '../../../../security/Permission';
import { colors } from '../../../constant';
import { showToast } from '../../../../hooks/show-toast';

export default function MultiFactorAuthModal({ open, close, qrCode }) {
  const { t } = useTranslation();
  const canUpdateMfaPerm = hasPermission('oem_profile', 'update_mfa');
  const AuthInputRef = useRef(null);
  const [qrCodeImage, setQrCodeImage] = useState('');
  const [secret, setSecret] = useState('');
  const [code, setCode] = useState('');
  const [inputCode, setInputCode] = useState('');
  const [displayLoader, setDisplayLoader] = useState(false);
  const [enableDisabled, setEnableDisabled] = useState(true);

  useEffect(() => {
    if (open) {
      updateUserMfa({
        enabled: false
      }).then(([res]) => {
        if (res.success && res.data) {
          setQrCodeImage(res.data.qrCodeBase64Image);
          qrCode(res.data.qrCodeBase64Image);
          setSecret(res.data.secret);
        } else {
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: res?.success
            },
            res?.success ? 'success' : 'error'
          );
        }
      });
    } else {
      setQrCodeImage('');
      setSecret('');
      setCode('');
      setInputCode('');
      AuthInputRef.current?.clear();
    }

    return () => {
      setQrCodeImage('');
      setSecret('');
      setCode('');
      setInputCode('');
      AuthInputRef.current?.clear();
      setEnableDisabled(true);
    };
  }, [open]);

  const handleEnable = () => {
    setDisplayLoader(true);

    updateUserMfa({
      enabled: true,
      code: code
    }).then(([res]) => {
      setDisplayLoader(false);
      if (res.success) {
        close({ secret, success: true, message: res?.code || res?.Code });
        setCode('');
        setInputCode('');
      } else if (!res.success) {
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
      }
    });
  };

  useEffect(() => {}, [secret]);

  const handleCodeChange = codeVerify => {
    setCode(codeVerify);
    setInputCode(codeVerify);
    if (codeVerify && codeVerify.length === 6) {
      setEnableDisabled(false);
    } else {
      setEnableDisabled(true);
    }
  };

  return (
    <>
      {canUpdateMfaPerm && (
        <ModalBasic
          style={{
            minWidth: '500px',
            overflowy: 'auto',
            maxHeight: '88vh',
            mt: '60px'
          }}
          open={open}
          closeModal={close}
        >
          <Box relative="true" minHeight="620px">
            <ModalHeader>
              <Title color={colors.black} fontSize="16px" fontWeight="500">
                {t('enableMultiFactorAuth')}
              </Title>
              <CloseModalImg src={CloseModalIcon} onClick={close} />
            </ModalHeader>

            <ModalBody>
              <Text fontSize="14px" mb="15px" maxwidth="490px">
                {t('installAuthyOrGoogleAuthenticator')}
              </Text>

              <Text fontSize="14px" mb="15px" maxwidth="490px">
                {t('cantScanCode')}
              </Text>

              <Text fontSize="14px">
                {t('key')}: {secret}
              </Text>

              <Box
                minheightsize="202px"
                border="true"
                w="100%"
                center="true"
                m="20px auto 30px"
                relative
              >
                {!!qrCodeImage?.length && <QrCodeImg src={qrCodeImage} />}
                <LoaderWrap display={!qrCodeImage?.length} height="202px" />
              </Box>

              <Text fontSize="16px" fontWeight="500" color={colors.black}>
                {t('verificationCode')}
              </Text>

              <VerificationInput
                autoFocus={true}
                length={6}
                validChars="0-9"
                inputProps={{ type: 'tel' }}
                onChange={!displayLoader ? handleCodeChange : null}
                placeholder=""
                value={inputCode}
                classNames={{
                  container: 'verify-wrap',
                  character: 'input-verify-code',
                  characterInactive: 'input-verify-code--inactive',
                  characterSelected: 'input-verify-code--selected'
                }}
              />
            </ModalBody>

            <ModalFooter display="flex" justifycontent="flex-end" mt="30px">
              <Button className="cancel" onClick={close} mr="10px">
                {t('cancel')}
              </Button>
              <Button
                w="134px"
                isLoading={displayLoader}
                onClick={handleEnable}
                disabled={enableDisabled}
                primary
              >
                {t('enable')}
              </Button>
            </ModalFooter>
          </Box>
        </ModalBasic>
      )}
    </>
  );
}

const QrCodeImg = styled.img``;

MultiFactorAuthModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  qrCode: PropTypes.string.isRequired
};
