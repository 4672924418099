export const colors = {
  //Primary
  primary: '#FF4A6A',
  //Secondary
  secondary: '#EDEEF7',
  //White//
  white: '#FFFFFF',
  //Black
  black: '#0B293A',
  //Success
  success: '#55AC63',
  //Gray
  bodyColor: '#6C757D',
  //LightGray
  lightGray: '#F1F1F1',
  //SoftGray
  softGray: '#F5F6F8',
  //Gray:
  gray: '#E5E5E6',
  //Gray10
  gray10: '#979797',
  //Gray20
  gray20: '#525E6B',
  //Gray30
  gray30: '#788089',
  //gray40
  gray40: '#A1B3C4',
  //darkBlue
  darkBlue: '#0B2E44',
  //ErrorColor
  errorColor: '#FF4A6A',
  //Green
  green: '#019A4E',
  //Green10
  green10: '#00B537',
  //Green20
  green20: '#CEFFDD',
  //DarkGreen
  darkGreen: '#073A16',
  //Yellow
  yellow: '#EFB451',
  //Red
  red: '#EB4343',
  //Pink
  pink: '#FDE0DF',
  //Brown
  brown: '#5D3115',
  //Blue
  blue: '#0E66FF',
  //MUIBlue
  mui_blue: '#1976d2'
};

export const fontSizes = {
  default: 'inherit',
  inherit: 'inherit',
  header: '1.5em',
  bodyLarge: '1.125rem',
  body: '1rem',
  bodySmall: '0.92rem',
  small: '0.85rem',
  2.5: '2.5rem',
  h1: '32px',
  h2: '28px',
  h3: '24px',
  h4: '20px',
  h5: '18px',
  h6: '16px'
};

export const fontWeights = {
  regular: '400',
  medium: '500',
  bold: '600',
  extraBold: '700'
};

export const fontFamily = {
  roboto: 'Roboto'
};

// Values to be used as REMs
export const spacings = {
  0: 0,
  1: 0.25,
  2: 0.5,
  3: 0.75,
  4: 1,
  5: 1.5,
  6: 2,
  7: 3,
  8: 3.75,
  9: 6,
  a: 'auto'
};

export const theme = {
  colors,
  fontSizes,
  fontWeights
};

export const apiPath = '/api';
