/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Outlet } from 'react-router';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

export const ProtectedRoute = ({ user, redirectPath = '/login' }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!user || !user.Id) {
      navigate(redirectPath);
    }
    if (user?.IsFirstLogin) {
      navigate('/reset-password');
    }
  }, []);
  if (user?.Id) return <Outlet />;
};

ProtectedRoute.propTypes = {
  user: PropTypes.object.isRequired,
  redirectPath: PropTypes.string.isRequired
};
