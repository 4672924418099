import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { colors } from '../../constant';
import {
  CloseModalImg,
  ModalBasic,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../../common/modal/Modal';
import CloseModalIcon from '../../../assets/icons/x.svg';
import Title from '../../common/title/Title';
import Button from '../../common/button/Button';
import Input from '../../common/input/Input';

const ConfirmVersioning = props => {
  const { t } = useTranslation();
  const {
    open,
    close,
    title,
    children,
    handleSubmit,
    modalWidth = '730px',
    isLoading
  } = props;
  return (
    <ModalBasic
      style={{ width: modalWidth }}
      open={open}
      closeModal={close}
      // eslint-disable-next-line react/no-children-prop
      children={<Input />}
    >
      <>
        <ModalHeader>
          <Title fontSize="16px" fontWeight="500" color={colors.black}>
            {title}
          </Title>
          <CloseModalImg src={CloseModalIcon} onClick={close} />
        </ModalHeader>

        <ModalBody>{children}</ModalBody>
        <ModalFooter
          width="100%"
          display="flex"
          alignitems="center"
          justifycontent={'flex-end'}
        >
          <Button
            onClick={close}
            cancel
            mr="20px"
            style={{ marginTop: '25px' }}
            className={'cancel'}
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={handleSubmit}
            primary
            style={{ marginTop: '25px' }}
            isLoading={isLoading}
            w="141x"
          >
            {t('confirm')}
          </Button>
        </ModalFooter>
      </>
    </ModalBasic>
  );
};

ConfirmVersioning.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalWidth: PropTypes.string.isRequired
};

export default ConfirmVersioning;
