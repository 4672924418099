import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '../../../../common/box/Box';
import Text from '../../../../common/text/Text';
import { RadioButton } from '../../../../common/radio-button';

const VersioningSwitchContainer = ({
  checked,
  onChange,
  description,
  title,
  id,
  value,
  currentDatabaseMode,
  disabled
}) => {
  const { t } = useTranslation();
  const suspendedColor = '#E6E7E8';
  const enabledColor = '#CEFFDD';
  const textColorSuspendedChecked = '#4A5158';
  const textColorEnabledChecked = '#073A16';
  const textColorEnabled = '#6C757D';
  const textColorTag = '#073A16';
  return (
    <Box>
      <Box
        display="flex"
        alignitems="center"
        justifycontent="flex-start"
        gap="10px"
        mt="35px"
        mb="10px"
        fontSize="5px"
      >
        <RadioButton
          disabled={disabled}
          id={id}
          checked={checked}
          onChange={onChange}
        />
        <label style={{ display: 'flex', alignItems: 'center' }} htmlFor={id}>
          <Text
            color={
              checked && value === 'Enabled'
                ? textColorEnabledChecked
                : checked && (value === 'Suspended' || value === null)
                ? textColorSuspendedChecked
                : textColorEnabled
            }
            background={
              checked && (value === 'Suspended' || null)
                ? suspendedColor
                : checked && value === 'Enabled'
                ? enabledColor
                : ''
            }
            fontSize="14px"
            fontWeight="500"
            p="3px 7px"
            borderradius="4px"
          >
            {title}
          </Text>
          {currentDatabaseMode && (
            <Text
              background={enabledColor}
              fontSize="14px"
              color={textColorTag}
              fontWeight="500"
              p="3px 7px"
              borderradius="4px"
            >
              {t('enabled')}
            </Text>
          )}
        </label>
      </Box>
      <Text fontSize="14px" maxwidth="420px" lineheight="18px" ml="34px">
        {description}
      </Text>
    </Box>
  );
};

VersioningSwitchContainer.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  currentDatabaseMode: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default VersioningSwitchContainer;
