import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Box from '../../../../common/box/Box';
import Button from '../../../../common/button/Button';
import { colors } from '../../../../constant';
import Input from '../../../../common/input/Input';

import {
  BoldDescription,
  ConfirmAndDescriptionContainer,
  ConfirmDescription,
  UnderlineDescription
} from './helpers';

const ConfirmResetKeys = ({ formik }) => {
  const { t } = useTranslation();
  useEffect(() => {
    formik.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfirmAndDescriptionContainer>
      <ConfirmDescription>
        {t('confirmDescriptionPart1')}{' '}
        <UnderlineDescription>{t('deletedLower')}</UnderlineDescription>{' '}
        {t('confirmDescriptionPart2')}
        <br />
      </ConfirmDescription>
      <ConfirmDescription>
        {t('confirmDescriptionPart3')}{' '}
        <BoldDescription>&apos;{t('confirmUpper')}&apos;</BoldDescription>{' '}
        {t('confirmDescriptionPart4')}
      </ConfirmDescription>
      <Box display={'flex'} alignitems={'center'} gap={'21px'}>
        <Input
          marginBottom="0"
          width="100%"
          fontSize="14px"
          name={'confirm'}
          formData={formik}
          value={formik?.values['confirm']}
          onChange={formik.handleChange}
          margin={'0'}
        />
        <Button
          fontWeight={500}
          onClick={formik?.handleSubmit}
          disabled={!formik.isValid}
          className={'delete'}
          color={colors.white}
          bordercolor="transparent"
          bgcolor={colors.red}
          primary
          type={'submit'}
          isLoading={formik?.isSubmitting}
          w="112px"
        >
          {t('confirm')}
        </Button>
      </Box>
    </ConfirmAndDescriptionContainer>
  );
};

ConfirmResetKeys.propTypes = {
  formik: PropTypes.object.isRequired
};

export default ConfirmResetKeys;
