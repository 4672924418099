import React, { useState, useEffect } from 'react';
import { createAccessKey } from '../endpoints';
import { showToast } from '../../../hooks/show-toast';
import KeysModal from './components/KeysModal';

export default function CreateAccessKey({ open, close, onSubmit }) {
  const [response, setResponse] = useState('');
  const [displayLoader, setDisplayLoader] = useState(false);
  const [accessKey, setAccessKey] = useState('');
  const [secretKey, setSecretKey] = useState('');

  useEffect(() => {
    if (open && accessKey.length === 0 && secretKey.length === 0) {
      setDisplayLoader(true);

      createAccessKey().then(([res]) => {
        setDisplayLoader(false);

        if (res.success) {
          setResponse(res.data);
          onSubmit();
        }
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
      });
    }

    if (!open) {
      setAccessKey('');
      setSecretKey('');
      setResponse(null);
    }
  }, [open]);

  useEffect(() => {
    if (response && accessKey.length === 0 && secretKey.length === 0) {
      setAccessKey(response.AccessKeyId);
      setSecretKey(response.SecretAccessKey);
    }
  }, [response, accessKey, secretKey]);

  return (
    <>
      <KeysModal
        open={open}
        close={close}
        secretKey={secretKey}
        accessKey={accessKey}
        displayLoader={displayLoader}
      />
    </>
  );
}
