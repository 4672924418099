import { action } from 'easy-peasy';

const DEFAULT_STATE = {
  accessKeysNotification: {
    display: false,
    success: false,
    message: ''
  }
};

const attributes = {
  defaultState: DEFAULT_STATE
};

const actions = {
  setAccessKeysNotification: action((state, notificationData) => {
    state.accessKeysNotification = notificationData;
  }),
  resetAccessKeysNotification: action(state => {
    state.accessKeysNotification = {
      display: false,
      success: false,
      message: ''
    };
  })
};

const accessKeys = {
  ...attributes,
  ...actions
};

export default accessKeys;
