import React from 'react';
import { useStoreState } from 'easy-peasy';

import Button from '../../../../../common/button/Button';
import { NoOrganizationDivContainer, NoOrganizationText } from '../styles';
import { OrganizationIcon } from '../../../../../../assets/images/Organization.jsx';
import hasPermission from '../../../../../../security/Permission';
import { useTranslation } from 'react-i18next';

const NoOrganizationContainer = ({ onClick }) => {
  const { t } = useTranslation();
  const editSSOPerm = hasPermission('oem_sso', 'connection');
  const { theme } = useStoreState(actions => actions.theme);

  return (
    <NoOrganizationDivContainer mt="0">
      <OrganizationIcon fill={theme.themes?.colorPrimary} />
      <NoOrganizationText fontSize="16px" fontWeight={500}>
        {t('addSsoToAccountTitle')}
      </NoOrganizationText>
      <NoOrganizationText>{t('addSsoToAccountDescription')}</NoOrganizationText>
      <Button
        disabled={!editSSOPerm}
        m="10px 0 0 0"
        primary
        minHeight="40px"
        onClick={editSSOPerm ? onClick : null}
      >
        {t('startSsoConfiguration')}
      </Button>
    </NoOrganizationDivContainer>
  );
};

export default NoOrganizationContainer;
