import React from 'react';
import ConfigContainer from './ConfigContainer';
import { RadioGroup } from '@mui/material';
import {
  ChannelLabel,
  SSOConfigDescriptions,
  SamlConfigInputContainer
} from '../styles';
import { colors } from '../../../../../constant';
import FormInput from '../../../../../common/form-input';
import { RadioButton } from '../../../../../common/radio-button';
import Box from '../../../../../common/box/Box';
import hasPermission from '../../../../../../security/Permission';
import { useTranslation } from 'react-i18next';

const OpenIdConfig = ({ formik }) => {
  const { t } = useTranslation();
  const editSSOPerm = hasPermission('oem_sso', 'connection');
  const ssoDomain = process.env.REACT_APP_SSO_DOMAIN;
  const errorOnDiscoveryEndpoint =
    formik?.errors?.discoveryEndpoint && formik?.touched?.discoveryEndpoint;
  const errorOnClientId = formik?.errors?.clientId && formik?.touched?.clientId;
  const errorOnClientSecret =
    formik?.errors?.clientSecret && formik?.touched?.clientSecret;

  return (
    <ConfigContainer title={t('openIdConnectConnectionTitle')}>
      <SamlConfigInputContainer borderColor={colors.gray}>
        <FormInput
          extraClassName="white-forminput"
          name="discoveryEndpoint"
          label={t('discoveryEndpointLabel')}
          inputMarginBottom={errorOnDiscoveryEndpoint ? '20px' : '10px'}
          isRequired
          formik={formik}
          errorTop="calc(100% - 16px)"
          disabled={!editSSOPerm}
          placeholder={t('discoveryEndpointPlaceholder')}
        />
        <SSOConfigDescriptions marginBottom="20px">
          {t('discoveryEndpointDescription')}
        </SSOConfigDescriptions>
        <FormInput
          extraClassName="white-forminput"
          name="clientId"
          label={t('clientIdLabel')}
          inputMarginBottom={errorOnClientId ? '20px' : '10px'}
          isRequired
          formik={formik}
          errorTop="calc(100% - 16px)"
          disabled={!editSSOPerm}
        />
        <SSOConfigDescriptions marginBottom="10px">
          {t('clientIdDescription')}
        </SSOConfigDescriptions>
      </SamlConfigInputContainer>
      <SamlConfigInputContainer borderColor="">
        <SSOConfigDescriptions
          fontSize="14px"
          fontWeight={500}
          marginBottom="5px"
        >
          {t('callbackUrlLabel')}
        </SSOConfigDescriptions>
        <SSOConfigDescriptions marginBottom="10px">
          {t('callbackUrlDescription')}
        </SSOConfigDescriptions>
        <a
          href={`${ssoDomain}/login/callback`}
          target="_blank"
          rel="noreferrer"
        >
          <SSOConfigDescriptions fontWeight={500} marginBottom="20px">
            {`${ssoDomain}/login/callback`}
          </SSOConfigDescriptions>
        </a>
      </SamlConfigInputContainer>
      <RadioGroup
        onChange={e => {
          if (editSSOPerm) {
            formik.setFieldValue('channelType', e.target.value);
          }
        }}
      >
        <Box
          cursor={!editSSOPerm ? 'not-allowed' : 'pointer'}
          maxwidth="fit-content"
          display="flex"
          alignitems="center"
          gap="5px"
          mb="10px"
        >
          <RadioButton
            pathColor={'s'}
            background="white"
            id="front_channel"
            value="front_channel"
            checked={formik.values.channelType === 'front_channel'}
            disabled={!editSSOPerm}
          />
          <ChannelLabel
            disabled={!editSSOPerm}
            htmlFor="front_channel"
            fontWeight={500}
          >
            {t('frontChannelLabel')}
          </ChannelLabel>
        </Box>
        <SSOConfigDescriptions marginBottom="20px">
          {t('frontChannelDescription')}
        </SSOConfigDescriptions>
        <Box
          cursor={!editSSOPerm ? 'not-allowed' : 'pointer'}
          maxwidth="fit-content"
          display="flex"
          alignitems="center"
          gap="5px"
          mb="10px"
        >
          <RadioButton
            pathColor="s"
            background="white"
            id="back_channel"
            value="back_channel"
            checked={formik.values.channelType === 'back_channel'}
            disabled={!editSSOPerm}
          />
          <ChannelLabel
            disabled={!editSSOPerm}
            htmlFor="back_channel"
            fontWeight={500}
          >
            {t('backChannelLabel')}
          </ChannelLabel>
        </Box>
      </RadioGroup>
      <SSOConfigDescriptions marginBottom="20px">
        {t('backChannelDescription')}
      </SSOConfigDescriptions>
      <FormInput
        disabled={formik.values.channelType === 'front_channel' || !editSSOPerm}
        extraClassName="white-forminput"
        name="clientSecret"
        label={t('clientSecretLabel')}
        inputMarginBottom={errorOnClientSecret ? '20px' : '10px'}
        isRequired={formik.values.channelType === 'back_channel'}
        formik={formik}
        errorTop="calc(100% - 16px)"
      />
    </ConfigContainer>
  );
};

export default OpenIdConfig;
