import { action } from 'easy-peasy';

const attributes = {
  theme: {}
};

const actions = {
  setTheme: action((state, theme) => {
    state.theme = theme;
  })
};

const theme = {
  ...attributes,
  ...actions
};

export default theme;
