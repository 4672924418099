import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  CloseModalImg,
  ModalBasic,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../../common/modal/Modal';
import Input from '../../common/input/Input';
import Button from '../../common/button/Button';
import CloseModalIcon from '../../../assets/icons/x.svg';
import Title from '../../common/title/Title';
import Box from '../../common/box/Box';
import Icon from '../../common/icon/Icon';
import { showToast } from '../../../hooks/show-toast';

export function CreateMemberNew({ open, close, data }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [url, setUrl] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (open && data) {
      setUrl(data.url);
      setEmail(data.email);
      setPassword(data.password);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleCopyKeys = async e => {
    setIsLoading(true);
    e.preventDefault();
    await navigator.clipboard.writeText(
      t('urlLabel')
        .concat(' = ')
        .concat(data?.url)
        .concat('\r\n')
        .concat(t('email'))
        .concat(' = ')
        .concat(data?.email)
        .concat('\r\n')
        .concat(t('password'))
        .concat(' = ')
        .concat(data?.password)
        .concat('\r\n')
    );
    showToast(
      {
        message: t('loginInfoCopiedToClipboard'),
        success: true,
        customMessage: true
      },
      'info'
    );
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ModalBasic
      style={{ width: '550px' }}
      open={open}
      closeModal={close}
      // eslint-disable-next-line react/no-children-prop
      children={<Input />}
    >
      <div>
        <ModalHeader>
          <Title fontSize="16px" fontWeight="500">
            {t('newMemberCreated')}
          </Title>
          <CloseModalImg src={CloseModalIcon} onClick={close} />
        </ModalHeader>

        <ModalBody>
          <form>
            <div className="input-wrapper">
              <Box
                mb="30px"
                p="0 10px"
                borderradius="4px"
                center
                w="100%"
                start="true"
                border="1px solid #f1f1f1"
                width="100%"
                fontSize="14px"
                minheightsize="40px"
              >
                <LinkMember href={url} target="_blank">
                  {url}
                </LinkMember>
              </Box>

              <label
                htmlFor="url"
                className={url.length > 0 ? 'filledField' : ''}
              >
                {t('urlLabel')}
              </label>
            </div>

            <div className="input-wrapper">
              <Input
                size="0"
                width="100%"
                fontSize="14px"
                name="email"
                type="email"
                value={email}
                className="create-member-new"
                readOnly
              />
              <label
                htmlFor="email"
                className={email.length > 0 ? 'filledField' : ''}
              >
                {t('emailLabel')}*
              </label>
            </div>

            <div className="input-wrapper">
              <Input
                size="0"
                width="100%"
                fontSize="14px"
                name="password"
                type={!showPassword ? 'password' : 'text'}
                value={password}
                className="create-member-new"
                readOnly
              />
              <label
                htmlFor="password"
                className={password.length > 0 ? 'filledField' : ''}
              >
                {t('passwordLabel')}*
              </label>

              {!showPassword ? (
                <EyePassword
                  icon="close_eye"
                  width="16px"
                  height="16px"
                  onClick={togglePassword}
                ></EyePassword>
              ) : (
                <EyePassword
                  icon="view"
                  width="16px"
                  height="16px"
                  onClick={togglePassword}
                ></EyePassword>
              )}
            </div>
          </form>
        </ModalBody>

        <ModalFooter display="flex" justifycontent="flex-end">
          <Button
            isLoading={isLoading}
            onClick={handleCopyKeys}
            primary
            w="239px"
          >
            {t('copyLoginInformation')}
          </Button>
        </ModalFooter>
      </div>
    </ModalBasic>
  );
}

const LinkMember = styled.a`
  font-size: 14px;
  font-weight: 400;
  color: #466fb6 !important;
  &:hover {
    color: #466fb6 !important;
  }
`;

const EyePassword = styled(Icon)`
  position: absolute;
  right: 15px;
  top: 14px;
  cursor: pointer;
  z-index: 9;
`;

CreateMemberNew.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};
