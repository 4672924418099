import React from 'react';
import { useStoreActions } from 'easy-peasy';
import styled from 'styled-components';

import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';

import { colors } from '../../constant';

export function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const muiButton = document.querySelectorAll('.MuiPagination-ul li button');
  const { setConToken } = useStoreActions(actions => actions.bucketDetails);
  muiButton.forEach(btn => {
    btn.addEventListener('click', function() {
      window.scrollTo(0, 0);
    });
  });

  return (
    <CustomPaginator
      count={pageCount}
      page={page + 1}
      showFirstButton={true}
      showLastButton={true}
      variant="outlined"
      shape="rounded"
      hidden={pageCount === 0}
      onChange={(event, value) => {
        if (value === 1) {
          setConToken('');
        }
        apiRef.current.setPage(value - 1);
      }}
      sx={{
        '.MuiButtonBase-root': {
          margin: 0,
          border: 0,
          borderRight: 0,
          borderRadius: 0
        },

        '.MuiPaginationItem-firstLast': {
          display: 'none'
        },

        '.MuiPagination-ul': {
          li: {
            border: '1px solid #F1F1F1',
            borderLeft: 1,
            borderRight: 0,
            borderColor: '#F1F1F1',
            borderRadius: '0',

            '&:first-of-type': {
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
              borderLeft: 1,
              borderColor: '#F1F1F1'
            },

            '&:last-child': {
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4
            },

            '&:nth-last-of-type(2)': {
              borderRight: 1,
              borderColor: '#F1F1F1'
            },

            button: {
              minWidth: '40px',
              height: '40px',
              color: '#788089',

              svg: {
                width: '.8em',
                height: '.8em'
              }
            }
          }
        },

        '.MuiPaginationItem-ellipsis': {
          height: '40px',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          paddingBottom: '7px'
        }
      }}
    />
  );
}

const CustomPaginator = styled(Pagination)`
  .Mui-selected {
    background-color: ${({ theme }) => theme.themes?.colorPrimary} !important;
    color: ${colors.white} !important;
  }
`;
