/* eslint-disable no-restricted-globals */
import { action, thunk } from 'easy-peasy';
import jwt from 'jwt-decode';

import i18n from '../../i18n';
import { returnLanguage } from '../../components/auth/login/helpers';

const DEFAULT_STATE = {
  confirmed: false
};

const attributes = {
  user: DEFAULT_STATE,
  authLoader: false,
  resetLoader: false
};

const actions = {
  logout: thunk(({ setUser }, data, { getStoreActions, getState }) => {
    const state = getState();
    const actions = getStoreActions();
    const { setUserData } = actions.user;
    const { setTheme } = actions.theme;
    setUserData(DEFAULT_STATE);
    setUser(DEFAULT_STATE);
    state.authLoader = false;
    setTheme({});
    window.dispatchEvent(new Event('logout'));
  }),

  processSignIn: thunk(({ setUser }, data, { getStoreActions }) => {
    const actions = getStoreActions();
    if (data !== undefined) {
      localStorage.setItem('Authorization', data.AccessToken);
      localStorage.setItem('RefreshToken', data.RefreshToken);
      const decodedToken = jwt(data.AccessToken);
      const { setUserData } = actions.user;
      setUserData(decodedToken.user);
      setUser(decodedToken.user);
      if (data.Language && !decodedToken?.user?.IsFirstLogin) {
        i18n.changeLanguage(data.Language);
        returnLanguage(data.Language);
      }

      return data;
    }
  }),

  setUser: action((state, user) => {
    state.user = { ...user };
  }),

  confirmUser: action(state => {
    state.user.confirmed = true;
  }),

  resetUserValues: thunk(({ setUser }, data, { getStoreActions }) => {
    const actions = getStoreActions();
    const { setUserData } = actions.user;
    setUserData(DEFAULT_STATE);
    setUser(DEFAULT_STATE);
  }),

  setNewUser: thunk(
    (state, data, { getStoreActions, getState, getStoreState }) => {
      const actions = getStoreActions();
      const { setFirstLastName } = actions.user;
      setFirstLastName(data);
      return data;
    }
  ),

  setAuthLoader: action((state, payload) => {
    state.authLoader = payload;
  }),

  setResetLoader: action((state, payload) => {
    state.resetLoader = payload;
  })
};

const authentication = {
  ...attributes,
  ...actions
};

export default authentication;
