import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {
  alignitems,
  bgcolor,
  borderradius,
  color,
  direction,
  disablePointerEvents,
  gridcolumn,
  grow,
  justifycontent,
  justifySelf,
  pointer,
  relative,
  shrink,
  textalign,
  wrap,
  hoverUnderline,
  gap,
  display,
  p,
  mr,
  mt,
  mb,
  w,
  minwidth,
  maxwidth,
  heightsize,
  minheightsize,
  maxheightsize,
  overflowy,
  m,
  lineheight,
  columngap,
  boxshadow,
  visibility,
  flex,
  ml,
  boxsizing,
  cursor
} from '../../properties';

const Box = styled(
  React.forwardRef(({ children, className, ...props }, ref) => (
    <div className={className} ref={ref} {...props}>
      {children}
    </div>
  ))
)`
  flex-wrap: nowrap;
  position: static;
  color: inherit;
  ${gridcolumn}
  ${pointer}
  ${alignitems}
  ${display}
  ${justifycontent}
  ${justifySelf}
  ${wrap}
  ${shrink}
  ${direction}
  ${relative}
  ${borderradius}
  ${bgcolor}
  ${grow}
  ${gap}
  ${columngap}
  ${p}
  ${m}
  ${ml}
  ${mr}
  ${mt}
  ${mb}
  ${w}
  ${minwidth}
  ${maxwidth}
  ${heightsize}
  ${minheightsize}
  ${maxheightsize}
  ${overflowy}
  ${color}
  ${textalign}
  ${disablePointerEvents}
  ${hoverUnderline}
  ${lineheight}
  ${boxshadow}
  ${visibility}
  ${flex}
  ${boxsizing}
  ${cursor}
  
  ${({ center }) =>
    center && 'display: flex; justify-content: center; align-items: center;'}
  ${({ end }) =>
    end && 'display: flex; justify-content: flex-end; align-items: center;'}
  ${({ start }) =>
    start && 'display: flex; justify-content: flex-start; align-items: center;'}
  ${({ border }) => border && 'border: 1px solid #f1f1f1'}
  ${({ borderTop }) =>
    borderTop && 'border-top: 1px solid #f1f1f1; padding-top: 15px;'}
  ${({ overflow }) => overflow && `overflow: ${overflow};`}
`;

Box.propTypes = {
  spacing: propTypes.string,
  /** Width for the component in % */
  width: propTypes.oneOfType([propTypes.number, propTypes.string]),
  /** Width for the component on md (medium) breakpoint and UP in % */
  mdWidth: propTypes.oneOfType([propTypes.number, propTypes.string]),
  /** Width for the component on lg (large) breakpoint and UP in % */
  lgWidth: propTypes.oneOfType([propTypes.number, propTypes.string]),
  /** Width for the component in % */
  height: propTypes.oneOfType([propTypes.number, propTypes.string]),
  /** Width for the component on md (medium) breakpoint and UP in % */
  mdHeight: propTypes.oneOfType([propTypes.number, propTypes.string]),
  /** Width for the component on lg (large) breakpoint and UP in % */
  lgHeight: propTypes.oneOfType([propTypes.number, propTypes.string]),
  /** Set value of CSS align-items property */
  alignItems: propTypes.string,
  /** Set value of CSS justify-content property */
  justifyContent: propTypes.string,
  /** Set HEX code for background color (use colors from constants.js) */
  bgcolor: propTypes.string,
  /** Set flex-wrap or not */
  wrap: propTypes.bool,
  /** Value for flex-shrink */
  shrink: propTypes.number,
  /** Value for flex-direction */
  direction: propTypes.string,
  /** Set text-align or not  */
  textalign: propTypes.string
};

Box.defaultProps = {};

export default Box;
