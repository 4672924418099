import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useStoreActions } from 'easy-peasy';
import { Slide, toast, ToastContainer } from 'react-toastify';

export default function NotificationContainer() {
  const { setCustomToastId } = useStoreActions(actions => actions.fileUpload);

  useEffect(() => {
    const unlisten = toast.onChange(payload => {
      if (payload.status === 'removed') {
        setCustomToastId(`${Math.random()}`);
      }
    });

    return () => {
      unlisten();
    };
  }, [setCustomToastId]);

  return (
    <StyledNotificationContainer
      draggable={false}
      newestOnTop
      hideProgressBar={true}
      pauseOnHover={false}
      pauseOnFocusLoss={false}
      autoClose={5000}
      position="top-center"
      transition={Slide}
    />
  );
}

const StyledNotificationContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    margin-top: 43px;
    width: fit-content;
  }
  .Toastify__toast {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0999235);
    border-radius: 4px;
    padding: 20px;
  }
  .Toastify__toast-body {
    font-family: Roboto, sans-serif !important;
    min-width: 360px;
    align-items: flex-start;
    padding: 0px;
  }
  .Toastify__toast-icon {
    margin-top: 3px;
  }
  .Toastify__toast-body.Toastify__toast--exit {
    opacity: 0;
    transition: opacity 0ms !important;
  }
  .Toastify__progress-bar {
  }
  .Toastify__slide-exit--top-center {
    opacity: 0;
    transition: opacity 0ms !important;
    visibility: hidden !important;
  }
`;
