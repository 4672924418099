import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Box from '../../common/box/Box';
import Moment from 'moment/moment';
import Text from '../../common/text/Text';
import i18n from '../../../i18n';

export const SpanFlex = styled.span`
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const BorderBottom = styled.div`
  border-bottom: 1px solid #f1f1f1;
  margin: 20px 0;
`;
export const KeysActions = styled.div`
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0999235);
  border-radius: 4px;
  margin-top: 1px;
  padding-bottom: 5px;
`;

export const PrimaryIndicator = styled.div`
  width: fit-content;
  padding: 0 7px;
  height: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #4a5158;
  background: #e6e7e8;
  border-radius: 4px;
  display: grid;
  place-items: center;
`;

export const NameAccessKey = styled(Text)``;

export const HeaderDisplay = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 13.9965px;
  line-height: 16px;
  color: #525e6b;
`;
export const RenderUsername = ({ row }) => {
  const { Username } = row;

  return (
    <Box>
      <NameAccessKey>{Username ?? i18n.t('rootAccessKey')}</NameAccessKey>
    </Box>
  );
};

export const columns = [
  {
    sortable: false,
    field: 'UserName',
    headerName: i18n.t('name'),
    fontWeight: 700,
    flex: 1,
    renderHeader: () => <HeaderDisplay>{i18n.t('name')}</HeaderDisplay>,
    renderCell: RenderUsername
  },
  {
    sortable: false,
    field: 'AccessKeyId',
    headerName: i18n.t('key'),
    flex: 2,
    renderHeader: () => <HeaderDisplay>{i18n.t('key')}</HeaderDisplay>
  },
  {
    sortable: false,
    field: 'CreateDate',
    headerName: i18n.t('createdOn'),
    flex: 1,
    type: 'date',
    renderHeader: () => <HeaderDisplay>{i18n.t('createdOn')}</HeaderDisplay>,
    valueGetter: params =>
      params.row?.CreateDate
        ? Moment(params.row?.CreateDate).format('DD-MMM-YYYY')
        : ''
  },
  {
    sortable: false,
    field: ' ',
    headerName: i18n.t(''),
    renderCell: row =>
      row.row.Disabled ? (
        <PrimaryIndicator>{i18n.t('primary')}</PrimaryIndicator>
      ) : (
        <></>
      )
  }
];

RenderUsername.propTypes = {
  row: PropTypes.object.isRequired
};
