import React from 'react';

const ArrowDown = ({ fill = '#323A46', width = '13', extraStyle = {} }) => {
  return (
    <svg
      style={extraStyle}
      width={width}
      height={width}
      viewBox="0 0 7 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.17969 0.753906L7 1.57422L3.5 5.07422L0 1.57422L0.820312 0.753906L3.5 3.43359L6.17969 0.753906Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowDown;
