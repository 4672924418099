import i18n from '../../../i18n';
export const translateFilterName = name => {
  switch (name) {
    case 'Region':
      return i18n.t('region');
    case 'region':
      return i18n.t('region');
    case 'status':
      return i18n.t('status');
    case 'Active':
      return i18n.t('active');
    case 'Deleted':
      return i18n.t('deleted');
    case 'Deactive':
      return i18n.t('deactive');
    default:
      return name;
  }
};
