export const ENDPOINTS = {
  GET_USAGES: 'usage/read?range=:range:&size=:size:&page=:page:',
  GET_USAGE_DATES: 'usage/read/last',
  GET_USAGE_EXPORT: 'usage/export/:type?',
  GET_USAGE_DATA_CARDS:
    'usage/data-cards/latest?startTime=:startTime:&endTime=:endTime:',
  GET_USAGE_DATA_CARDS_DETAILS: 'usage/data-cards?endTime=:endTime:',

  // USAGE DETAILS //
  GET_USAGES_DETAILS: 'usage_details/read?page=:page:&size=:size:',
  GET_USAGES_DETAILS_EXPORT: filters =>
    `usage_details/export/:type${filters.length > 0 ? filters : ''}`,
  USAGES_DETAILS_FILTERS: filters =>
    `usage_details/read?page=:page:&size=:size:${
      filters.length > 0 ? '&' : ''
    }${filters}`,
  FILTER_WITH: 'usage_details/filters?filter_with=',

  //USAGE BUCKET DETAILS//
  GET_USAGES_BUCKET:
    'bucket_details/read/:bucketNumber?size=:size:&page=:page:',
  GET_USAGE_DATES_BUCKET: bucketNumber =>
    `bucket_details/read/last/${bucketNumber}`,
  GET_BUCKET_STATUS: bucketNumber => `bucket_details/status/${bucketNumber}`,
  GET_USAGE_DATA_CARDS_BUCKET:
    'bucket_details/data-cards/latest/:bucketNumber?startTime=:startTime:&endTime=:endTime:',
  GET_USAGES_DETAILS_EXPORT_BUCKET: (bucketNumber, type) =>
    `bucket_details/export/${bucketNumber}/${type}?`
};
