import React from 'react';

const SSOIcon = ({ fill = '#00AC39', width = '25px' }) => {
  return (
    <svg width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path
        fill={fill}
        d="M 29 8.53125 C 23.785 8.53125 19.371 11.6525 17.25 16.0625 C 16.121 15.2855 14.81175 14.71875 13.34375 14.71875 C 9.61675 14.71875 6.6445 17.66775 6.4375 21.34375 C 2.7105 22.68875 5.9211895e-16 26.16375 0 30.34375 C 0 35.67175 4.32825 40 9.65625 40 L 41.09375 40 C 45.99975 40 50 35.99975 50 31.09375 C 50 26.54275 46.50775 22.92625 42.09375 22.40625 C 42.10975 22.16425 42.15625 21.9375 42.15625 21.6875 C 42.15625 14.4385 36.25 8.53125 29 8.53125 z M 29 10.53125 C 35.172 10.53125 40.15625 15.5195 40.15625 21.6875 C 40.15625 22.1445 40.1215 22.5935 40.0625 23.0625 L 39.90625 24.1875 L 41.09375 24.1875 C 44.91775 24.1875 48 27.26975 48 31.09375 C 48 34.91775 44.91775 38 41.09375 38 L 9.65625 38 C 5.40625 38 2 34.58975 2 30.34375 C 2 26.80875 4.383 23.8315 7.625 22.9375 L 8.375 22.75 L 8.34375 21.9375 C 8.33975 21.7965 8.34375 21.72675 8.34375 21.71875 C 8.34375 18.94175 10.56675 16.71875 13.34375 16.71875 C 14.73475 16.71875 16.00025 17.2775 16.90625 18.1875 L 18 19.3125 L 18.53125 17.84375 C 20.10525 13.59475 24.191 10.53125 29 10.53125 z M 30.546875 16.208984 L 24.363281 22.390625 C 23.761068 22.205581 23.162304 22 22.5 22 C 18.921732 22 16 24.921732 16 28.5 C 16 32.078268 18.921732 35 22.5 35 C 26.078268 35 29 32.078268 29 28.5 C 29 27.827083 28.788831 27.219858 28.597656 26.609375 L 29.193359 26.015625 L 30.732422 26.015625 L 30.732422 24.517578 L 32.230469 24.517578 L 32.230469 23.005859 L 33.765625 23.005859 L 33.765625 21.46875 L 33.738281 21.46875 L 34.78125 20.425781 L 34.730469 19.949219 L 34.365234 16.53125 L 30.546875 16.208984 z M 31.302734 18.279297 L 32.552734 18.384766 L 32.691406 19.6875 L 26.330078 26.046875 L 26.611328 26.673828 C 26.86113 27.235061 27 27.849235 27 28.5 C 27 30.997732 24.997732 33 22.5 33 C 20.002268 33 18 30.997732 18 28.5 C 18 26.002268 20.002268 24 22.5 24 C 23.143174 24 23.749378 24.135753 24.304688 24.378906 L 24.929688 24.652344 L 31.302734 18.279297 z M 21.5 28 A 1.5 1.5 0 0 0 21.5 31 A 1.5 1.5 0 0 0 21.5 28 z"
      />
    </svg>
  );
};

export default SSOIcon;
