import styled from 'styled-components';

export const NotFoundContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 163, 255, 0.06);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -60px;
  gap: 20px;
`;

export const ErrorCodeText = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 162px;
  background: ${({ theme }) =>
    theme
      ? theme.themes?.colorPrimary
      : 'linear-gradient(246.37deg, #00a3ff 11.69%, #00f0ff 113.99%)'};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
export const PageNotFoundText = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  color: #1e1e1e;
`;

export const InformationText = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #1e1e1e;
  margin-top: -10px;
`;

export const BackButton = styled.button`
  width: 170px;
  height: 50px;
  background: ${({ theme }) =>
    theme ? theme.themes?.colorPrimary : '#00a3ff'};
  border-radius: 13px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
`;
