// Title For H1 //
import styled from 'styled-components';
import propTypes from 'prop-types';
import {
  color,
  fontSize,
  fontWeight,
  textalign,
  lineheight
} from '../../properties';

const Title = styled.h1`
  font-family: Roboto, sans-serif;
  margin: ${props => props.m || 0};
  cursor: ${props => props.cursor || ''};
  ${fontSize}
  ${fontWeight}
  ${textalign}
  ${color}
  ${lineheight}
  max-width: ${props => `${props.maxwidth} !important` || ''};
  word-break: ${props => `${props.wordBreak}` || ''};
`;

Title.propTypes = {
  /** Set text-align or not  */
  textalign: propTypes.string
};

Title.defaultProps = {};

export default Title;
