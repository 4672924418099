import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';

import Box from '../../common/box/Box';
import { colors } from '../../constant';
import hasPermission from '../../../security/Permission';

const BucketTabs = () => {
  const { t } = useTranslation();
  const { name } = useParams();
  const { theme } = useStoreState(actions => actions.theme);
  const canReadObjectsPerm = hasPermission('oem_objects', 'read_all');
  const canReadBucketSettingsPerm = hasPermission(
    'oem_objects',
    'read_bucket_settings'
  );

  const TabsWrapper = styled(Box)`
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.0999235);
  `;

  const TabLink = styled(NavLink)`
    color: ${colors.darkBlue} !important;
    font-size: 16px;
    &:hover {
      color: ${theme.themes?.darkBlue} !important;
    }
    &.active {
      color: ${theme.themes?.darkBlue} !important;
      &:hover {
        color: ${theme.themes?.darkBlue} !important;
      }
    }
    &:not(:last-child) {
      margin-right: 30px;
    }
  `;

  return (
    <>
      {(canReadObjectsPerm || canReadBucketSettingsPerm) && (
        <Box display="flex" p="20px 30px 30px">
          <TabsWrapper
            display="flex"
            alignitems="center"
            p="0 30px"
            bgcolor={colors.white}
            borderradius="4px"
            w="100%"
            minheightsize="60px"
          >
            {canReadObjectsPerm && (
              <TabLink
                exact="true"
                className="active-tabs"
                activeclassname="active"
                to={'/buckets/'.concat(name).concat('/objects')}
              >
                {t('objects')}
              </TabLink>
            )}
            {canReadBucketSettingsPerm && (
              <TabLink
                exact="true"
                className="active-tabs"
                activeclassname="active"
                to={'/buckets/'.concat(name).concat('/properties')}
              >
                {t('properties')}
              </TabLink>
            )}
          </TabsWrapper>
        </Box>
      )}
    </>
  );
};

export default BucketTabs;
