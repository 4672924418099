export const exportFormatList = [
  {
    displayName: 'CSV',
    value: 'csv'
  },
  {
    displayName: 'JSON',
    value: 'json'
  },
  {
    displayName: 'Excel',
    value: 'excel'
  }
];
