/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import jwt from 'jwt-decode';

import { LoaderWrap } from '../../../../common/loader/Loader';

import {
  AccessKeyRequiredContainer,
  AccessKeyRequiredModal,
  KeysDescription,
  KeysTitle
} from './helpers';

const AccessKeyRequired = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = jwt(localStorage.getItem('Authorization'));
  const [displayLoader, setDisplayLoader] = useState(true);

  useEffect(() => {
    setDisplayLoader(true);
    if (
      userData?.roles.includes('ROOT') &&
      (!userData?.user?.AccessKey?.length > 0 ||
        !userData?.user?.SecretKey?.length > 0)
    ) {
      navigate('/access-keys');
    }
    if (
      userData?.user?.AccessKey?.length > 0 &&
      userData?.user?.SecretKey?.length > 0
    ) {
      navigate('/buckets');
    }
    setDisplayLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <AccessKeyRequiredContainer>
      <AccessKeyRequiredModal>
        <KeysTitle>{t('accessKeysRequired')}</KeysTitle>
        <KeysDescription>{t('noAccessKeysWereFound')}</KeysDescription>
      </AccessKeyRequiredModal>
      <LoaderWrap display={displayLoader} />
    </AccessKeyRequiredContainer>
  );
};

export default AccessKeyRequired;
