import React from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import Box from '../../../../common/box/Box';
import Text from '../../../../common/text/Text';
import Button from '../../../../common/button/Button';

const ConfirmLegalHoldEnable = props => {
  const {
    fileName,
    setConfrimLegalHoldView,
    handleLegalToggleView,
    toggle,
    isLoading
  } = props;
  const { t } = useTranslation();

  return (
    <Box>
      <Text mb="20px" fontSize="14px">
        {t('confirmLegalHoldChange')}
        <span
          style={{ fontWeight: '500', maxWidth: 343, wordBreak: 'break-all' }}
        >
          {' '}
          {fileName}
        </span>
      </Text>
      <Box display="flex" justifycontent="center" width="100%">
        <Button
          onClick={() => {
            setConfrimLegalHoldView(false);
            toggle();
          }}
          type="button"
          className="cancel"
          mr="20px"
        >
          {t('cancel')}
        </Button>
        <Button
          w="112px"
          onClick={() => handleLegalToggleView()}
          type="submit"
          primary
          isLoading={isLoading}
        >
          {t('confirm')}
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmLegalHoldEnable;
