import React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
  BackButton,
  ErrorCodeText,
  InformationText,
  NotFoundContainer,
  PageNotFoundText
} from './style';

const PageNotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <NotFoundContainer>
      <ErrorCodeText>404</ErrorCodeText>
      <PageNotFoundText>{t('pageNotFound')}</PageNotFoundText>
      <InformationText>{t('theLinkMayBeBroken')}</InformationText>
      <BackButton onClick={() => navigate('/buckets')}>
        {t('goBack')}
      </BackButton>
    </NotFoundContainer>
  );
};

export default PageNotFound;
