import * as Yup from 'yup';
import styled from 'styled-components';
import i18n from '../../../../../i18n';

export const resetInitialValues = {
  confirm: ''
};

const confirmValidation = Yup.string().oneOf([i18n.t('confirmUpper')]);

export const resetValidationSchema = Yup.object({
  confirm: confirmValidation.required()
});

export const ResetKeysHeader = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #0b293a;
  padding-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
