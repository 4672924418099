/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { wait } from '@testing-library/user-event/dist/utils';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Box from '../../common/box/Box';
import { colors } from '../../constant';
import { showUploadToast } from '../../../hooks/show-toast';
import { LanguageSelector } from '../../common/language-selector';

import { ResponsiveWrapper } from './styles';
import LoginHeader from './components/Header';
import LoginForm from './components/LoginForm';

export function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useStoreState(state => state.user);
  const { theme } = useStoreState(state => state.theme);

  useEffect(() => {
    if (userData && userData.Id > 0) {
      navigate('/buckets');
    }
  }, []);

  useEffect(() => {
    if (
      !localStorage.getItem('ssoDeletedOrg') &&
      !localStorage.getItem('userIsSso') &&
      (localStorage.getItem('accountDeActive') ||
        localStorage.getItem('invalidToken') ||
        localStorage.getItem('refreshTokenExpired'))
    ) {
      toast.dismiss();
      showUploadToast(
        {
          message: localStorage.getItem('invalidToken')
            ? t('unauthorized')
            : localStorage.getItem('accountDeActive')
            ? t('youHaveBeenLoggedOut')
            : t('youHaveBeenLoggedOutDueToInactivity'),
          success: false
        },
        'error'
      );
      wait(3000).then(() => {
        localStorage.removeItem('accountDeActive');
        localStorage.removeItem('invalidToken');
        localStorage.removeItem('inactivity');
        localStorage.removeItem('refreshTokenExpired');
      });
    }
  }, []);

  return (
    <Box
      bgcolor={colors.lightGray}
      heightsize="100vh"
      display="flex"
      alignitems="center"
      mt="-60px"
    >
      <Helmet>
        <meta name="description" content={theme?.loginTitle} />
      </Helmet>
      <ResponsiveWrapper
        boxsizing="initial"
        bgcolor={colors.white}
        borderradius="4px"
        maxwidth="380px"
        minwidth="380px"
        m="0 auto"
        p="30px"
        overflowy="auto"
        maxheightsize="-webkit-fill-available"
      >
        <LoginHeader theme={theme} />
        <LoginForm />
        <Box mt="30px" display="flex" justifycontent="center">
          <LanguageSelector />
        </Box>
      </ResponsiveWrapper>
    </Box>
  );
}
