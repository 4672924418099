/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { colors } from '../../constant';
import Box from '../../common/box/Box';
import Text from '../../common/text/Text';
import Title from '../../common/title/Title';
import Input from '../../common/input/Input';
import Button from '../../common/button/Button';
import CloseModalIcon from '../../../assets/icons/x.svg';

import {
  CloseModalImg,
  ModalBasic,
  ModalBody,
  ModalFooter,
  ModalHeader
} from './Modal';

export default function DeleteModal({
  name,
  text,
  deleteText,
  isLoading,
  onSubmit,
  open,
  close
}) {
  const { t } = useTranslation();
  const [writtenText, setWrittenText] = useState('');
  const [error, setError] = useState('');
  const onChange = e => {
    setWrittenText(e.target.value);
  };

  useEffect(() => {
    if (!open && writtenText.length > 0) {
      setWrittenText('');
    }
  }, [open]);

  useEffect(() => {
    if (text !== writtenText) {
      setError(t('textMismatchError'));
    } else {
      setError('');
    }
  }, [writtenText, t, text]);

  const handleSubmit = e => {
    e.preventDefault();
    if (error.length === 0) {
      onSubmit({ name: writtenText });
    }
  };

  return (
    <ModalBasic
      style={{ width: '450px' }}
      open={open}
      closeModal={close}
      // eslint-disable-next-line react/no-children-prop
      children={<Input />}
    >
      <div>
        <ModalHeader>
          <Title fontSize="16px" fontWeight="500" color="#0B293A">
            {t('delete')} {name}
          </Title>
          <CloseModalImg src={CloseModalIcon} onClick={close} />
        </ModalHeader>

        <ModalBody>
          <Text mb="20px" fontSize="14px" fontWeight="400" centeritems="true">
            <Text fontWeight="500">{t('warningUpperCase')}</Text>: {deleteText}?
          </Text>
          <Text left="true" mb="10px" fontSize="14px" fontWeight="400">
            {t('typeToProceed')} &apos;<Text fontWeight="500">{text}</Text>
            &apos; {t('toProceed')}
          </Text>
        </ModalBody>

        <ModalFooter width="100%" display="flex" alignitems="flex-start">
          <Box display="flex" direction="column" w="100%" mr="20px">
            <Input
              marginBottom="0"
              width="auto"
              borderColor="#F5F6F8"
              fontSize="14px"
              value={writtenText}
              onChange={onChange.bind(this)}
            />
          </Box>

          <Button
            w="131px"
            minwidth="131px"
            maxwidth="131px"
            isLoading={isLoading}
            onClick={handleSubmit}
            bgcolor={colors.red}
            color={colors.white}
            className={
              text.length === 0 || error.length > 0 ? 'delete-modal' : 'delete'
            }
            disabled={text.length === 0 || error.length > 0}
          >
            {t('delete')}
          </Button>
        </ModalFooter>
      </div>
    </ModalBasic>
  );
}

DeleteModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  text: PropTypes.bool.isRequired,
  deleteText: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired
};
