/* eslint-disable no-console */
/* eslint-disable no-restricted-globals */
import axios from 'axios';

import { getPermissions, refreshTokenAuth } from '../components/auth/endpoints';
import { apiPath } from '../components/constant';

axios.defaults.baseURL =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_BASE_URL.toString()
    : `${window.location.origin}${apiPath}`;

axios.interceptors.response.use(undefined, err => {
  const response = err.response?.data;

  if (
    response?.code === 'ErrCcc0014' ||
    response?.Code === 'ErrCcc0014' ||
    err.code === 'ErrCcc0014'
  ) {
    //Token expired
    return new Promise(resolve => {
      localStorage.removeItem('Authorization');
      localStorage.removeItem('firstAndLastName');

      const token = localStorage.getItem('Authorization');
      if (!token) {
        const refreshToken = localStorage.getItem('RefreshToken');
        if (refreshToken) {
          refreshTokenAuth(refreshToken).then(([res]) => {
            if (res.success && res.data) {
              localStorage.setItem('Authorization', res?.data.AccessToken);
              localStorage.setItem('RefreshToken', res?.data.RefreshToken);
              window.dispatchEvent(new Event('set_user_data'));
              getPermissions().then(([result]) => {
                localStorage.setItem(
                  'Permissions',
                  JSON.stringify(result.data)
                );
                err.config.headers.Authorization = localStorage.getItem(
                  'Authorization'
                );
                resolve(axios.request(err.config));
              });
            } else {
              localStorage.setItem('refreshTokenExpired', 'true');
              window.dispatchEvent(new Event('logout'));
            }
          });
        }
      }
    });
  }

  if (
    err.message.includes('Network Error') ||
    err.message.includes('timeout')
  ) {
    const maxRetries = 3;
    const retryDelay = 1000; // 1 second delay
    err.config.__retryCount = err.config.__retryCount || 0;
    if (err.config.__retryCount >= maxRetries) {
      return Promise.reject(err);
    }
    err.config.__retryCount += 1;
    console.log(
      'Retry count: ' + err.config.__retryCount + ' for: ' + err.config
    );
    return new Promise(resolve => setTimeout(resolve, retryDelay)).then(() =>
      axios.request(err.config)
    );
  }
  return Promise.reject(err);
});

export const requestApi = async params => {
  let response = undefined;
  let error = '';
  const loading = false;
  let redirectError;
  params.headers = {
    Authorization: localStorage.getItem('Authorization'),
    accept: '*/*'
  };

  await axios
    .request(params)
    .then(result => {
      response = result.data;
    })
    .catch(err => {
      error = err;
      response = err.response?.data;

      if (
        response?.code === 'ErrCcc0009' ||
        response?.Code === 'ErrCcc0009' ||
        err.code === 'ErrCcc0009'
      ) {
        window.dispatchEvent(new Event('buckets'));
      }

      if (
        response?.code === 'ErrCcc0054' ||
        response?.Code === 'ErrCcc0054' ||
        err.code === 'ErrCcc0054'
      ) {
        window.dispatchEvent(new Event('logout'));
      }

      if (
        response?.code === 'ErrCcc0045' ||
        response?.Code === 'ErrCcc0045' ||
        err.code === 'ErrCcc0045'
      ) {
        localStorage.setItem('accountDeActive', 'true');
        window.dispatchEvent(new Event('logout'));
      }

      if (params?.redirectError) {
        redirectError = params.redirectError;
      }
    });

  return [response, error, loading, redirectError];
};

export const requestPublicApi = async params => {
  let response = undefined;
  let error = '';
  const loading = false;

  await axios
    .request(params)
    .then(result => {
      response = result.data;
    })
    .catch(err => {
      error = err;
      response = err.response?.data;

      if (
        response?.code === 'ErrCcc0009' ||
        response?.Code === 'ErrCcc0009' ||
        err.code === 'ErrCcc0009'
      ) {
        window.dispatchEvent(new Event('buckets'));
      }
      if (
        response?.code === 'ErrCcc0045' ||
        response?.Code === 'ErrCcc0045' ||
        err.code === 'ErrCcc0045'
      ) {
        localStorage.setItem('accountDeActive', 'true');
        window.dispatchEvent(new Event('logout'));
      }

      if (
        response?.code === 'ErrCcc0011' ||
        response?.Code === 'ErrCcc0011' ||
        err.code === 'ErrCcc0011'
      ) {
        localStorage.setItem('refreshTokenExpired', 'true');
        window.dispatchEvent(new Event('logout'));
      }
    });

  return [response, error, loading];
};

export const s3Request = async params => {
  let response = undefined;
  let error = '';
  const loading = false;
  let status = undefined;

  params.baseURL = process.env.REACT_APP_S3_BASE_URL.toString();

  await axios
    .request(params)
    .then(result => {
      response = result.data;
    })
    .catch(err => {
      error = err;
      response = err.response?.data;
      status = err.response?.statuscode;
    });

  return [response, error, loading, status];
};

export const elasticServiceRequest = async params => {
  let config;
  let status = undefined;
  let response = undefined;
  let error = '';
  const loading = false;
  params.headers = {
    Authorization: localStorage.getItem('Authorization'),
    // Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50TnVtYmVyIjo4NTY0OTcsImV4cCI6MTY3OTQxNzk0Nywicm9sZXMiOlsiUk9PVCJdLCJ1c2VyIjp7IklkIjoxMTYwOCwiQWNjb3VudE51bWJlciI6ODU2NDk3LCJBY2Nlc3NLZXkiOiJmTUtOQzhyQ3ljRXJCU1ZMMEZWQm9uWEU1clU9IiwiU2VjcmV0S2V5IjoiSk9XaWVzejUzdUVtSEgwNjFCZGVvcitvaG5mOGlEY24xZzNJQjIyV3AvVWJ6TnBZdGp6YWdRPT0iLCJFbWFpbCI6ImVsYXN0aWNAdGVzdC5jb20iLCJVc2VybmFtZSI6ImVsYXN0aWNzMyIsIkZpcnN0TmFtZSI6InRlc3QiLCJMYXN0TmFtZSI6InVzZXIiLCJQcm9maWxlUGljdHVyZSI6IiIsIklzRmlyc3RMb2dpbiI6ZmFsc2UsIlJvbGVzIjpbIlJPT1QiXSwiTWZhRW5hYmxlZCI6ZmFsc2UsIk1mYVN1Y2Nlc3NmdWwiOmZhbHNlfX0.QE1axO0zfh7vWJ2cfHn3AcDRj8fJ0lgy_Tp9px27Z8g',
    accept: '*/*'
  };

  // const url = process.env.REACT_APP_BASE_URL.toString() + params.path
  const url = params.path;
  if (params.responseType) {
    config = {
      params: params.params,
      responseType: params.responseType
    };
  } else {
    // eslint-disable-next-line no-unused-vars
    config = {
      params: params.params
    };
  }

  response = await axios.get(url, params).catch(err => {
    if (
      response?.code === 'ErrCcc0045' ||
      response?.Code === 'ErrCcc0045' ||
      err.code === 'ErrCcc0045' ||
      err?.response?.data?.Code === 'ErrCcc0045'
    ) {
      localStorage.setItem('accountDeActive', 'true');
      window.dispatchEvent(new Event('logout'));
    }
    error = err;
    response = err.response?.data;
    status = err.response?.statuscode;
  });

  return [response, error, loading, status];
};
