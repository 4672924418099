import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  CloseModalImg,
  ModalBasic,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../../common/modal/Modal';
import { colors } from '../../constant';
import Box from '../../common/box/Box';
import Text from '../../common/text/Text';
import Input from '../../common/input/Input';
import Label from '../../common/label/Label';
import Title from '../../common/title/Title';
import Button from '../../common/button/Button';
import CloseModalIcon from '../../../assets/icons/x.svg';

export function DeleteBucketModal({ name, open, close, onSubmit, isLoading }) {
  const { t } = useTranslation();
  const [bucketName, setBucketName] = useState('');
  const [error, setError] = useState('');
  const onChange = e => {
    setBucketName(e.target.value);
  };

  useEffect(() => {
    if (name !== bucketName) {
      setError(t('bucketNameShouldMatchInput'));
    } else {
      setError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bucketName]);

  useEffect(() => {
    if (!open) {
      setBucketName('');
      setError('');
    }
  }, [open]);

  const handleSubmit = e => {
    e.preventDefault();
    if (error.length === 0) {
      onSubmit({ name: bucketName });
    }
  };

  return (
    <ModalBasic
      style={{ width: '730px' }}
      open={open}
      closeModal={close}
      // eslint-disable-next-line react/no-children-prop
      children={<Input />}
    >
      <div>
        <ModalHeader>
          <Title fontSize="16px" color="#0B293A" fontWeight="500">
            {t('deleteBucket')}
          </Title>
          <CloseModalImg src={CloseModalIcon} onClick={close} />
        </ModalHeader>

        <ModalBody>
          <Text mb="20px" fontSize="14px" fontWeight="400">
            {t('objectsHaveBeen')} {t('deleted')}
          </Text>
          <Text
            wordBreak="break-all"
            mb="20px"
            fontSize="14px"
            fontWeight="400"
          >
            {t('areYouSureDelete')} &apos;
            <Label color={colors.bodyColor} fontWeight="500">
              {name}
            </Label>
            &apos; {t('thisIsPotentiallyDangerous')}.
          </Text>
          <Text
            wordBreak="break-all"
            mb="10px"
            fontSize="14px"
            fontWeight="400"
          >
            {t('firstType')} &apos;
            <Label color={colors.bodyColor} fontWeight="500">
              {name}
            </Label>
            &apos; {t('toProceed')}
          </Text>
        </ModalBody>

        <ModalFooter width="100%" display="flex" alignitems="flex-start">
          <Box display="flex" direction="column" w="100%" mr="20px">
            <Input
              marginBottom="0"
              width="auto"
              borderColor="#F5F6F8"
              fontSize="14px"
              value={bucketName}
              onChange={onChange.bind(this)}
            />
          </Box>

          <Button
            w="200px"
            isLoading={isLoading}
            color={colors.white}
            bordercolor="transparent"
            bgcolor={colors.red}
            onClick={handleSubmit}
            className={
              bucketName.length === 0 || error.length > 0
                ? 'delete-modal'
                : 'delete'
            }
            disabled={bucketName.length === 0 || error.length > 0}
          >
            {t('delete')}
          </Button>
        </ModalFooter>
      </div>
    </ModalBasic>
  );
}

DeleteBucketModal.propTypes = {
  open: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};
