import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';

export const LabelInput = styled(InputLabel).attrs(({ sx, fontSize }) => ({
  sx: {
    '&.MuiInputLabel-root': {
      fontSize: fontSize || 14,
      color: '#788089'
    },
    '&.MuiInputLabel-outlined': {
      transform: 'translate(14px, 12px)'
    },
    '&.Mui-focused, &.MuiFormLabel-filled': {
      transform: 'translate(16px, -8px)',
      fontSize: 12
    }
  }
}))`
  display: ${props => (props.flex ? 'flex !important' : '')};
`;
