import React from 'react';
import AccountInformation from './components/account-information';
import SSOConfig from './components/sso-config';

const Account = () => {
  return (
    <div style={{ width: '100%' }}>
      <AccountInformation />
      <SSOConfig />
    </div>
  );
};

export default Account;
