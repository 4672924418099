import React from 'react';
import { toast } from 'react-toastify';

import { notificationConfig } from '../components/common/notification/helpers';
import NewNotification from '../components/common/notification/NewNotification';

export const showToast = async (notification, type) => {
  toast.dismiss();
  // Show the new toast and store its ID
  await new Promise(resolve => setTimeout(resolve, 20)); // Adjust the delay as needed
  toast[type](
    <NewNotification notification={notification} type={type} />,
    notificationConfig(type)
  );
};

//THIS TOAST IS USED ON UPLOAD MODAL FILES FOR A VARIOUS OF REASONS.
let isNotificationVisible = false; // Flag to track if a notification is already visible

export const showUploadToast = async (notification, type) => {
  if (isNotificationVisible) {
    return; // If a notification is already visible, exit the function
  }

  isNotificationVisible = true; // Set the flag to true to indicate that a notification is now visible
  await new Promise(resolve => setTimeout(resolve, 20)); // Adjust the delay as needed

  toast[type](
    <NewNotification notification={notification} type={type} />,
    notificationConfig(type)
  );

  // After showing the toast, wait for a few milliseconds before resetting the flag to false
  setTimeout(() => {
    isNotificationVisible = false;
  }, 20); // You can adjust the delay as needed
};
