import React from 'react';
import get from 'lodash/get';
import Input, { ErrorMessage } from '../input/Input';
import { Label } from '../form-label';
import { convertCammelCaseToTitleCase } from '../../../utils/format-string-cases';

const FormInput = ({
  filled = false,
  errorTop,
  onFocus,
  onBlur,
  touched,
  disabled = false,
  isRequired,
  extraIcon,
  formik,
  onChange,
  name,
  label,
  inputType = 'text',
  value,
  inputWidth = '100%',
  inputMarginBottom = '',
  extraClassName = '',
  placeholder = '',
  icon = null
}) => {
  const inputError = get(formik?.errors, name);
  const inputValue = value || get(formik?.values, name) || '';
  const inputTouched = touched || get(formik?.touched, name);
  const requiredField = isRequired || get(formik?.requiredFields, name);
  const convertedName = `${convertCammelCaseToTitleCase(name)}${
    requiredField ? '*' : ''
  }`;

  return (
    <div
      className={`input-wrapper ${
        disabled ? 'input-wrapper-disabled' : ''
      } ${extraClassName}`}
    >
      <Input
        size="0"
        width={inputWidth}
        fontSize="14px"
        minHeight="42px"
        name={name}
        id={name}
        type={inputType}
        formData={formik}
        value={formik ? formik?.values?.[name] : value}
        className={
          formik?.errors?.[name] && formik?.touched?.[name] ? 'not-valid' : ''
        }
        onChange={onChange || formik?.handleChange}
        onBlur={onBlur || formik?.handleBlur}
        marginBottom={inputMarginBottom}
        disabled={disabled}
        onFocus={onFocus}
        placeholder={placeholder}
      />
      <Label
        label={label || convertedName}
        htmlFor={name}
        filled={filled || inputValue?.length > 0}
        icon={extraIcon}
      />
      {icon}
      {inputError && inputTouched && (
        <ErrorMessage top={errorTop}>{inputError}</ErrorMessage>
      )}
    </div>
  );
};

export default FormInput;
