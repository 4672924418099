import moment from 'moment';
import * as Yup from 'yup';

export const initialValues = {
  selectedMode: '',
  retainDate: moment().add(1, 'day')._d
};

export const ValidationSchema = Yup.object({
  selectedMode: Yup.string()
    .label('Mode')
    .nullable()
    .optional(),
  retainDate: Yup.date()
    .nullable()
    .required()
    .typeError('Retain Date is required')
});
