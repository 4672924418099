import * as React from 'react';
import { ModalBody } from '../../../common/modal/Modal';
import Text from '../../../common/text/Text';
import { colors } from '../../../constant';
import Box from '../../../common/box/Box';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const ComponentToPrint = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <div
      ref={ref}
      className="print-container"
      style={{ margin: '0', padding: '30px' }}
    >
      <ModalBody>
        <Box display="flex" alignitems="flex-start">
          <Box border="true" w="100%" center="true" m="0 20px 0 0" flex=".6">
            <QrCodeImg src={props.qrCode} />
          </Box>

          <Box w="100%">
            <Text
              fontSize="14px"
              mb="15px"
              maxwidth="490px"
              fontWeight="400"
              color={colors.bodyColor}
              fontFamily="Arial"
            >
              {t('installAuthyOrGoogleAuthenticator')}
            </Text>

            <Text
              fontSize="14px"
              mb="15px"
              maxwidth="490px"
              fontWeight="400"
              color={colors.bodyColor}
              fontFamily="Arial"
            >
              {t('cantScanCode')}
            </Text>

            <Text
              fontSize="14px"
              fontWeight="400"
              color={colors.bodyColor}
              fontFamily="Arial"
            >
              {t('key')}: {props.secretKey}
            </Text>
          </Box>
        </Box>
      </ModalBody>
    </div>
  );
});

const QrCodeImg = styled.img``;
