import React, { useEffect } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import 'rsuite/dist/rsuite.min.css';

import Box from '../box/Box';
import { ErrorMessage } from '../error-message';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow_down.svg';

import { DatePickerStyled, Label } from './styles';

export const DatePicker = ({
  onChange,
  label,
  value,
  flexDirection = 'row',
  justifyContent = 'space-between',
  placement = 'rightStart',
  disabled = false,
  id = 'datepicker',
  formik,
  error,
  touched,
  name,
  cleanable = true,
  minDate,
  disabledDate,
  onOpen,
  onClose
}) => {
  const errors = error || get(formik?.errors, name);
  const inputTouched = touched || get(formik?.touched, name);

  useEffect(() => {
    disabledDate();
  }, [disabledDate]);

  return (
    <>
      <Box
        display={'flex'}
        alignitems={'center'}
        justifycontent={justifyContent}
        flexDirection={flexDirection}
      >
        {label?.length > 0 && <Label>{label}</Label>}
        <DatePickerStyled
          onOpen={onOpen}
          onClose={onClose}
          disabledDate={disabledDate}
          minDate={minDate}
          cleanable={cleanable}
          name={name}
          disabled={disabled}
          id={id}
          value={value}
          onChange={onChange}
          placement={placement}
          format="dd-LLL-yyyy"
          components={{
            OpenPickerIcon: <img src={ArrowDown} alt="" />
          }}
          editable={false}
        />
      </Box>
      {name && inputTouched && errors && <ErrorMessage>{errors}</ErrorMessage>}
    </>
  );
};

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  flexDirection: PropTypes.string.isRequired,
  justifyContent: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
  touched: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  cleanable: PropTypes.bool.isRequired,
  minDate: PropTypes.string.isRequired,
  disabledDate: PropTypes.string.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};
