import React from 'react';
import { Header } from './header/Header';
import { Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ProtectedRoute } from '../security/ProtectedRoute';
import { useStoreState } from 'easy-peasy';

const Layout = ({ publicRoutes = [] }) => {
  //check for authentication
  const { pathname } = useLocation();

  const { userData } = useStoreState(state => state.user);

  return (
    <>
      {!publicRoutes.includes(pathname) &&
        !['/reset-password'].includes(pathname) &&
        !pathname.includes('code') && <Header />}

      {!publicRoutes.includes(pathname) && (
        <ProtectedRoute user={userData}>
          <Outlet />
        </ProtectedRoute>
      )}

      {publicRoutes.includes(pathname) && <Outlet />}
    </>
  );
};

export default Layout;
