import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '../../common/box/Box';
import Icon from '../../common/icon/Icon';
import { colors } from '../../constant';
import { showToast } from '../../../hooks/show-toast';
import { LoaderWrap } from '../../common/loader/Loader';
import { ActionMenuItem, Table } from '../../common/table/Table';
import { getAllAccessKeys } from '../endpoints';
import hasPermission from '../../../security/Permission';

import { BorderBottom, columns } from './helpers';
import AccessKeysAndPopper from './components/AccessKeysAndPopper';
import AccessKeysHeader from './components/AccessKeysHeader';
import ActionModals from './components/ActionModals';
import { accessKeyReducer, initialState } from './reducer';

export const AccessKeys = () => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(accessKeyReducer, initialState);
  const canReadAccessKeysPerm = hasPermission('oem_access_keys', 'read');
  const canCreateAccessKeysPerm = hasPermission('oem_access_keys', 'create');
  const canDeleteAccessKeysPerm = hasPermission('oem_access_keys', 'delete');

  useEffect(() => {
    fetchAccessKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.newKeys]);

  const onDeleteClick = name => {
    dispatch({ type: 'TOGGLE_DELETE_ACCESS_KEY', payload: true });
    dispatch({ type: 'SET_ACCESS_KEY_TO_DELETE', payload: name });
  };

  const handleClick = event => {
    dispatch({
      type: 'SET_ANCHOREL',
      payload: state.anchorEl ? null : event?.currentTarget
    });
  };

  const updatedColumns = [
    ...columns,
    {
      sortable: false,
      field: t('actions'),
      type: 'actions',
      width: 50,
      cellClassName: 'right-side',
      getActions: params => [
        <ActionMenuItem
          key="deletekey"
          label={t('deleteKey')}
          onClick={
            state.response?.length < 2 ? null : () => onDeleteClick(params.id)
          }
          showInMenu={canDeleteAccessKeysPerm}
          disabled={state.response?.length < 2 || params.row?.Disabled}
          sx={{ color: colors.red }}
          icon={
            canDeleteAccessKeysPerm ? (
              <Icon height="15px" icon="deleteFilled" color={colors.red} />
            ) : (
              <></>
            )
          }
        />
      ]
    }
  ];

  const fetchAccessKeys = (noLoader = true) => {
    dispatch({ type: 'SET_DISPLAY_LOADER', payload: noLoader });

    getAllAccessKeys().then(([res]) => {
      dispatch({ type: 'SET_DISPLAY_LOADER', payload: false });
      if (res.success) {
        dispatch({ type: 'SET_RESPONSE', payload: res.data });
        dispatch({ type: 'SET_RESPONSE_LOADED', payload: true });
        handleClick();
      } else {
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: false
          },
          'error'
        );
      }
    });
  };

  return (
    <>
      {canReadAccessKeysPerm && (
        <div style={{ width: '100%' }}>
          <Box
            relative="true"
            p="30px"
            bgcolor={colors.white}
            minheightsize="400px"
            maxheightsize="400px"
          >
            <Box
              display="flex"
              alignitems="center"
              justifycontent="space-between"
            >
              <AccessKeysHeader />
              <AccessKeysAndPopper
                handleClick={handleClick}
                anchorEl={state.anchorEl}
                responseLoaded={state.responseLoaded}
                response={state.response}
                canCreateAccessKeysPerm={canCreateAccessKeysPerm}
                setResetKeysModal={e =>
                  dispatch({ type: 'SET_RESET_KEYS_MODAL', payload: e })
                }
                onCreateAccessKeyClick={() =>
                  dispatch({
                    type: 'SET_SHOW_CREATE_ACCESS_KEY',
                    payload: true
                  })
                }
              />
            </Box>
            <BorderBottom />
            {state.response && (
              <div style={{ height: '400px', width: '100%' }}>
                <Table
                  disableColumnFilter={false}
                  disableColumnMenu={true}
                  disableColumnSelector={true}
                  rows={state.response}
                  columns={updatedColumns}
                  getRowId={row => row.AccessKeyId}
                  hideFooterPagination
                  checkboxSelections
                  disableSelectionOnClick
                  getRowClassName={() => 'no-highlight'}
                />
              </div>
            )}
            <ActionModals
              state={state}
              dispatch={dispatch}
              fetchAccessKeys={e => fetchAccessKeys(e)}
            />
            <LoaderWrap display={state.displayLoader} />
          </Box>
        </div>
      )}
    </>
  );
};
