import { requestApi, requestPublicApi } from '../../services/HttpRequester';

export function getAllAccessKeys() {
  return requestApi({
    method: 'GET',
    url: '/access-keys'
  });
}

export function createAccessKey(data) {
  return requestApi({
    method: 'POST',
    url: '/access-keys',
    data
  });
}

export function deleteAccessKey(accessKeyId) {
  return requestApi({
    method: 'DELETE',
    url: '/access-keys/'.concat(accessKeyId)
  });
}

export function getProfile() {
  return requestApi({
    method: 'GET',
    url: '/user/profile'
  });
}

export function updateProfile(data) {
  return requestApi({
    method: 'PUT',
    url: '/user/profile',
    data
  });
}

export function getUserMfa() {
  return requestApi({
    method: 'GET',
    url: '/user/mfa'
  });
}

export function updateUserMfa(data) {
  return requestApi({
    method: 'PUT',
    url: '/user/mfa',
    data
  });
}

export function getAllMembers() {
  return requestApi({
    method: 'GET',
    url: '/members/read/all'
  });
}

export function getMemberProfile(id) {
  return requestApi({
    method: 'GET',
    url: '/members/profile/'.concat(id)
  });
}

export function updateMemberMfa(id, data) {
  return requestApi({
    method: 'PUT',
    url: '/members/'.concat(id).concat('/disable-mfa'),
    data
  });
}

export function disableUserMfa(id, data) {
  return requestApi({
    method: 'PUT',
    url: '/user/'.concat(id).concat('/disable-mfa'),
    data
  });
}

export function updateMemberProfile(id, data) {
  return requestApi({
    method: 'PUT',
    url: '/members/profile/'.concat(id),
    data
  });
}

export function deleteMemberUser(id) {
  return requestApi({
    method: 'DELETE',
    url: '/members/'.concat(id)
  });
}

export function createMember(data) {
  return requestApi({
    method: 'POST',
    url: '/members',
    data
  });
}

export function deactivateMember(id) {
  return requestApi({
    method: 'PUT',
    url: `/members/deactivate/${id}`
  });
}

export function reinstateMember(id) {
  return requestApi({
    method: 'PUT',
    url: `/members/reinstate/${id}`
  });
}
export function getMemberGroups() {
  return requestApi({
    method: 'GET',
    url: '/members/groups'
  });
}

export function getTheme() {
  return requestPublicApi({
    method: 'GET',
    url: '/theme'
  });
}

export function getSSOConfig() {
  return requestApi({
    method: 'GET',
    url: '/sso/read-configuration'
  });
}

export function updateSSOConfigStatus(enabled) {
  return requestApi({
    method: 'PUT',
    url: `sso/update-status-configuration?enabled=${enabled ? 'true' : 'false'}`
  });
}

export function createOrEditOrganization(name, isEditing) {
  const data = { name };
  return requestApi({
    method: isEditing ? 'PUT' : 'POST',
    url: `sso/${isEditing ? 'update' : 'create'}-organisation`,
    data
  });
}

export function checkIfOrganizationExists(name) {
  return requestApi({
    method: 'GET',
    url: `sso/organisation-exists?orgName=${name}`
  });
}

export function createOrUpdateConnection(data) {
  return requestApi({
    method: 'POST',
    url: '/sso/connection',
    data
  });
}

export function getSamlConfig() {
  return requestApi({
    method: 'GET',
    url: '/sso/auth-sso-saml-config'
  });
}

export function deleteSsoConfiguration() {
  return requestApi({
    method: 'DELETE',
    url: '/sso'
  });
}

export function getAccountData() {
  return requestApi({
    method: 'GET',
    url: '/account-information'
  });
}
