import React from 'react';
import { Outlet } from 'react-router';
import UsageDetails from './UsageDetails';
import UsageList from './UsageList';
import BucketUsage from '../buckets/details/BucketUsage';

const Usage = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};

Usage.childrenRoutes = [
  { Component: UsageList, index: true },
  {
    path: 'usage-details',
    exact: true,
    Component: UsageDetails,
    childrenRoutes: UsageDetails.childrenRoutes
  },
  {
    path: ':id/usage-details/:dateTime',
    exact: true,
    Component: UsageDetails,
    childrenRoutes: UsageDetails.childrenRoutes
  },
  { path: 'bucket/:name/:number', exact: true, Component: BucketUsage }
];

export default Usage;
