import { action } from 'easy-peasy';

const attributes = {
  conToken: ''
};

const actions = {
  setConToken: action((state, payload) => {
    state.conToken = payload;
  })
};

const bucketDetails = {
  ...attributes,
  ...actions
};

export default bucketDetails;
