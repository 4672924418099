import React from 'react';
import AccessKeyAction from '../AccessKeyAction';
import PopperComponent from '../../../../common/popper';
import { ClickAwayListener } from '@mui/material';
import { KeysActions } from '../../helpers';
import { useTranslation } from 'react-i18next';

const AccessKeysAndPopper = ({
  handleClick,
  anchorEl,
  responseLoaded,
  response,
  onCreateAccessKeyClick,
  setResetKeysModal,
  canCreateAccessKeysPerm
}) => {
  const { t } = useTranslation();

  return (
    <>
      <AccessKeyAction
        mainIcon="settings"
        title={t('manageAccessKeys')}
        x
        collapse="arrow_left"
        onClick={handleClick}
        rotate={Boolean(anchorEl)}
      />

      <PopperComponent anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={() => handleClick()}>
          <KeysActions>
            <AccessKeyAction
              disabled={!responseLoaded || response?.length >= 2}
              mainIcon="generate_access_key"
              title={t('generateAccessKey')}
              onClick={
                !responseLoaded ||
                response?.length >= 2 ||
                !canCreateAccessKeysPerm
                  ? null
                  : () => onCreateAccessKeyClick()
              }
            />
            <AccessKeyAction
              onClick={() => setResetKeysModal(true)}
              danger
              mainIcon="reset_access_keys"
              title={t('resetAccessKeys')}
            />
          </KeysActions>
        </ClickAwayListener>
      </PopperComponent>
    </>
  );
};

export default AccessKeysAndPopper;
