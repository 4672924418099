import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';

import { colors } from '../constant';
import Box from '../common/box/Box';
import Text from '../common/text/Text';
import hasPermission from '../../security/Permission';

const SettingsTabs = () => {
  const { t } = useTranslation();
  const { theme } = useStoreState(actions => actions.theme);
  const canReadProfilePerm = hasPermission('oem_profile', 'read');
  const canReadMembersPerm = hasPermission('oem_members', 'read_all');
  const canReadAccessKeysPerm = hasPermission('oem_access_keys', 'read');

  const TabLink = styled(NavLink)`
    color: ${colors.darkBlue} !important;
    font-size: 16px;
    &:hover {
      color: ${theme.themes?.darkBlue} !important;
    }
    &.active {
      color: ${theme.themes?.darkBlue} !important;
      &:hover {
        color: ${theme.themes?.darkBlue} !important;
      }
    }
    &:not(:last-child) {
      margin-right: 30px;
    }
  `;

  return (
    <>
      {(canReadProfilePerm || canReadMembersPerm || canReadAccessKeysPerm) && (
        <Box w="100%" display="flex" direction="column" p="30px 0">
          <Text fontSize="18px" fontWeight="500" mb="15px" color={colors.black}>
            {t('settings')}
          </Text>
          <TabsWrapper
            display="flex"
            alignitems="center"
            p="0 30px"
            bgcolor={colors.white}
            borderradius="4px"
            minheightsize="60px"
          >
            {canReadProfilePerm && (
              <TabLink
                exact="true"
                className="active-tabs"
                activeclassname="active"
                to={'/settings/profile'}
              >
                {t('profile')}
              </TabLink>
            )}
            {canReadMembersPerm && (
              <TabLink
                exact="true"
                className="active-tabs"
                activeclassname="active"
                to={'/settings/members'}
              >
                {t('members')}
              </TabLink>
            )}

            {canReadAccessKeysPerm && (
              <TabLink
                exact="true"
                className="active-tabs"
                activeclassname="active"
                to={'/settings/access-keys'}
              >
                {t('accessKeys')}
              </TabLink>
            )}

            <TabLink
              exact="true"
              className="active-tabs"
              activeclassname="active"
              to={'/settings/account'}
            >
              {t('account')}
            </TabLink>
          </TabsWrapper>
        </Box>
      )}
    </>
  );
};

export default SettingsTabs;

const TabsWrapper = styled(Box)`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.0999235);
`;
