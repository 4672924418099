import React from 'react';
import styled, { keyframes } from 'styled-components';

const LdsRing = styled.div`
  position: relative;
  width: ${props => (props.w ? props.w : '103px')};
  height: ${props => (props.size ? props.size : '20px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const spinAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const SpinnerContainer = styled.div`
  display: block;
  position: relative;
  width: 22px;
  height: 22px;

  div {
    transform-origin: 11px 11px;
    animation: ${spinAnimation} 1.2s linear infinite;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 2px;
      left: 11px;
      width: 2px;
      height: 5px;
      border-radius: 6%;
      background: #b3b3b3;
    }
  }

  div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
`;

export const Spinner = passedProps => {
  return (
    <LdsRing {...passedProps}>
      <SpinnerContainer>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </SpinnerContainer>
    </LdsRing>
  );
};
