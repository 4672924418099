import styled from 'styled-components';

import { colors } from '../../../../constant';

export const SSOConfigContainer = styled.div`
  background-color: white;
  padding: 30px;
  position: relative;
`;

export const SSOConfigHeaderContainer = styled.div`
  width: 100%;
  padding-bottom: ${props => (props?.isToggled ? '20px' : '0')};
  border-bottom: 1px solid ${props => props?.borderColor || colors?.softGray};
  margin-bottom: ${props => (props?.isToggled ? '20px' : '0')};
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const SSOConfigTitle = styled.h1`
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
  color: ${colors?.black};
`;

export const SSOConfigDescriptions = styled.h1`
  margin: 0px;
  font-size: ${props => props.fontSize || '13px'};
  font-weight: ${props => props.fontWeight || 400};
  margin-bottom: ${props => props.marginBottom};
  color: ${colors?.black};
`;

export const ConfigContainerStyle = styled.div`
  background-color: ${colors?.softGray};
  border: 1px solid ${colors?.gray};
  border-radius: 4px;
  padding: 30px;
  margin-top: 20px;
`;

export const SamlConfigInputContainer = styled.div`
  width: 100%;
  padding: ${props => props.padding || '20px 0'};
  border-bottom: 1px solid ${props => props?.borderColor || colors?.softGray};
`;

export const UploadFileButton = styled.label`
  width: 180px;
  height: 40px;
  color: ${colors.white};
  background: ${props =>
    props.disabled ? colors.gray : ({ theme }) => theme?.themes?.colorPrimary};
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-bottom: 10px;
`;

export const UrlText = styled.h1`
  min-width: 300px;
  font-size: 14px;
  font-weight: ${props => props.fontWeight || 400};
  margin-bottom: ${props => props.marginBottom};
  color: ${colors?.black};
  margin-top: 10px;
`;

export const NoOrganizationDivContainer = styled.div`
  background-color: white;
  padding: 30px;
  margin-top: ${props => props.mt || '30px'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const NoOrganizationText = styled.h1`
  font-weight: ${props => props.fontWeight || 400};
  font-size: ${props => props.fontSize || '14px'};
  margin-bottom: ${props => props.marginBottom};
  color: ${colors?.black};
  margin-top: 10px;
`;

export const OrgNameAndDropdownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const OrganizationNameContainer = styled.div`
  margin-bottom: 35px;
  width: 405px;
  min-height: 40px;
  border-radius: 3px;
  border: 1px solid ${colors.lightGray};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  padding: 5px 15px;
  color: ${props => props.disabled && '#6c757d'};
  word-break: break-all;
  gap: 15px;
`;

export const ChannelLabel = styled.label`
  margin: 0px;
  font-size: 14px;
  font-weight: ${props => props.fontWeight || 400};
  margin-bottom: ${props => props.marginBottom};
  color: ${colors?.black};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const FileNameAndRemoveIcon = styled.div`
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  padding: 11px;
  border: 1px solid ${colors?.gray};
  width: fit-content;
`;

export const CheckingLoading = styled.div`
  height: 25px;
  font-size: 12px;
  font-weight: 400;
  color: ${colors?.gray30};
  cursor: pointer;
`;

export const FileAttached = styled.div`
  font-size: 14px;
  color: ${colors.green};
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
`;
