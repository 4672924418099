import React from 'react';
import {
  BoldDescription,
  KeysDescription,
  UnderlineDescription,
  ResetKeysAndDescriptionContainer
} from './helpers';
import { useTranslation } from 'react-i18next';

const ResetAccessKeys = () => {
  const { t } = useTranslation();
  return (
    <ResetKeysAndDescriptionContainer>
      <KeysDescription>
        <BoldDescription>{t('warning')}:</BoldDescription>{' '}
        {t('resetKeysDescriptionStart')}{' '}
        <BoldDescription>
          {t('allCurrentExistingRootAccessKeys')}
        </BoldDescription>{' '}
        <UnderlineDescription>{t('deletedLower')}</UnderlineDescription>{' '}
        {t('resetKeysDescriptionEnd')}
      </KeysDescription>
    </ResetKeysAndDescriptionContainer>
  );
};
export default ResetAccessKeys;
