import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '../../../../common/box/Box';
import { RadioButton } from '../../../../common/radio-button';
import Text from '../../../../common/text/Text';

import { Title, Description } from './styles';

export default function SingleOption({
  id,
  checked,
  onChange,
  title,
  description,
  currentDatabaseMode
}) {
  const { t } = useTranslation();
  const enabledColor = '#CEFFDD';
  const textColorTag = '#073A16';
  return (
    <Box mt={'20px'}>
      <Box display={'flex'} alignitems={'center'} gap={'5px'}>
        <Box ml={'-3px'}>
          <RadioButton id={id} checked={checked} onChange={onChange} />
        </Box>
        <Title htmlFor={id}>{title}</Title>
        {currentDatabaseMode && (
          <Text
            background={enabledColor}
            fontSize="14px"
            color={textColorTag}
            fontWeight="500"
            p="3px 7px"
            borderradius="4px"
          >
            {t('enabled')}
          </Text>
        )}
      </Box>
      <Description>{description}</Description>
    </Box>
  );
}

SingleOption.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  currentDatabaseMode: PropTypes.bool.isRequired
};
