import styled from 'styled-components';

export const NewRootAccessKeysContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 60px);
  margin-top: -60px;
`;
export const NewRootAccessKeysModal = styled.div`
  width: 452px;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.101617);
  border-radius: 4px;
  margin-top: 175px;
  height: fit-content;
`;
export const NewRootAccessKeysTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

export const DividerContainer = styled.div`
  padding: 0px 20px;
`;
export const Divider = styled.div`
  height: 1px;
  background: #f1f1f1;
`;
