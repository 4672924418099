import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  CloseModalImg,
  ModalBasic,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../../../../common/modal/Modal';
import Box from '../../../../common/box/Box';
import Icon from '../../../../common/icon/Icon';
import Text from '../../../../common/text/Text';
import Title from '../../../../common/title/Title';
import Input from '../../../../common/input/Input';
import Button from '../../../../common/button/Button';
import CloseModalIcon from '../../../../../assets/icons/x.svg';
import { colors } from '../../../../constant';
import { showToast } from '../../../../../hooks/show-toast';
import { saveFileAs } from '../../../../../services/Download';
import { LoaderWrap } from '../../../../common/loader/Loader';

export default function KeysModal({
  open,
  close,
  secretKey,
  accessKey,
  displayLoader
}) {
  const { t } = useTranslation();
  const [showAccessKey, setShowAccessKey] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState(false);
  const [isCopyLoading, setIsCopyLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const handleDownloadKeys = async e => {
    setIsDownloadLoading(true);
    e.preventDefault();
    const headers = [t('name'), t('accessKey'), t('secretKey')];
    const data = ['\r\n'.concat('Root Account Key'), accessKey, secretKey];
    saveFileAs(
      [...headers, ...data],
      'credentials.csv',
      'text/csv;charset=utf-8'
    );
    setTimeout(() => {
      setIsDownloadLoading(false);
    }, 100);
  };

  const handleCopyKeys = async e => {
    e.preventDefault();
    setIsCopyLoading(true);
    await navigator.clipboard.writeText(
      t('access-key')
        .concat(' = ')
        .concat(accessKey)
        .concat('\r\n')
        .concat(t('secret-key'))
        .concat(' = ')
        .concat(secretKey)
    );
    showToast(
      {
        message: t('keysCopiedToClipboard'),
        success: true,
        customMessage: true
      },
      'info'
    );
    setTimeout(() => {
      setIsCopyLoading(false);
    }, 100);
  };

  return (
    <ModalBasic
      style={{
        width: '550px',
        overflowy: 'auto',
        maxHeight: '88vh',
        mt: '60px'
      }}
      open={open}
      closeModal={close}
      // eslint-disable-next-line react/no-children-prop
      children={<Input />}
    >
      <Box relative="true">
        <ModalHeader>
          <Title color={colors.black} fontSize="16px" fontWeight="500">
            {t('newRootAccessKeys')}
          </Title>
          <CloseModalImg src={CloseModalIcon} onClick={close} />
        </ModalHeader>

        <ModalBody>
          <form>
            <Text fontSize="14px" maxwidth="490px" lineheight="20px">
              {t('copyOrDownloadKeys')}
              <br />
              {t('secretKeyAvailability')}
            </Text>

            <Title
              fontSize="16px"
              fontWeight="500"
              color={colors.black}
              m="30px 0px"
            >
              {t('s3Credentials')}
            </Title>
            <div className="input-wrapper modified">
              <Input
                size="0"
                width="100%"
                fontSize="14px"
                name="accessKey"
                type={showAccessKey ? 'text' : 'password'}
                value={accessKey ?? ''}
                readOnly
              />
              <label
                htmlFor="accessKey"
                className={accessKey?.length > 0 ? 'filledField' : ''}
              >
                {t('accessKey')}
                <Icon
                  icon={showAccessKey ? 'close_eye' : 'view'}
                  display={accessKey?.length > 0 ? 'initial' : 'none'}
                  onClick={() => setShowAccessKey(prev => !prev)}
                />
              </label>
            </div>

            <div className="input-wrapper modified">
              <Input
                size="0"
                width="100%"
                fontSize="14px"
                name="secretKey"
                type={showSecretKey ? 'text' : 'password'}
                value={secretKey ?? ''}
                readOnly
              />
              <label
                htmlFor="secretKey"
                className={secretKey?.length > 0 ? 'filledField' : ''}
              >
                {t('secretKey')}
                <Icon
                  icon={showSecretKey ? 'close_eye' : 'view'}
                  display={secretKey?.length > 0 ? 'initial' : 'none'}
                  onClick={() => setShowSecretKey(prev => !prev)}
                />
              </label>
            </div>
          </form>
        </ModalBody>

        <ModalFooter display="flex" justifycontent="flex-end">
          <Button
            w="fit-content"
            mr="20px"
            isLoading={isDownloadLoading}
            onClick={handleDownloadKeys}
            primary
          >
            {t('downloadKeys')}
          </Button>
          <Button
            w="fit-content"
            isLoading={isCopyLoading}
            onClick={handleCopyKeys}
            primary
          >
            {t('copyKeys')}
          </Button>
        </ModalFooter>

        <LoaderWrap display={displayLoader} />
      </Box>
    </ModalBasic>
  );
}

KeysModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  secretKey: PropTypes.string.isRequired,
  accessKey: PropTypes.string.isRequired,
  displayLoader: PropTypes.bool.isRequired
};
