/* eslint-disable no-restricted-globals */
import jwt from 'jwt-decode';
import { createStore } from 'easy-peasy';

import reducers from './reducers';

const storeConfig = {
  name: 'localStorageConfig-name',
  storage: 'localStorage'
};

const store = createStore(
  {
    ...reducers,
    usage: {
      ...reducers.usage,
      usageLastSync: null,
      bucketUsageDates: [null, null]
    },
    user: {
      ...reducers.user,
      userData:
        localStorage.getItem('Authorization') !== null
          ? jwt(localStorage.getItem('Authorization')).user
          : [],
      firstAndLastName:
        localStorage.getItem('firstAndLastName') !== null
          ? localStorage.getItem('firstAndLastName')
          : null
    },
    authentication: {
      ...reducers.authentication,
      authLoader: false,
      resetLoader: false
    },
    notifications: {
      ...reducers.notifications,
      notification: {
        color: '',
        icon: 'info',
        title: ''
      },
      allNotifications: []
    },
    fileUpload: {
      ...reducers.fileUpload,
      filesUploadingGlobal: [],
      customToastId: 'custom-id'
    },
    general: {
      ...reducers.general,
      isRedirecting: false
    },
    bucketDetails: {
      ...reducers.bucketDetails,
      conToken: ''
    },
    bucketObjects: {
      ...reducers.bucketObjects,
      lastObjectsByPage: []
    }
  },
  storeConfig
);

// if (process.env.NODE_ENV === "development") {
//   if (module.hot) {
//     module.hot.accept("./reducers", () => {
//       store.reconfigure(reducers);
//     });
//   }
// }

export default store;
