/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

import {
  CloseModalImg,
  ModalBasic,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../../common/modal/Modal';
import Box from '../../common/box/Box';
import Icon from '../../common/icon/Icon';
import Text from '../../common/text/Text';
import Button from '../../common/button/Button';
import Input, { ErrorMessage } from '../../common/input/Input';
import hasPermission from '../../../security/Permission';
import CloseModalIcon from '../../../assets/icons/x.svg';
import CheckboxWrapper from '../../common/checkbox/CheckboxWrapper';
import { Dropdown } from '../../common/select/Dropdown';
import { LabelInput } from '../../common/label/LabelInput';
import { createMember, getMemberGroups } from '../endpoints';
import { showToast } from '../../../hooks/show-toast';

import { CreateMemberWithToolTip } from './helpers';

export function CreateMembers({ open, close, onSubmit }) {
  const { t } = useTranslation();
  const canCreateMemberPerm = hasPermission('oem_members', 'create');
  const [response, setResponse] = useState(null);
  const [displayLoader, setDisplayLoader] = useState(false);
  const [error, setError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedRole, setSelectedRole] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVerified, setPasswordVerified] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset
  } = useForm({ mode: 'onChange' });

  /**
   * Get all Member Role
   */
  const getMemberRoles = () => {
    getMemberGroups().then(([res]) => {
      const arr = res.data.map(item => {
        return {
          ...item,
          name: t(item.name)
        };
      });
      setResponse([...arr]);
    });
  };

  useEffect(() => {
    if (!open) {
      setFirstName('');
      setLastName('');
      setUserName('');
      setEmail('');
      setSelectedRole('');
      setPassword('');
      setConfirmPassword('');
      setError('');
      setIsFirstLogin(true);
      reset();
    }

    if (open) {
      getMemberRoles();
    }
  }, [open]);

  const onFormSubmit = () => {
    setDisplayLoader(true);

    if (error.length === 0) {
      const data = {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Username: userName,
        Active: false,
        Password: password,
        Mfa: 'disabled',
        LastLogin: null,
        GroupId: selectedRole,
        IsFirstLogin: isFirstLogin
      };
      createMember(data).then(([res]) => {
        setDisplayLoader(false);

        if (res.success) {
          let data = res.data;
          onSubmit({
            url: data?.URL,
            email: data?.email,
            password: data?.password,
            message: res?.code || res?.Code,
            success: res?.success
          });
          close();
        } else {
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: false
            },
            'error'
          );
        }
      });
    }
  };

  const onChangeIsFirstLogin = event => {
    setIsFirstLogin(event.target.checked);
  };
  const onChangeFirstName = event => {
    setFirstName(event.target.value);
  };

  const onChangeLastName = event => {
    setLastName(event.target.value);
  };

  const onChangeUserName = event => {
    setUserName(event.target.value);
  };

  const handleRoleChange = event => {
    setSelectedRole(event.target.value);
  };

  const onChangeEmail = event => {
    setEmail(event.target.value);
  };

  const onChangePassword = e => {
    setPassword(e.target.value);
    setPasswordVerified(
      confirmPassword.length > 0 && e.target.value === confirmPassword
    );
  };

  const onChangeConfirmPassword = e => {
    setConfirmPassword(e.target.value);
    setPasswordVerified(password.length > 0 && e.target.value === password);
  };

  const emailRegex = /^$|^(^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-][a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$)?$/;

  return (
    <>
      {canCreateMemberPerm && (
        <ModalBasic
          style={{ width: '550px', maxHeight: '88vh', mt: '60px' }}
          open={open}
          closeModal={close}
          // eslint-disable-next-line react/no-children-prop
          children={<Input />}
        >
          <Box relative="true">
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <ModalHeader>
                <CreateMemberWithToolTip />
                <CloseModalImg src={CloseModalIcon} onClick={close} />
              </ModalHeader>

              <ModalBody>
                <>
                  <Box display="grid" columngap="30px" gridcolumn="1fr 1fr">
                    <div className="input-wrapper">
                      <Input
                        size="0"
                        width="100%"
                        fontSize="14px"
                        name="firstName"
                        type="text"
                        value={firstName}
                        className={errors.firstName ? 'not-valid' : ''}
                        {...register('firstName', {
                          onChange: onChangeFirstName,
                          minLength: 2,
                          maxLength: 50,
                          required: true,
                          pattern: /^\S.*$/g
                        })}
                      />
                      <label
                        htmlFor="firstName"
                        className={firstName.length > 0 ? 'filledField' : ''}
                      >
                        {t('firstName')}*
                      </label>
                      {errors.firstName?.type === 'minLength' && (
                        <ErrorMessage>
                          {t('firstNameMinLengthError')}
                        </ErrorMessage>
                      )}
                      {errors.firstName?.type === 'maxLength' && (
                        <ErrorMessage>
                          {t('firstNameMaxLengthError')}
                        </ErrorMessage>
                      )}
                      {errors.firstName?.type === 'required' && (
                        <ErrorMessage>{t('firstNameRequired')}</ErrorMessage>
                      )}
                      {errors.firstName?.type === 'pattern' && (
                        <ErrorMessage>{t('firstNamePattern')}</ErrorMessage>
                      )}
                    </div>

                    <div className="input-wrapper">
                      <Input
                        size="0"
                        width="100%"
                        fontSize="14px"
                        name="lastName"
                        type="text"
                        value={lastName}
                        className={errors.lastName ? 'not-valid' : ''}
                        {...register('lastName', {
                          onChange: onChangeLastName,
                          minLength: 2,
                          maxLength: 50,
                          required: true,
                          pattern: /^\S.*$/g
                        })}
                      />
                      <label
                        htmlFor="lastName"
                        className={lastName.length > 0 ? 'filledField' : ''}
                      >
                        {t('lastName')}*
                      </label>
                      {errors.lastName?.type === 'minLength' && (
                        <ErrorMessage>
                          {t('lastNameMinLengthError')}
                        </ErrorMessage>
                      )}
                      {errors.lastName?.type === 'maxLength' && (
                        <ErrorMessage>
                          {t('lastNameMaxLengthError')}
                        </ErrorMessage>
                      )}
                      {errors.lastName?.type === 'required' && (
                        <ErrorMessage>{t('lastNameRequired')}</ErrorMessage>
                      )}
                      {errors.lastName?.type === 'pattern' && (
                        <ErrorMessage>{t('lastNamePattern')}</ErrorMessage>
                      )}
                    </div>
                  </Box>

                  <div className="input-wrapper">
                    <Input
                      size="0"
                      width="100%"
                      fontSize="14px"
                      name="userName"
                      type="text"
                      value={userName}
                      className={errors.userName ? 'not-valid' : ''}
                      {...register('userName', {
                        onChange: onChangeUserName,
                        minLength: 3,
                        maxLength: 200,
                        required: true,
                        pattern: /^\S.*$/g
                      })}
                    />
                    <label
                      htmlFor="userName"
                      className={userName.length > 0 ? 'filledField' : ''}
                    >
                      {t('userName')}*
                    </label>
                    {errors.userName?.type === 'minLength' && (
                      <ErrorMessage>{t('userNameMinLength')}</ErrorMessage>
                    )}
                    {errors.userName?.type === 'maxLength' && (
                      <ErrorMessage>{t('userNameMaxLength')}</ErrorMessage>
                    )}
                    {errors.userName?.type === 'required' && (
                      <ErrorMessage>{t('userNameRequired')}</ErrorMessage>
                    )}
                    {errors.userName?.type === 'pattern' && (
                      <ErrorMessage>{t('userNamePattern')}</ErrorMessage>
                    )}
                  </div>

                  <FormControl fullWidth>
                    <LabelInput id="storeLogsLabel" htmlFor="storeLogs">
                      {t('userRole')}*
                    </LabelInput>
                    <Dropdown
                      id="storeLogs"
                      label={t('userRole')}
                      labelId="storeLogsLabel"
                      fullWidth
                      variant="outlined"
                      name="storeLogs"
                      value={selectedRole}
                      className={
                        errors?.storeLogs && !selectedRole ? 'not-valid' : ''
                      }
                      {...register('storeLogs', {
                        onChange: handleRoleChange,
                        required: true
                      })}
                      MenuProps={{
                        getcontentanchorel: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left'
                        },
                        PaperProps: { sx: { maxHeight: 230 } }
                      }}
                    >
                      {response &&
                        response.map(b => (
                          <MenuItem key={b.id} value={b.id}>
                            <Box
                              w="100%"
                              display="flex"
                              p="0"
                              lineheight="40px"
                            >
                              <Text fontSize="14px" fontWeight="400">
                                {b.name}
                              </Text>
                            </Box>
                          </MenuItem>
                        ))}
                    </Dropdown>
                    {/*{errors.storeLogs?.type === 'required' && (*/}
                    {/*    <ErrorMessage>{t("selectedRoleRequired")}</ErrorMessage>)}*/}
                  </FormControl>

                  <Box className="input-wrapper" mt="30px">
                    <Input
                      size="0"
                      width="100%"
                      fontSize="14px"
                      name="Email"
                      type="email"
                      value={email}
                      className={errors.email ? 'not-valid' : ''}
                      {...register('email', {
                        onChange: onChangeEmail,
                        required: true,
                        pattern: {
                          value: emailRegex
                        }
                      })}
                    />
                    <label
                      htmlFor="Email"
                      className={email.length > 0 ? 'filledField' : ''}
                    >
                      {t('email')}*
                    </label>
                    {errors.email?.type === 'required' && (
                      <ErrorMessage>{t('emailRequired')}</ErrorMessage>
                    )}
                    {errors.email?.type === 'pattern' && (
                      <ErrorMessage>{t('emailPattern')}</ErrorMessage>
                    )}
                  </Box>

                  <div className="input-wrapper">
                    <Input
                      size="0"
                      width="100%"
                      fontSize="14px"
                      name="password"
                      type="password"
                      className={errors.password ? 'not-valid' : ''}
                      {...register('password', {
                        onChange: onChangePassword,
                        required: true,
                        minLength: 5,
                        pattern: /(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[\" \!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\=\>\?\@\[\\\]\^\_\`\{\|\}\~\"])(?=.*[a-zA-Z]).{6,}/
                      })}
                    />
                    <label
                      htmlFor="password"
                      className={password.length > 0 ? 'filledField' : ''}
                    >
                      {t('password')}*
                    </label>
                    {errors.password?.type === 'minLength' && (
                      <ErrorMessage>{t('passwordMinLength')}</ErrorMessage>
                    )}
                    {errors.password?.type === 'pattern' && (
                      <ErrorMessage>{t('passwordPattern')}</ErrorMessage>
                    )}
                    {errors.password?.type === 'required' && (
                      <ErrorMessage>{t('passwordRequired')}</ErrorMessage>
                    )}
                  </div>

                  <div className="input-wrapper">
                    <Input
                      size="0"
                      width="100%"
                      fontSize="14px"
                      name="confirmPassword"
                      type="password"
                      padding="0 35px 0 10px"
                      className={
                        errors.confirmPassword ||
                        (!passwordVerified && confirmPassword.length > 0)
                          ? 'not-valid'
                          : ''
                      }
                      {...register('confirmPassword', {
                        onChange: onChangeConfirmPassword,
                        required: true
                      })}
                    />
                    <label
                      htmlFor="confirmPassword"
                      className={
                        confirmPassword.length > 0 ? 'filledField' : ''
                      }
                    >
                      {t('confirmPassword')}*
                    </label>
                    <Icon
                      icon="verify"
                      position="absolute"
                      right="10px"
                      top="12px"
                      display={
                        passwordVerified && confirmPassword.length > 0
                          ? 'block'
                          : 'none'
                      }
                    />

                    {errors.confirmPassword?.type === 'required' && (
                      <ErrorMessage>
                        {t('confirmPasswordRequired')}
                      </ErrorMessage>
                    )}
                    {!passwordVerified && confirmPassword.length > 0 && (
                      <ErrorMessage>{t('passwordsMustMatch')}</ErrorMessage>
                    )}
                  </div>

                  <Box display="flex" alignitems="center">
                    <CheckboxWrapper>
                      <input
                        checked={isFirstLogin}
                        onChange={e => onChangeIsFirstLogin(e)}
                        type="checkbox"
                        id="pw-input"
                      />
                      <LabelInput
                        fontSize="14px"
                        id="pw-label"
                        htmlFor="pw-input"
                      >
                        {t('passwordResetRequired')}
                      </LabelInput>
                    </CheckboxWrapper>
                  </Box>
                </>
              </ModalBody>

              <ModalFooter display="flex" justifycontent="flex-end" mt="10px">
                <Button
                  type="button"
                  onClick={close}
                  mr="20px"
                  className="cancel"
                >
                  {t('cancel')}
                </Button>
                <Button
                  w="134px"
                  isLoading={displayLoader}
                  type="submit"
                  primary
                  disabled={!isValid || !passwordVerified}
                >
                  {t('create')}
                </Button>
              </ModalFooter>
            </form>
          </Box>
        </ModalBasic>
      )}
    </>
  );
}

CreateMembers.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
