import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  CloseModalImg,
  ModalBasic,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../../../common/modal/Modal';
import Input from '../../../common/input/Input';
import Title from '../../../common/title/Title';
import CloseModalIcon from '../../../../assets/icons/x.svg';
import Text from '../../../common/text/Text';
import Button from '../../../common/button/Button';

export default function ToggleMemberStatus({
  open,
  close,
  activate,
  isLoading,
  handleSubmit
}) {
  const { t } = useTranslation();
  return (
    <ModalBasic
      style={{ width: '450px' }}
      open={open}
      closeModal={close}
      // eslint-disable-next-line react/no-children-prop
      children={<Input />}
    >
      <div>
        <ModalHeader>
          <Title fontSize="16px" fontWeight="500">
            {activate ? t('activateMember') : t('deactivateMember')}
          </Title>
          <CloseModalImg src={CloseModalIcon} onClick={close} />
        </ModalHeader>
        <ModalBody>
          <Text mb="60px" fontSize="14px" fontWeight="400" centeritems="true">
            {t('confirmAction', {
              action: activate ? t('activate') : t('deactivate')
            })}{' '}
            {t('member')}?
          </Text>
        </ModalBody>
        <ModalFooter width="100%" display="flex" justifycontent="flex-end">
          <Button onClick={close} cancel mr="20px" className={'cancel'}>
            {t('cancel')}
          </Button>
          <Button
            w="130px"
            isLoading={isLoading}
            onClick={handleSubmit}
            primary
          >
            {activate ? t('activate') : t('deactivate')}
          </Button>
        </ModalFooter>
      </div>
    </ModalBasic>
  );
}

ToggleMemberStatus.propTypes = {
  open: PropTypes.bool.isRequired,
  activate: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};
