import React from 'react';
import { FormControl, MenuItem } from '@mui/material';

import OpenIdConfig from './OpenIdConfig';
import SamlConfig from './SamlConfig';
import { menuItems } from '../helpers';
import { OrgNameAndDropdownContainer } from '../styles';

import { Dropdown } from '../../../../../common/select/Dropdown';
import Text from '../../../../../common/text/Text';
import Box from '../../../../../common/box/Box';
import hasPermission from '../../../../../../security/Permission';

const ConfigRenderer = ({ formik, ssoConfigData }) => {
  const editSSOPerm = hasPermission('oem_sso', 'connection');

  const renderConnectionType = () => {
    switch (formik?.values?.connectionType) {
      case 'SAMLP':
        return <SamlConfig ssoConfigData={ssoConfigData} formik={formik} />;
      case 'OIDC':
        return <OpenIdConfig ssoConfigData={ssoConfigData} formik={formik} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <OrgNameAndDropdownContainer>
        <FormControl sx={{ width: '405px', marginTop: '10px' }}>
          <Dropdown
            disabled={!editSSOPerm}
            name="connectionType"
            value={formik?.values?.connectionType}
            onChange={e => {
              formik?.resetForm({
                values: {
                  ...formik.initialValues,
                  organizationName: formik.values.organizationName
                }
              });
              formik?.setFieldValue('connectionType', e.target.value);
            }}
          >
            {menuItems.map(item => (
              <MenuItem key={item.value} value={item.value}>
                <Box w="100%" display="flex" p="0" lineheight="40px">
                  <Text fontSize="14px" fontWeight="400">
                    {item?.displayName}
                  </Text>
                </Box>
              </MenuItem>
            ))}
          </Dropdown>
        </FormControl>
      </OrgNameAndDropdownContainer>
      {renderConnectionType()}
    </>
  );
};

export default ConfigRenderer;
