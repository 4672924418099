/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { ThemeProvider } from 'styled-components';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { wait } from '@testing-library/user-event/dist/utils';
import 'react-toastify/dist/ReactToastify.css';

import OEMApp from './components';
import { getTheme } from './components/settings/endpoints';
import defaultFavIcon from './assets/icons/wfi.ico';
import GlobalStyles from './components/GlobalStyles';
import NotificationContainer from './components/common/notification/NotificationContainer';
import { LoaderWrap } from './components/common/loader/Loader';
import { showUploadToast } from './hooks/show-toast';

function App() {
  const defaultTheme = {
    themes: {
      navColor: '#000000',
      headerBackground: '#ffffff',
      navActiveColor: '#f8f8f8',
      colorPrimary: '#deebf1'
    }
  };

  const { setTheme } = useStoreActions(actions => actions.theme);
  const { theme } = useStoreState(actions => actions.theme);
  const { userData } = useStoreState(state => state.user);
  const { isRedirecting } = useStoreState(state => state.general);
  const { authLoader } = useStoreState(state => state.authentication);

  useEffect(() => {
    if (JSON.stringify(theme) === '{}') {
      getTheme().then(([res]) => {
        if (res?.success) {
          const data = res.data;
          const themesData = {
            navColor: '',
            headerBackground: '',
            navActiveColor: '',
            colorPrimary: ''
          };
          if (data && data.slug) {
            themesData.colorPrimary =
              data.themes.filter(th => th.code === '--colorPrimary').pop()
                ?.value ?? defaultTheme.themes.colorPrimary;
            themesData.headerBackground =
              data.themes.filter(th => th.code === '--headerBackground').pop()
                ?.value ?? defaultTheme.themes.headerBackground;
            themesData.navActiveColor =
              data.themes.filter(th => th.code === '--navActiveColor').pop()
                ?.value ?? defaultTheme.themes.navActiveColor;
            themesData.navColor =
              data.themes.filter(th => th.code === '--navColor').pop()?.value ??
              defaultTheme.themes.navColor;
            data.themes = themesData;
            setTheme(data);
          } else if (!theme) {
            setTheme(defaultTheme);
          }
        }
      });
      if (
        (localStorage.getItem('ssoDeletedOrg') ||
          localStorage.getItem('userIsSso')) &&
        localStorage.getItem('accountDeActive')
      ) {
        showUploadToast(
          {
            message:
              'You have been logged out due to a change in your account. Please login again or contact your account administrator.',
            success: false
          },
          'error'
        );
        wait(3000).then(() => {
          localStorage.removeItem('accountDeActive');
          localStorage.removeItem('invalidToken');
          localStorage.removeItem('inactivity');
          localStorage.removeItem('refreshTokenExpired');
          localStorage.removeItem('ssoDeletedOrg');
          localStorage.removeItem('userIsSso');
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    theme &&
    theme.slug && (
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{theme?.loginTitle}</title>
            <meta name="description" content={theme?.loginTitle} />
            <link
              rel="icon"
              href={theme?.favIcon !== '' ? theme?.favIcon : defaultFavIcon}
            />
          </Helmet>
        </HelmetProvider>

        <BrowserRouter>
          <GlobalStyles />
          <OEMApp />
        </BrowserRouter>
        <NotificationContainer />
        <LoaderWrap display={isRedirecting || authLoader} />
      </ThemeProvider>
    )
  );
}

export default App;
