import React from 'react';
import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ConfigContainer from './ConfigContainer';

const Roles = () => {
  const { t } = useTranslation();

  const rows = [
    { name: t('Root'), description: t('rootDescription') },
    { name: t('Administrator'), description: t('administratorDescription') },
    { name: t('Full Access'), description: t('fullAccessDescription') },
    {
      name: t('Full Access Limited'),
      description: t('fullAccessLimitedDescription')
    },
    { name: t('Download Only'), description: t('downloadOnlyDescription') },
    { name: t('Read Only'), description: t('readOnlyDescription') }
  ];

  return (
    <ConfigContainer title={t('roles')} description="">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('role')}</TableCell>
              <TableCell align="left">{t('description')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  sx={{ minWidth: '200px', fontSize: '14px' }}
                  component="th"
                  scope="row"
                >
                  {row.name}
                </TableCell>
                <TableCell sx={{ fontSize: '14px' }} align="left">
                  {row.description}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ConfigContainer>
  );
};

export default Roles;
