import React from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';

import { ExportModal } from '../../common/exportmodal/ExportModal';
import { ENDPOINTS } from '../../../constants/endpoints';
import { formatToYearMonthDayWithTimeZoneOffset } from '../../../utils/format.dates.utils';
import { exportElasticServiceFile } from '../endpoint';

const ExportUsage = ({
  setExportLoading,
  showExportModal,
  setShowExportModal,
  exportLoading,
  date
}) => {
  async function exportFileSave(event) {
    setExportLoading(true);
    let extension = event;
    if (event === 'excel') {
      extension = 'xlsx';
    }
    exportElasticServiceFile(
      ENDPOINTS.GET_USAGE_EXPORT.replace(':type', event).concat(
        date[1]
          ? `range=endTime=${formatToYearMonthDayWithTimeZoneOffset(
              date[0]
            )},${formatToYearMonthDayWithTimeZoneOffset(date[1])}`
          : ''
      )
    ).then(([res, error]) => {
      if (error) {
        // silent err
      }
      setShowExportModal(false);
      setExportLoading(false);
      let fileName = res?.headers['file-name'];
      if (!fileName) {
        fileName = 'export';
      }
      saveAs(new Blob([res.data]), `${fileName}.${extension}`);
    });
  }

  return (
    <ExportModal
      open={showExportModal}
      isLoading={exportLoading}
      close={() => setShowExportModal(false)}
      onSubmit={e => exportFileSave(e)}
    />
  );
};

ExportUsage.propTypes = {
  setExportLoading: PropTypes.func.isRequired,
  showExportModal: PropTypes.bool.isRequired,
  setShowExportModal: PropTypes.func.isRequired,
  exportLoading: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired
};

export default ExportUsage;
