import createLoadingThunks from '../createLoadingThunks';
import authentication from './authentication';
import user from './user';
import theme from './theme';
import accessKeys from './access-keys';
import usage from './usage';
import fileDetails from './file-details';
import fileUpload from './file-upload';
import notifications from './notifications';
import general from './general';
import bucketDetails from './bucket-details';
import bucketObjects from './bucket-objects';

export default createLoadingThunks({
  accessKeys,
  user,
  authentication,
  theme,
  fileDetails,
  usage,
  notifications,
  fileUpload,
  general,
  bucketDetails,
  bucketObjects
});
