import React from 'react';
import Popper from '@mui/material/Popper';

const PopperComponent = ({ anchorEl, children }) => {
  const open = Boolean(anchorEl);
  return (
    <Popper open={open} anchorEl={anchorEl}>
      {children}
    </Popper>
  );
};

export default PopperComponent;
