/* eslint-disable no-restricted-globals */
import moment from 'moment';
import ptBR from 'rsuite/locales/pt_BR';
import enUs from 'rsuite/locales/en_US';
import itIt from 'rsuite/locales/it_IT';
import 'moment/locale/pt-br';
import 'moment/locale/it';

const language = JSON.parse(localStorage.getItem('language'));

export const returnRsuiteLocale = () => {
  switch (language?.value) {
    case 'pt':
      return ptBR;
    case 'it':
      return itIt;
    default:
      return enUs;
  }
};

export const returnMomentLocale = () => {
  switch (language?.value) {
    case 'pt':
      moment.locale('pt-br');
      break;
    case 'it':
      moment.locale('it');
      break;
    default:
      moment.locale('en-us');
      break;
  }
};
