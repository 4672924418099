import styled from 'styled-components';
import { Radio } from '@mui/material';
import { colors } from '../../constant';

export const RadioButton = styled(Radio).attrs(
  ({
    sx,
    componentProps,
    theme,
    pathColor,
    background,
    checked = false,
    disabled
  }) => ({
    sx: {
      '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path': {
        color:
          disabled && checked
            ? colors?.gray10
            : checked && pathColor
            ? theme
              ? theme.themes?.colorPrimary
              : colors.mui_blue
            : pathColor || '#F1F1F1',
        strokeWidth: 2
      },
      background,
      padding: 0,
      '&.Mui-checked': {
        color: disabled
          ? colors.gray10
          : theme
          ? theme.themes?.colorPrimary
          : colors.mui_blue
      }
    }
  })
)``;
