import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  label {
    cursor: ${props => props.cursor || 'pointer'};
    padding-left: 25px;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translate3d(0, 0, 0) translateY(-50%);
    }

    &:before {
      left: 0;
      width: 13px;
      height: 13px;
      background: #ffffff;
      border: 1px solid #f1f1f1;
      border-radius: 2px;
    }

    &:after {
      left: 3px;
      width: 7px;
      height: 7px;
      opacity: 0;
      background: ${({ theme }) => theme?.themes?.colorPrimary};
      border-radius: 1px;
    }
  }

  input[type='checkbox'] {
    position: absolute;
    top: 0;
    left: -9999px;
    visibility: hidden;

    &:checked + label {
      &:before {
      }

      &:after {
        opacity: 1;
      }
    }
  }
`;

export default CheckboxWrapper;
