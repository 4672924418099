import React from 'react';

const GlobeIcon = ({ fill = '#666666', width = '11', extraStyle = {} }) => {
  return (
    <svg
      style={extraStyle}
      width={width}
      height={width}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.745 8.4645C8.602 8.0245 8.1895 7.7 7.7 7.7H7.15V6.05C7.15 5.90413 7.09205 5.76424 6.98891 5.66109C6.88576 5.55795 6.74587 5.5 6.6 5.5H3.3V4.4H4.4C4.54587 4.4 4.68576 4.34205 4.78891 4.23891C4.89205 4.13576 4.95 3.99587 4.95 3.85V2.75H6.05C6.34174 2.75 6.62153 2.63411 6.82782 2.42782C7.03411 2.22153 7.15 1.94174 7.15 1.65V1.4245C8.7615 2.0735 9.9 3.652 9.9 5.5C9.9 6.644 9.46 7.6835 8.745 8.4645ZM4.95 9.8615C2.7775 9.592 1.1 7.744 1.1 5.5C1.1 5.159 1.144 4.829 1.2155 4.5155L3.85 7.15V7.7C3.85 7.99174 3.96589 8.27153 4.17218 8.47782C4.37847 8.68411 4.65826 8.8 4.95 8.8M5.5 0C4.77773 0 4.06253 0.142262 3.39524 0.418663C2.72795 0.695063 2.12163 1.10019 1.61091 1.61091C0.579463 2.64236 0 4.04131 0 5.5C0 6.95869 0.579463 8.35764 1.61091 9.38909C2.12163 9.89981 2.72795 10.3049 3.39524 10.5813C4.06253 10.8577 4.77773 11 5.5 11C6.95869 11 8.35764 10.4205 9.38909 9.38909C10.4205 8.35764 11 6.95869 11 5.5C11 4.77773 10.8577 4.06253 10.5813 3.39524C10.3049 2.72795 9.89981 2.12163 9.38909 1.61091C8.87837 1.10019 8.27205 0.695063 7.60476 0.418663C6.93747 0.142262 6.22227 0 5.5 0Z"
        fill={fill}
      />
    </svg>
  );
};

export default GlobeIcon;
