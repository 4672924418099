import { action } from 'easy-peasy';

const DEFAULT_STATE = {
  usageDates: [],
  usageLastSync: null,
  statusNumbers: {
    totalStorageLabel: null,
    totalObjectsLabel: null
  },
  bucketUsageDates: []
};

const attributes = {
  defaultState: DEFAULT_STATE
};

const actions = {
  setUsageDates: action((state, usageDates) => {
    state.usageDates = usageDates;
  }),
  setUsageLastSync: action((state, usageLastSync) => {
    state.usageLastSync = usageLastSync;
  }),
  setStatusNumbers: action((state, statusNumbers) => {
    state.statusNumbers = statusNumbers;
  }),
  setBucketUsageDates: action((state, bucketUsageDates) => {
    state.bucketUsageDates = bucketUsageDates;
  })
};

const usage = {
  ...attributes,
  ...actions
};

export default usage;
