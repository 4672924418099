import { action } from 'easy-peasy';

const attributes = {
  legalHoldToggle: false
};

const actions = {
  setLegalHoldToggle: action((state, payload) => {
    state.legalHoldToggle = payload;
  })
};

const fileDetails = {
  ...attributes,
  ...actions
};

export default fileDetails;
