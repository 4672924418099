import React from 'react';
import { useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';

import { Logo } from './styles';

import { colors } from '../../../constant';
import Box from '../../../common/box/Box';
import Text from '../../../common/text/Text';

const ResetHeader = () => {
  const { t } = useTranslation();
  const { theme } = useStoreState(actions => actions.theme);

  return (
    <Box
      display="flex"
      direction="column"
      alignitems="center"
      justifycontent="center"
    >
      <Logo src={theme.logo} alt="" />
      <Text fontSize="14px" fontWeight="500" mb="20px" color={colors.black}>
        {t('newPasswordRequiredText')}
      </Text>

      <Text fontSize="14px" mb="20px" textalign="center">
        {t('pleaseSetNewPassword')}
      </Text>
    </Box>
  );
};

export default ResetHeader;
