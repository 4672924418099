import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';

import { colors } from '../../constant';
import Box from '../../common/box/Box';
import Text from '../../common/text/Text';
import Icon from '../../common/icon/Icon';
import Title from '../../common/title/Title';
import Button from '../../common/button/Button';
import hasPermission from '../../../security/Permission';

export function EmptyObjectsView({
  onUploadFileClick,
  onCreateFolderClick,
  incorrectFileName,
  breadCrumbsLength
}) {
  const { t } = useTranslation();
  const canCreateFilePerm = hasPermission('oem_objects', 'create_file');
  const canCreateFolderPerm = hasPermission('oem_objects', 'create_folder');
  const { filesUploadingGlobal } = useStoreState(actions => actions.fileUpload);

  const filesUploading = Boolean(filesUploadingGlobal?.length > 0);

  const { getRootProps, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    disabled: filesUploading,
    onDrop: acceptedFiles => {
      if (canCreateFilePerm) {
        acceptedFiles.map((file, index) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        );
        const files = [];
        let filePaths = [];
        acceptedFiles.forEach(acceptedFile => {
          const fileName = acceptedFile.name;
          const filePath = acceptedFile.path.replace(fileName, '');

          if (
            ['%', '<', '>'].some(
              item => acceptedFile.path.indexOf(item) > -1
            ) ||
            fileName.indexOf('%') > -1
          ) {
            incorrectFileName();
          } else {
            files.push({
              file: acceptedFile,
              percentage: 0,
              displaySuccessIcon: false,
              displayErrorIcon: false
            });

            let previousPath = '/';
            filePath
              .split('/')
              .filter(e => e !== '')
              .forEach(function(item) {
                const path = previousPath + item + '/';
                filePaths.push(path);
                previousPath = path;
              });
          }
        });
        filePaths = [...new Set(filePaths)];
        filePaths.forEach(function(path) {
          files.push({
            file: new File([''], path, {}),
            percentage: 0,
            displaySuccessIcon: false,
            displayErrorIcon: false
          });
        });
        onUploadFileClick(files);
      }
    }
  });

  /**
   * Upload Files.
   */
  const showUploadFilesModal = () => {
    onUploadFileClick();
  };

  /**
   * Create folder.
   */
  const showCreateFolderModal = () => {
    onCreateFolderClick();
  };

  return (
    <Box>
      {canCreateFilePerm && (
        <Box maxwidth="540px" m="0 auto">
          <Box
            {...getRootProps({
              className: `dropzone dropzone-empty-view ${
                isDragActive ? 'dragActive' : ''
              }`
            })}
          >
            <UploadIcon icon="upload" mb="10px" />

            <Title fontSize="20px" color={colors.black} fontWeight="500">
              {t('your')}{' '}
              {breadCrumbsLength > 2 ? t('folder') : t('bucketLowercase')}{' '}
              {t('isEmpty')}
            </Title>

            <Text
              m="10px 0 30px"
              fontSize="14px"
              color={colors.bodyColor}
              data-react-if={canCreateFilePerm}
            >
              {t('dragOrClickToUpload')}
            </Text>

            <Box>
              <Button
                primary
                minHeight="40px"
                onClick={showUploadFilesModal}
                m="0 30px"
                data-react-if={canCreateFilePerm}
              >
                {t('uploadFiles')}
              </Button>
              <Button
                primary
                minHeight="40px"
                onClick={showCreateFolderModal}
                data-react-if={canCreateFolderPerm}
              >
                {t('createFolder')}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

const UploadIcon = styled(Icon)`
  cursor: default;
`;

EmptyObjectsView.propTypes = {
  onUploadFileClick: PropTypes.func.isRequired,
  onCreateFolderClick: PropTypes.func.isRequired,
  incorrectFileName: PropTypes.bool.isRequired,
  breadCrumbsLength: PropTypes.number.isRequired
};
