import React, { useEffect, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';

import { readUsageDataCards } from '../endpoint';
import { CardSimple } from '../../common/card/CardSimple';
import Line from '../../common/line/Line';
import Box from '../../common/box/Box';

import { formatToYearMonthDayWithTimeZoneOffset } from '../../../utils/format.dates.utils';
import { formatStorageValue } from '../../../utils/format-storage-values.utils';
import { useTranslation } from 'react-i18next';

export default function DataCards({ date }) {
  const { t } = useTranslation();
  const { theme } = useStoreState(actions => actions.theme);
  const [dataCard, setDataCard] = useState({
    totalAPICalls: null,
    totalEgress: null,
    totalIngress: null,
    totalObjects: null,
    totalObjectsLabel: null,
    totalStorage: null,
    totalStorageLabel: null
  });

  useEffect(() => {
    if (date?.[1]) {
      readUsageDataCards([
        formatToYearMonthDayWithTimeZoneOffset(date[0]),
        formatToYearMonthDayWithTimeZoneOffset(date[1])
      ])
        .then(([res]) => {
          setDataCard(res?.data.data);
        })
        .catch(err => {
          // silent err
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date?.[1]]);

  return (
    <Box
      display="grid"
      gridcolumn="repeat(auto-fit, minmax(250px, 1fr) )"
      gap="20px"
      mb="30px"
    >
      <CardSimple
        icon
        style={{ width: '100%' }}
        formattedValue={
          typeof dataCard?.totalStorage === 'number'
            ? formatStorageValue(dataCard?.totalStorage, 'totalStorage')
            : '--'
        }
        statusNumber={dataCard?.totalStorageLabel}
        text={t('totalStorage')}
        measuringUnit="TB"
        message={t('totalStorageMessage')}
      >
        <Line
          color={theme.themes?.colorPrimary}
          absolute="true"
          t="0"
          l="0"
          borderRadius="4px 4px 0 0"
          borderWidth="3px"
        />
      </CardSimple>

      <CardSimple
        icon
        style={{ width: '100%' }}
        mainNumber={dataCard?.totalObjects
          ?.toFixed(1)
          ?.toLocaleString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        statusNumber={dataCard?.totalObjectsLabel}
        text={t('totalObjects')}
        message={t('totalObjectsMessage')}
      >
        <Line
          color={theme.themes?.colorPrimary}
          absolute="true"
          t="0"
          l="0"
          borderRadius="4px 4px 0 0"
          borderWidth="3px"
        />
      </CardSimple>

      <CardSimple
        style={{ width: '100%' }}
        mainNumber={dataCard?.totalAPICalls
          ?.toFixed(1)
          ?.toLocaleString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        text={t('apiCalls')}
        message={t('apiCallsMessage')}
      >
        <Line
          color={theme.themes?.colorPrimary}
          absolute="true"
          t="0"
          l="0"
          borderRadius="4px 4px 0 0"
          borderWidth="3px"
        />
      </CardSimple>

      <CardSimple
        style={{ width: '100%' }}
        formattedValue={
          typeof dataCard?.totalEgress === 'number'
            ? formatStorageValue(dataCard?.totalEgress)
            : '--'
        }
        text={t('egress')}
        message={t('egressMessage')}
      >
        <Line
          color={theme.themes?.colorPrimary}
          absolute="true"
          t="0"
          l="0"
          borderRadius="4px 4px 0 0"
          borderWidth="3px"
        />
      </CardSimple>

      <CardSimple
        style={{ width: '100%' }}
        formattedValue={
          typeof dataCard?.totalIngress === 'number'
            ? formatStorageValue(dataCard?.totalIngress)
            : '--'
        }
        text={t('ingress')}
        message={t('ingressMessage')}
      >
        <Line
          color={theme.themes?.colorPrimary}
          absolute="true"
          t="0"
          l="0"
          borderRadius="4px 4px 0 0"
          borderWidth="3px"
        />
      </CardSimple>
    </Box>
  );
}

DataCards.propTypes = {
  date: PropTypes.array.isRequired
};
