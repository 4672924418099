import styled from 'styled-components';

import { colors } from '../../../constant';

export const Link = styled.a`
  color: ${({ theme }) =>
    theme?.themes?.colorPrimary ?? colors.primary} !important;
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
  &:hover {
    color: ${({ theme }) =>
      theme?.themes?.colorPrimary ?? colors.primary} !important;
  }
`;

export const Logo = styled.img`
  margin-bottom: 20px;
  max-width: 240px;
  max-height: 50px;
`;
