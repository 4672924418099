export const initialState = {
  showDeleteAccessKey: false,
  resetKeysModal: false,
  keysActionsModal: false,
  responseLoaded: false,
  displayLoader: false,
  buttonLoading: false,
  showCreateAccessKey: false,
  accessKeyToDelete: '',
  response: null,
  anchorEl: null,
  newKeys: {
    accessKey: '',
    secretKey: ''
  }
};

export const accessKeyReducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_DELETE_ACCESS_KEY':
      return {
        ...state,
        showDeleteAccessKey: action.payload
      };
    case 'SET_ACCESS_KEY_TO_DELETE':
      return {
        ...state,
        accessKeyToDelete: action.payload
      };
    case 'SET_RESET_KEYS_MODAL':
      return {
        ...state,
        resetKeysModal: action.payload
      };
    case 'SET_KEYS_ACTIONS_MODAL':
      return {
        ...state,
        keysActionsModal: action.payload
      };
    case 'SET_RESPONSE_LOADED':
      return {
        ...state,
        responseLoaded: action.payload
      };
    case 'SET_DISPLAY_LOADER':
      return {
        ...state,
        displayLoader: action.payload
      };
    case 'SET_BUTTON_LOADING':
      return {
        ...state,
        buttonLoading: action.payload
      };
    case 'SET_SHOW_CREATE_ACCESS_KEY':
      return {
        ...state,
        showCreateAccessKey: action.payload
      };
    case 'SET_RESPONSE':
      return {
        ...state,
        response: action.payload
      };
    case 'SET_ANCHOREL':
      return {
        ...state,
        anchorEl: action.payload
      };
    case 'SET_NEW_KEYS':
      return {
        ...state,
        newKeys: action.payload
      };
    default:
      return state;
  }
};
