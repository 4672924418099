import { action } from 'easy-peasy';
import _ from 'lodash';

const attributes = {
  lastObjectsByPage: []
};

const actions = {
  setLastObjectsByPage: action((state, payload) => {
    state.lastObjectsByPage = _.uniqBy(
      [...state.lastObjectsByPage, payload],
      'page'
    );
  }),

  resetObjectsState: action((state, _payload) => {
    state.lastObjectsByPage = [];
  })
};

const bucketObjects = {
  ...attributes,
  ...actions
};

export default bucketObjects;
