/* eslint-disable no-restricted-globals */
import React from 'react';
import jwtDecode from 'jwt-decode';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';

import { showToast } from '../../../../hooks/show-toast';
import { LoaderWrap } from '../../../common/loader/Loader';
import { changePassword, getPermissions } from '../../endpoints';
import { initialValues, validationSchema } from '../helpers';
import FormInput from '../../../common/form-input';
import Button from '../../../common/button/Button';
import Text from '../../../common/text/Text';
import Icon from '../../../common/icon/Icon';
import { LanguageSelector } from '../../../common/language-selector';
import Box from '../../../common/box/Box';

import { Link } from './styles';

const ResetForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { resetLoader } = useStoreState(state => state.authentication);
  const { processSignIn, logout, setResetLoader } = useStoreActions(
    actions => actions.authentication
  );

  const formik = {
    ...useFormik({
      validationSchema: validationSchema,
      initialValues: initialValues,
      enableReinitialize: true,
      onSubmit: fieldValues => handleResetPassword(fieldValues)
    })
  };

  const handleResetPassword = values => {
    const { password } = values;

    if (formik.errors.length > 0) {
      return;
    }
    setResetLoader(true);
    changePassword(null, password, true).then(([res]) => {
      showToast(
        {
          message: res?.code || res?.Code,
          params: res?.params || null,
          success: res?.success
        },
        res?.success ? 'success' : 'error'
      );
      if (res.success) {
        processSignIn(res.data);
        getPermissions().then(([result]) => {
          const hasKeys = res?.data?.HasRootAcessKey;
          const jwtToken = jwtDecode(res.data.AccessToken);
          localStorage.setItem('Permissions', JSON.stringify(result.data));
          if (!hasKeys && !jwtToken?.roles.includes('ROOT')) {
            navigate('/no-access-keys');
          } else {
            navigate(hasKeys ? '/buckets' : '/access-keys');
          }
          setResetLoader(false);
        });
      } else {
        setResetLoader(false);
      }
    });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormInput
        name="password"
        inputType="password"
        formik={formik}
        label={t('password')}
      />
      <FormInput
        formik={formik}
        label={t('confirmPassword')}
        name="confirmPassword"
        inputType="password"
        onChange={e => {
          formik.setFieldTouched('confirmPassword', true);
          formik.setFieldValue('confirmPassword', e.target.value);
        }}
        icon={
          <Icon
            icon="verify"
            position="absolute"
            right="10px"
            top="12px"
            display={formik.isValid && formik.dirty ? 'block' : 'none'}
          />
        }
      />
      <Button
        type={'submit'}
        disabled={!formik.dirty || !formik.isValid}
        w="100%"
        primary
      >
        {resetLoader && <LoaderWrap display />}
        {t('update')}
      </Button>

      <Text fontSize="14px" mt="20px" center="true">
        {t('backTo')} <Link onClick={() => logout()}>{t('login')}</Link>
      </Text>
      <Box display="flex" justifycontent="center" mt="20px">
        <LanguageSelector />
      </Box>
    </form>
  );
};

export default ResetForm;
