import * as Yup from 'yup';
import i18n from '../../../../../i18n';

export const menuItems = [
  {
    displayName: 'SAML',
    value: 'SAMLP'
  },
  {
    displayName: 'OPEN ID',
    value: 'OIDC'
  }
];

export const initialValues = {
  organizationName: '',
  connectionType: 'SAMLP',
  channelType: 'front_channel',
  discoveryEndpoint: '',
  clientId: '',
  signInUrl: '',
  signingCert: '',
  signOutUrl: ''
};

export const createEditOrgInitialValues = {
  organizationName: ''
};

export const validationSchema = Yup.object({
  discoveryEndpoint: Yup.string().when(['connectionType'], {
    is: connectionType => connectionType === 'OIDC',
    then: schema =>
      schema
        .matches(
          /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]+)?(\/[^\s]*)?\/\.well-known\/openid-configuration$/,
          i18n.t('discoveryEndpointInvalidURL')
        )
        .required(i18n.t('discoveryEndpointRequired')),
    otherwise: schema => schema.nullable()
  }),
  clientId: Yup.string().when(['connectionType'], {
    is: connectionType => connectionType === 'OIDC',
    then: schema => schema.required(i18n.t('clientIdRequired')),
    otherwise: schema => schema.nullable()
  }),
  clientSecret: Yup.string().when(['channelType', 'connectionType'], {
    is: (channelType, connectionType) =>
      channelType === 'back_channel' && connectionType === 'OIDC',
    then: schema => schema.required(i18n.t('clientSecretRequired')),
    otherwise: schema => schema.nullable()
  }),
  signInUrl: Yup.string().when('connectionType', {
    is: value => value === 'SAMLP',
    then: schema =>
      schema
        .matches(
          /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]+)?(\/[^\s]*)?$/,
          i18n.t('signInUrlInvalidURL')
        )
        .required(i18n.t('signInUrlRequired')),
    otherwise: schema => schema.nullable()
  }),
  signOutUrl: Yup.string().when('connectionType', {
    is: value => value === 'SAMLP',
    then: schema =>
      schema
        .matches(
          /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]+)?(\/[^\s]*)?$/,
          i18n.t('signOutUrlInvalidURL')
        )
        .optional(),
    otherwise: schema => schema.nullable()
  }),
  signingCert: Yup.string().when('connectionType', {
    is: value => value === 'SAMLP',
    then: schema => schema.required(i18n.t('signingCertRequired')),
    otherwise: schema => schema.nullable()
  })
});

export const createEditOrgValidationSchema = Yup.object({
  organizationName: Yup.string()
    .required(i18n.t('organizationNameRequired'))
    .min(3, i18n.t('organizationNameMinLength'))
    .max(50, i18n.t('organizationNameMaxLength'))
    .matches(
      /^[a-z0-9][a-z0-9_-]*(?<![_-])$/,
      i18n.t('organizationNameInvalidFormat')
    )
});
