import React from 'react';
import Button from '../../../../common/button/Button';
import { FooterContainer, OtherOptionButton } from './helpers';
import { colors } from '../../../../constant';
import { useTranslation } from 'react-i18next';

const Footer = ({ formik, toggleView, isResetForm, onClick }) => {
  const { t } = useTranslation();
  return (
    <FooterContainer>
      <OtherOptionButton onClick={toggleView}>
        {t('viewOtherOption')}
      </OtherOptionButton>
      <Button
        isLoading={formik?.isSubmitting}
        fontWeight={500}
        onClick={onClick}
        className={isResetForm && 'delete'}
        color={colors.white}
        bordercolor="transparent"
        bgcolor={isResetForm && colors.red}
        primary
        w="130px"
        type={'submit'}
        disabled={!isResetForm && (!formik?.isValid || !formik?.dirty)}
      >
        {isResetForm ? t('reset') : t('save')}
      </Button>
    </FooterContainer>
  );
};

export default Footer;
