import { action } from 'easy-peasy';

const attributes = {
  isRedirecting: false
};

const actions = {
  setIsRedirecting: action((state, payload) => {
    state.isRedirecting = payload;
  })
};

const general = {
  ...attributes,
  ...actions
};

export default general;
