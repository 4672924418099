/* eslint-disable no-restricted-globals */

function hasPermission(model, action) {
  const permissions = JSON.parse(localStorage.getItem('Permissions'));
  function hasValue(item) {
    return item && item.length > 0;
  }

  if (!hasValue(model) || !hasValue(action) || !permissions) {
    return false;
  }

  const foundPerm = permissions
    .filter(perm => perm.Model === model && perm.Action === action)
    .pop();
  return (
    foundPerm && Object.keys(foundPerm) && Object.keys(foundPerm).length > 0
  );
}

export default hasPermission;
