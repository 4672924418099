export async function getBase64(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      resolve(reader.result.split(',')[1]); // Resolve the Promise with the result
    };
    reader.onerror = function(error) {
      reject(error); // Reject the Promise if there's an error
    };
  });
}
