import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constant';
import Icon from '../icon/Icon';

export const LoaderWrap = ({
  display,
  m,
  p,
  backgroundColor,
  zIndex,
  width,
  height,
  wrapperWidth
}) => {
  return (
    <Loader
      height={height}
      wrapperWidth={wrapperWidth}
      zIndex={zIndex}
      backgroundColor={backgroundColor}
      m={m}
      p={p}
      display={display ? 'flex' : 'none'}
    >
      <Icon width={width || '120px'} icon="cloud"></Icon>
    </Loader>
  );
};

const Loader = styled.div`
  width: ${props => props.wrapperWidth || '100%'};
  height: ${props => props.height || '100%'};
  position: absolute;
  align-items: center;
  display: ${props => props.display};
  margin: ${props => props.m};
  padding: ${props => props.p};
  justify-content: center;
  top: 0;
  left: 0;
  background-color: ${props => props.backgroundColor || colors.white};
  z-index: ${props => props.zIndex || 9};
`;

export default Loader;
