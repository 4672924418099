/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import jwtDecode from 'jwt-decode';

import { CloseModalImg } from '../../../../common/modal/Modal';
import Text from '../../../../common/text/Text';
import Title from '../../../../common/title/Title';
import { colors } from '../../../../constant';
import Input from '../../../../common/input/Input';
import Icon from '../../../../common/icon/Icon';
import Button from '../../../../common/button/Button';
import Box from '../../../../common/box/Box';
import CloseModalIcon from '../../../../../assets/icons/x.svg';
import { saveFileAs } from '../../../../../services/Download';
import { showToast } from '../../../../../hooks/show-toast';

import {
  Divider,
  DividerContainer,
  NewRootAccessKeysContainer,
  NewRootAccessKeysModal,
  NewRootAccessKeysTitleContainer
} from './helpers';

const NewRootAccessKeys = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [accessKey, setAccessKey] = useState('');
  const [showAccessKey, setShowAccessKey] = useState(false);
  const [secretKey, setSecretKey] = useState('');
  const [showSecretKey, setShowSecretKey] = useState(false);
  const [isCopyLoading, setIsCopyLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const userData = jwtDecode(localStorage.getItem('Authorization'));

  useEffect(() => {
    if (
      !userData?.roles.includes('ROOT') &&
      (!userData?.user?.AccessKey?.length > 0 ||
        !userData?.user?.SecretKey?.length > 0)
    ) {
      navigate('/no-access-keys');
    } else if (
      userData?.roles.includes('ROOT') &&
      (!userData?.user?.AccessKey?.length > 0 ||
        !userData?.user?.SecretKey?.length > 0)
    ) {
      navigate('/access-keys');
    }
    if (
      !state?.accessKey &&
      userData?.user?.AccessKey?.length > 0 &&
      userData?.user?.SecretKey?.length > 0
    )
      navigate('/buckets');
    setAccessKey(state?.accessKey);
    setSecretKey(state?.secretKey);
  }, [state]);

  const handleDownloadKeys = e => {
    setIsDownloadLoading(true);
    e.preventDefault();
    const headers = [t('name'), t('accessKey'), t('secretKey')];
    const data = ['\r\n'.concat('Root Account Key'), accessKey, secretKey];
    saveFileAs(
      [...headers, ...data],
      'credentials.csv',
      'text/csv;charset=utf-8'
    );
    setTimeout(() => {
      setIsDownloadLoading(false);
    }, 100);
  };

  const handleCopyKeys = async e => {
    e.preventDefault();
    setIsCopyLoading(true);
    await navigator.clipboard.writeText(
      t('access-key')
        .concat(' = ')
        .concat(accessKey)
        .concat('\r\n')
        .concat(t('secret-key'))
        .concat(' = ')
        .concat(secretKey)
    );

    showToast(
      {
        message: t('keysCopiedToClipboard'),
        success: true,
        customMessage: true
      },
      'info'
    );
    setTimeout(() => {
      setIsCopyLoading(false);
    }, 100);
  };

  return (
    <NewRootAccessKeysContainer>
      {!state?.accessKey && !state?.secretKey ? (
        <Title fontSize="16px" fontWeight="500">
          {t('noAccessKeysFound')}
        </Title>
      ) : (
        <NewRootAccessKeysModal>
          <NewRootAccessKeysTitleContainer
            display={'flex'}
            justifycontent={'space-between'}
            alignitems={'center'}
            p={'20px'}
          >
            <Title fontSize="16px" fontWeight="500">
              {t('newRootAccessKeys')}
            </Title>
            <CloseModalImg
              src={CloseModalIcon}
              onClick={() => navigate('/buckets')}
            />
          </NewRootAccessKeysTitleContainer>
          <DividerContainer>
            <Divider />
          </DividerContainer>
          <Box bgcolor={'white'} p={'20px'}>
            <Text fontSize="14px" maxwidth="490px" lineheight="20px">
              {t('copyOrDownloadKeysDescription')}
            </Text>

            <Title
              fontSize="16px"
              fontWeight="500"
              color={colors.black}
              m="30px 0"
            >
              {t('s3Credentials')}
            </Title>
            <div className="input-wrapper modified">
              <Input
                size="0"
                width="100%"
                fontSize="14px"
                name="accessKey"
                type={showAccessKey ? 'text' : 'password'}
                value={accessKey ?? ''}
                readOnly
                marginBottom="20px"
              />
              <label
                htmlFor="accessKey"
                className={accessKey.length > 0 ? 'filledField' : ''}
              >
                {t('accessKey')}*
                <Icon
                  display={accessKey.length > 0 ? 'initial' : 'none'}
                  onClick={() => setShowAccessKey(prev => !prev)}
                  icon={showAccessKey ? 'close_eye' : 'view'}
                />
              </label>
            </div>
            <div className="input-wrapper modified">
              <Input
                size="0"
                width="100%"
                fontSize="14px"
                name="secretKey"
                type={showSecretKey ? 'text' : 'password'}
                value={secretKey ?? ''}
                readOnly
                marginBottom="10px"
              />
              <label
                htmlFor="secretKey"
                className={secretKey.length > 0 ? 'filledField' : ''}
              >
                {t('secretKey')}*
                <Icon
                  display={secretKey.length > 0 ? 'initial' : 'none'}
                  onClick={() => setShowSecretKey(prev => !prev)}
                  icon={showSecretKey ? 'close_eye' : 'view'}
                />
              </label>
            </div>
          </Box>

          <Box display="flex" justifycontent="flex-end" p={'20px'}>
            <Button
              mr="20px"
              onClick={handleDownloadKeys}
              primary
              isLoading={isDownloadLoading}
              w="fit-content"
            >
              {t('downloadKeys')}
            </Button>
            <Button
              isLoading={isCopyLoading}
              onClick={handleCopyKeys}
              primary
              w="fit-content"
            >
              {t('copyKeys')}
            </Button>
          </Box>
        </NewRootAccessKeysModal>
      )}
    </NewRootAccessKeysContainer>
  );
};
export default NewRootAccessKeys;
