/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';

import Box from '../../../common/box/Box';
import Text from '../../../common/text/Text';
import Line from '../../../common/line/Line';
import Title from '../../../common/title/Title';
import Button from '../../../common/button/Button';
import hasPermission from '../../../../security/Permission';
import MfaModalRecoveryKey from './MfaModalRecoveryKey';
import ChangePasswordNotAvailable from '../../../common/change-password-not-available';
import { colors } from '../../../constant';
import { getUserMfa } from '../../endpoints';
import { LoaderWrap } from '../../../common/loader/Loader';
import { showToast } from '../../../../hooks/show-toast';

import MfaModalDisabled from './MfaModalDiasbled';
import MultiFactorAuthModal from './MultiFactorAuthModal';

export default function MultiFactorAuth() {
  const { t } = useTranslation();
  const { userData } = useStoreState(state => state.user);
  const { theme } = useStoreState(actions => actions.theme);
  const isSsoUser = JSON.parse(localStorage.getItem('userIsSso'));
  const canUpdateMfaPerm = hasPermission('oem_profile', 'update_mfa');
  const displayLoader = false;

  const [text, setText] = useState('Turn On');
  const [showMFAModal, setShowMFAModal] = useState(false);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [showMfaDisabledModal, setShowMfaDisabledModal] = useState(false);
  const [showMfaRecoveryKeyModal, setShowMfaRecoveryKeyModal] = useState(false);
  const [currentSecretKey, setCurrentSecretKey] = useState('');
  const [qrCode, setQrCode] = useState(null);

  const fetchUserMfa = () => {
    getUserMfa().then(([res]) => {
      if (res.success) {
        if (res.data) {
          setMfaEnabled(res.data.enabled);
          setText(res.data.enabled ? t('turnOff') : t('turnOn'));
        }
      } else {
        if (res?.code || res?.Code !== 'ErrCcc0045')
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: res.success
            },
            res.success ? 'success' : 'error'
          );
      }
    });
  };

  useEffect(() => {
    fetchUserMfa();
  }, []);

  useEffect(() => {}, [mfaEnabled]);

  const handleButtonClick = () => {
    if (mfaEnabled) {
      setShowMfaDisabledModal(true);
    } else {
      setShowMFAModal(true);
    }
  };

  const closeModal = ({ success, message }) => {
    if (success) {
      showToast(
        { message: message, success: success },
        success ? 'success' : 'error'
      );
    }
    setShowMfaDisabledModal(false);
    fetchUserMfa();
  };

  const closeEnableMfaModal = ({ secret, success, message }) => {
    if (success) {
      showToast(
        { message: message, success: success },
        success ? 'success' : 'error'
      );
    }
    setShowMFAModal(false);
    setShowMfaRecoveryKeyModal(success);
    setCurrentSecretKey(secret);
    fetchUserMfa();
  };

  const getQrCode = code => {
    setQrCode(code);
  };

  useEffect(() => {}, [currentSecretKey]);

  return (
    <>
      <Box bgcolor={colors.white} p="30px" mt="30px">
        <Box display="flex" alignitems="center" justifycontent="space-between">
          <Title fontSize="18px" fontWeight="500" color={colors.black}>
            {t('multiFactorAuthentication')}
          </Title>
        </Box>

        <Line m="20px 0 40px" />

        {isSsoUser ? (
          <ChangePasswordNotAvailable />
        ) : (
          <>
            <Text fontSize="14px">{t('increaseSecurityByEnablingMFA')}</Text>

            <Box
              display="flex"
              alignitems="center"
              justifycontent="space-between"
              p="30px"
              border="true"
              borderradius="4px"
              mt="30px"
            >
              <Box>
                <Title fontSize="16px" color={colors.black} fontWeight="400">
                  {t('googleAuthenticatorOrAuthy')}
                </Title>
                <Text fontSize="14px" maxwidth="355px" mt="10px">
                  {t('provideOneTimePasswordUsingGoogleAuthenticator')}
                </Text>
              </Box>

              {canUpdateMfaPerm && (
                <Button
                  disabled={isSsoUser}
                  minWidth="150px"
                  onClick={handleButtonClick}
                  outlined
                  hoverColor={theme.themes.colorPrimary}
                >
                  {text}
                </Button>
              )}
            </Box>
          </>
        )}

        <MultiFactorAuthModal
          open={showMFAModal}
          close={e => closeEnableMfaModal(e)}
          qrCode={getQrCode}
        />

        <MfaModalDisabled
          open={showMfaDisabledModal}
          close={e => closeModal(e)}
          id={userData.Id}
        />

        <MfaModalRecoveryKey
          open={showMfaRecoveryKeyModal}
          close={() => setShowMfaRecoveryKeyModal(false)}
          secretKey={currentSecretKey}
          qrCode={qrCode}
        />
        <LoaderWrap display={displayLoader} />
      </Box>
    </>
  );
}
