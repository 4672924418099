/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { CloseModalImg, ModalBasic } from '../../../../common/modal/Modal';
import CloseModalIcon from '../../../../../assets/icons/x.svg';
import { resetRootAccessKeys } from '../../../../auth/endpoints';
import { showToast } from '../../../../../hooks/show-toast';

import {
  resetInitialValues,
  ResetKeysHeader,
  resetValidationSchema
} from './helpers';
import ConfirmResetKeys from './ConfirmReset';

export default function ResetAccessKeysModal({
  open,
  close,
  showSuccessModal,
  setNewKeys
}) {
  const { t } = useTranslation();

  const onSubmit = (_values, actions) => {
    resetRootAccessKeys().then(([res]) => {
      if (res?.success) {
        localStorage.setItem('Authorization', res.data.Auth.AccessToken);
        localStorage.setItem('RefreshToken', res.data.Auth.RefreshToken);
        setNewKeys({
          accessKey: res.data.AccessKeyId,
          secretKey: res.data.SecretAccessKey
        });
        actions.setSubmitting(false);
        formik.resetForm();
        showSuccessModal();
        close();
      } else {
        actions.setSubmitting(false);
      }
      showToast(
        {
          message: res?.code || res?.Code,
          params: res?.params || null,
          success: res?.success
        },
        res?.success ? 'success' : 'error'
      );
    });
  };

  const formik = {
    ...useFormik({
      validationSchema: resetValidationSchema,
      initialValues: resetInitialValues,
      enableReinitialize: true,
      onSubmit: (fieldValues, actions) => onSubmit(fieldValues, actions)
    })
  };

  return (
    <ModalBasic
      style={{
        width: '400px',
        overflowy: 'auto',
        mt: '60px'
      }}
      open={open}
      closeModal={close}
    >
      <ResetKeysHeader>
        {t('resetRootAccessKeys')}
        <CloseModalImg src={CloseModalIcon} onClick={close} />
      </ResetKeysHeader>
      <ConfirmResetKeys formik={formik} />
    </ModalBasic>
  );
}

ResetAccessKeysModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  showSuccessModal: PropTypes.bool.isRequired,
  setNewKeys: PropTypes.func.isRequired
};
