import styled from 'styled-components';

export const StyledBadge = styled.div`
  width: fit-content;
  height: 20px;
  padding: 6px 9px;
  background-color: ${props => props.backgroundColor};
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  cursor: pointer;
`;

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  margin-left: 3px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #5d3115;
`;
