export const saveFileAs = (data, fileName, typeFormat) => {
  const blob = new Blob([data], {
    type: typeFormat ? typeFormat : 'octect/stream'
  });
  const url = URL.createObjectURL(blob);

  const anchor = document.createElement('a');
  anchor.setAttribute('href', url);
  anchor.setAttribute('download', fileName);
  anchor.click();

  URL.revokeObjectURL(url);
};
