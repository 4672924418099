import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  CloseModalImg,
  ModalBasic,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../../common/modal/Modal';
import Input, { ErrorMessage } from '../../common/input/Input';
import Button from '../../common/button/Button';
import CloseModalIcon from '../../../assets/icons/x.svg';
import Title from '../../common/title/Title';
import { colors } from '../../constant';

export function CreateFolderModal({ open, onSubmit, displayLoader, close }) {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [specialCharsError, setSpecialCharsError] = useState('');
  const [folderName, setFolderName] = useState('');

  const onChange = e => {
    setFolderName(e.target.value);
  };

  useEffect(() => {
    if (folderName.length === 0) {
      setError(t('folderNameRequired'));
    } else if (
      ['%', '<', '>', '/'].some(item => folderName.indexOf(item) > -1)
    ) {
      setSpecialCharsError(t('specialCharsError'));
    } else {
      setError('');
      setSpecialCharsError('');
    }
  }, [folderName, t]);

  useEffect(() => {
    if (!open) {
      setFolderName('');
      setSpecialCharsError('');
    }
  }, [open]);

  const handleSubmit = e => {
    e.preventDefault();
    if (error.length === 0 && specialCharsError.length === 0) {
      onSubmit({ name: folderName });
    }
  };

  return (
    <ModalBasic
      style={{ width: '600px' }}
      open={open}
      closeModal={close}
      // eslint-disable-next-line react/no-children-prop
      children={<Input />}
    >
      <div>
        <ModalHeader>
          <Title color={colors.black} fontSize="16px" fontWeight="500">
            {t('createFolder')}
          </Title>
          <CloseModalImg src={CloseModalIcon} onClick={close} />
        </ModalHeader>

        <ModalBody>
          <form onSubmit={handleSubmit}>
            <div className="input-wrapper">
              <Input
                size="0"
                width="100%"
                fontSize="14px"
                name="name"
                value={folderName}
                onChange={onChange.bind(this)}
              />
              <label
                htmlFor="name"
                className={folderName.length > 0 ? 'filledField' : ''}
              >
                {t('folderName')}*
              </label>
              {specialCharsError !== '' && (
                <ErrorMessage>{specialCharsError}</ErrorMessage>
              )}
            </div>
          </form>
        </ModalBody>

        <ModalFooter display="flex" justifycontent="flex-end">
          <Button onClick={close} className="cancel" mr="10px">
            {t('cancel')}
          </Button>
          <Button
            isLoading={displayLoader}
            w="133px"
            onClick={handleSubmit}
            primary
            disabled={error.length > 0 || specialCharsError.length > 0}
          >
            {t('create')}
          </Button>
        </ModalFooter>
      </div>
    </ModalBasic>
  );
}

CreateFolderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  displayLoader: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};
