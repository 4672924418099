import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constant';

export const ButtonToggle = ({
  isToggled,
  handle,
  disabled,
  type = 'button'
}) => {
  return (
    <Toggle
      type={type}
      isToggled={isToggled}
      onClick={handle}
      disabled={disabled}
    />
  );
};

const Toggle = styled.button`
  width: 44px;
  height: 22px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-color: ${props =>
    props.isToggled === true
      ? ({ theme }) => theme.themes?.colorPrimary
      : colors.lightGray};
  transition: background-color 0.3s linear;

  &:disabled {
    opacity: ${props => (props.isToggled === true ? '0.6' : '1')};
    cursor: not-allowed;
  }

  &:before {
    content: '';
    width: 18px;
    height: 18px;
    background-color: ${colors.white};
    border-radius: 50%;
    margin: 0 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: ${props => (props.isToggled === true ? '22px' : '0')};
    transition: left 0.3s linear;
  }
`;
